import { useQueryExamAll } from "@/api/exam/exam";
import { ExamExport } from "@/api/export/dto/exam-export.enum";
import { useQueryExportExam } from "@/api/export/export";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { Form } from "@/components/atoms/Form";
import ExamDateCombobox from "@/components/molecules/ExamDateCombobox";
import { id } from "@/constants/zodTypes";
import { downloadFile } from "@/lib/download-file";
import { formatDate } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { HttpStatusCode } from "axios";
import { Download } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  examId: id(),
});

type Schema = z.infer<typeof schema>;

const examFilesNames = {
  [ExamExport.Payments]: "paiements.xlsx",
  [ExamExport.Registrations]: "inscriptions.xlsx",
  [ExamExport.Prerequisites]: "pré-requis.xlsx",
  [ExamExport.Results]: "résultats.xlsx",
  [ExamExport.Answers]: "réponses.xlsx",
  [ExamExport.Live]: "live.xlsx",
  [ExamExport.CopiesComplete]: "copies-globales.zip",
  [ExamExport.CopiesPartial]: "copies-partielles.zip",
  [ExamExport.QCMsExcel]: "qcms.xlsx",
  [ExamExport.QCMsPDF]: "qcms.zip",
};

const titles = {
  [ExamExport.Payments]: "Suivi des paiements",
  [ExamExport.Registrations]: "Liste des inscrits",
  [ExamExport.Prerequisites]: "Suivi des prérequis",
  [ExamExport.Results]: "Résultats",
  [ExamExport.Answers]: "Export des réponses",
  [ExamExport.Live]: "Suivi session en temps réel",
  [ExamExport.CopiesComplete]: "Export des copies complètes",
  [ExamExport.CopiesPartial]: "Export des copies partielles",
  [ExamExport.QCMsExcel]: "Export des QCMs sous format Excel",
  [ExamExport.QCMsPDF]: "Export des QCMs sous format PDF",
};

interface ExportExamDialogProps {
  examExport: ExamExport;
}

const ExportExamDialog = ({ examExport }: ExportExamDialogProps) => {
  const [open, setOpen] = useState(false);

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      examId: -1,
    },
  });

  const examId = form.watch("examId");

  const { data: exams } = useQueryExamAll();
  const { isLoading, isFetching, isError, error, refetch, data } =
    useQueryExportExam({
      examExport,
      examId,
    });

  // display error
  useEffect(() => {
    if (!isError) return;

    switch (error.response?.status) {
      case HttpStatusCode.Conflict:
        toast.error("Aucune inscription à cet examen.");
        return;

      case HttpStatusCode.NotImplemented:
        toast.error("Export non implémenté pour l'instant.");
        return;

      default:
        toast.error("Échec de l'export");
    }
  }, [isError, error?.response?.status]);

  // download file
  useEffect(() => {
    if (!data) return;

    const examNumber =
      exams?.find((exam) => exam.id === examId)?.number ?? "examen";

    downloadFile(data, `${examNumber}-${examFilesNames[examExport]}`);
  }, [data, examExport, examId, exams]);

  const onSubmit = () => refetch();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Download className="w-5 h-5" />
      </DialogTrigger>
      <DialogContent className="p-4">
        <DialogHeader>
          <DialogTitle>{titles[examExport]}</DialogTitle>
          <DialogDescription hidden>{titles[examExport]}</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <ExamDateCombobox
              fieldName="examId"
              title="Examen"
              label={({ number, date }) => `${number} - ${formatDate(date)}`}
            />
            <Button disabled={isLoading || isFetching}>
              {isLoading || isFetching ? "Export en cours..." : "Exporter"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ExportExamDialog;
