import { useQueryExamResultCandidatePaginated } from "@/api/exam-result/exam-result";
import { Skeleton } from "@/components/atoms/Skeleton";
import LoadingError from "@/components/molecules/LoadingError";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import usePersistent from "@/hooks/use-persistent";
import { cn } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { generateColumns } from "./Columns";

interface ResultsTableProps {
  candidateId: number;
  searchText?: string;
}

const ResultsTable = ({ candidateId, searchText }: ResultsTableProps) => {
  const [pageIndex, setPageIndex] = usePersistent(
    `exam-results-page-index-${candidateId}`,
    0
  );
  const { data, isLoading } = useQueryExamResultCandidatePaginated(
    {
      pageIndex,
      searchText,
    },
    candidateId,
    { placeholderData: keepPreviousData }
  );

  const columns = useMemo(() => generateColumns(), []);

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!data) {
    if (isLoading) return <Skeleton className="h-full w-full" />;
    else return <LoadingError>Erreur de chargement des résultats</LoadingError>;
  }

  if (!searchText && !data?.data?.length)
    return <span className="italic">Aucun résultat pour l'instant</span>;

  return (
    <div className="flex flex-col w-full gap-5">
      <div className="rounded-lg overflow-auto border border-gray-200 w-full">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                  className="whitespace-nowrap"
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn("text-gray-900", {
                  "bg-gray-50": i % 2,
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-14 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={setPageIndex}
        totalPages={data.totalPages}
      />
    </div>
  );
};

export default ResultsTable;
