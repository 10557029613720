import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { DelegationStatus } from "@/api/enums/DelegationStatus";
import { ExamResultStatus } from "@/api/enums/exam-result-status.enum";
import { ExamStatus, ExamStatusUtil } from "@/api/enums/exam-status.enum";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import AuraInfo from "@/components/molecules/AuraLogos/AuraInfo";
import { PayementUtil } from "@/lib/payement-util";
import React from "react";

interface TopMessageProps {
  registration: CandidateRegistrationDtoClient;
}

const TopMessageBox = React.forwardRef<HTMLDivElement, React.PropsWithChildren>(
  ({ children }, ref) => (
    <div ref={ref} className="flex flex-row gap-4 items-center  px-4 py-2">
      {children}
    </div>
  )
);

const WaitingDelegationPayement = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="orange" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Vous êtes pré-inscrit
        </span>
        <span className="text-gray-600">
          Nous sommes en attente du paiement de la part de votre délégataire
          désigné. Le paiement doit avoir lieu avant la date de clôture des
          inscriptions : aucune dérogation n'est accordée. N'hésitez pas à
          relancer votre délégataire si nécessaire.
        </span>
      </div>
    </TopMessageBox>
  );
};

const WaitingPersonalPayement = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="orange" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Vous êtes pré-inscrit
        </span>
        <span className="text-gray-600">
          Votre règlement doit être effectué avant la date de clôture des
          inscriptions
        </span>
      </div>
    </TopMessageBox>
  );
};

const WaitingReducedPriceDocumentValidation = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="orange" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Vous êtes pré-inscrit
        </span>
        <span className="text-gray-600">
          Votre règlement doit être effectué avant la date de clôture des
          inscriptions. Vous pourrez payer lorsque le justificatif de tarif
          réduit sera validé.
        </span>
      </div>
    </TopMessageBox>
  );
};

const WaitingFormationAttestationUpload = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="orange" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Attestation non encore déposée
        </span>
        <span className="text-gray-600">
          En attente du ou des justificatif(s)
        </span>
      </div>
    </TopMessageBox>
  );
};

const WaitingFormationAttestationValidation = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="orange" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Attestation(s) déposée(s) à vérifier
        </span>
        <span className="text-gray-600">En attente de validation</span>
      </div>
    </TopMessageBox>
  );
};

const InvalidFormationAttestation = () => {
  return (
    <TopMessageBox>
      {" "}
      <AuraInfo variant="orange" />
      <span className="text-gray-800 font-semibold">
        Attestation déposée invalide ou incomplète
      </span>
    </TopMessageBox>
  );
};

const WaitingPreTestValidation = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="orange" />
      <span className="text-gray-800 font-semibold">
        Le test préalable n'est pas encore validé.
      </span>
    </TopMessageBox>
  );
};

const NotComplete = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="orange" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Vous êtes pré-inscrit
        </span>
      </div>
    </TopMessageBox>
  );
};

const AllGood = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="green" />
      <span className="text-gray-800 font-semibold">
        Tous les pré-requis sont validés, votre convocation vous a été envoyée
      </span>
    </TopMessageBox>
  );
};

const NoNotesYet = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="orange" />
      <span className="text-gray-800 font-semibold">
        L'examen a eu lieu mais les résultats ne sont pas encore communiqués /
        édités.
      </span>
    </TopMessageBox>
  );
};

const Passed = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="green" />
      <span className="text-gray-800 font-semibold">
        Félicitations, vous avez été admis à l’examen. Votre certificat sera
        disponible dans un délai de 15 jours après l'envoi du relevé de notes.
      </span>
    </TopMessageBox>
  );
};

const Failed = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="red" />
      <span className="text-gray-800 font-semibold">
        Vous êtes malheureusement ajourné à l'examen. Si vous souhaitez le
        repasser, vous devez vous inscrire à une nouvelle session.
      </span>
    </TopMessageBox>
  );
};

const Missing = () => {
  return (
    <TopMessageBox>
      <AuraInfo variant="red" />
      <span className="text-gray-800 font-semibold">
        Vous ne vous êtes pas présenté à l'examen (absent). Si vous souhaitez le
        passer, vous devez vous inscrire à une nouvelle session d'examen.
      </span>
    </TopMessageBox>
  );
};

const TopMessage = ({
  registration: {
    reducedPriceStatus,
    delegation,
    formationCertificatesStatus,
    allAttestationsProvided,
    preTestStatus,
    payementStatus,
    complete,
    exam,
    examResult,
  },
}: TopMessageProps) => {
  // complete, waiting exam
  if (
    complete &&
    ExamStatusUtil.order(exam.status) < ExamStatusUtil.order(ExamStatus.PASSED)
  )
    return <AllGood />;

  // exam passed
  if (complete) {
    // don't show results id results document
    // is not available
    if (!examResult?.resultsDocument) return <NoNotesYet />;

    switch (examResult?.status) {
      case ExamResultStatus.PASSED:
        return <Passed />;
      case ExamResultStatus.FAILED:
        return <Failed />;
      case ExamResultStatus.MISSING:
        return <Missing />;
      default:
        return <NoNotesYet />;
    }
  }

  // not complete, missing documents or validation
  if (
    delegation &&
    [
      DelegationStatus.WAITING_ACCEPTATION,
      DelegationStatus.WAITING_ACCOUNT_CREATION,
    ].includes(delegation.status)
  )
    return <WaitingDelegationPayement />;

  if (reducedPriceStatus === ValidationStatus.PENDING)
    return <WaitingReducedPriceDocumentValidation />;

  if (!PayementUtil.isPaid(payementStatus)) return <WaitingPersonalPayement />;

  if (!allAttestationsProvided) return <WaitingFormationAttestationUpload />;

  if (formationCertificatesStatus === ValidationStatus.PENDING)
    return <WaitingFormationAttestationValidation />;

  if (formationCertificatesStatus === ValidationStatus.REFUSED)
    return <InvalidFormationAttestation />;

  if (preTestStatus === ValidationStatus.PENDING)
    return <WaitingPreTestValidation />;

  return <NotComplete />;
};

export default TopMessage;
