import { Calendar } from "lucide-react";
import GrayCard from "./GrayCard";
import { format } from "date-fns";

interface ExamDateCardProps {
  date: Date;
}

const ExamDateCard = ({ date }: ExamDateCardProps) => (
  <GrayCard>
    <Calendar className="w-4 h-4 text-gray-500" />
    Date de l'examen : {format(new Date(date), "dd/MM/yyyy")}
  </GrayCard>
);

export default ExamDateCard;
