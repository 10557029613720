import { useQueryExamRegistrations } from "@/api/exam/exam";
import { AdminRegistrationDtoClient } from "@/api/registration-dto/dto/admin-registration.dto";
import { Badge } from "@/components/atoms/Badge";
import { Checkbox } from "@/components/atoms/CheckBox";
import { Skeleton } from "@/components/atoms/Skeleton";
import LoadingError from "@/components/molecules/LoadingError";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import useTablePlaceholderRows from "@/hooks/table-placeholder-rows";
import { PayementUtil } from "@/lib/payement-util";
import { cn } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Hourglass } from "lucide-react";
import { useMemo, useState } from "react";
import { useSessionContext } from "../../session-context";

const columnHelper = createColumnHelper<AdminRegistrationDtoClient>();

interface PreRegisteredTableProps {
  searchText?: string;
  allSelected: boolean;
  setAllSelected: (b: boolean) => void;
  selected: Set<number>;
  setSelected: (s: Set<number>) => void;
  notSelected: Set<number>;
  setNotSelected: (s: Set<number>) => void;
}

const PreRegisteredTable = ({
  allSelected,
  selected,
  notSelected,
  setNotSelected,
  setAllSelected,
  setSelected,
  searchText,
}: PreRegisteredTableProps) => {
  const { exam } = useSessionContext();
  const [pageIndex, setPageIndex] = useState(0);

  const { data: registrations, isLoading } = useQueryExamRegistrations(
    {
      examId: exam.id,
      pageIndex,
      searchText,
      complete: false,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const columns = useMemo(() => {
    const toggleSelection = (id: number) => {
      if (!allSelected) {
        const newSelected = new Set(selected);
        if (!newSelected.has(id)) newSelected.add(id);
        else newSelected.delete(id);

        setSelected(newSelected);
      } else {
        const newNotSelected = new Set(notSelected);
        if (newNotSelected.has(id)) newNotSelected.delete(id);
        else newNotSelected.add(id);

        setNotSelected(newNotSelected);
      }
    };

    const toggleGlobalSelection = () => {
      setAllSelected(!allSelected);
      setSelected(new Set());
      setNotSelected(new Set());
    };

    return [
      columnHelper.accessor("id", {
        id: "selection",
        header: () => (
          <Checkbox
            // Checks if all rows are selected
            checked={allSelected}
            onClick={() => toggleGlobalSelection()}
          />
        ),
        cell: (info) => {
          const id = info.getValue();

          return (
            <Checkbox
              checked={!allSelected ? selected.has(id) : !notSelected.has(id)}
              onClick={() => toggleSelection(id)}
            />
          );
        },
        maxSize: 1,
      }),
      columnHelper.accessor(
        ({ candidate: { firstName, lastName }, currentDelegation }) => ({
          name: `${firstName} ${lastName}`,
          currentDelegation,
        }),
        {
          id: "candidate",
          header: "Candidat",
          cell: (info) => {
            const { currentDelegation, name } = info.getValue();
            return (
              <div className="flex flex-col h-9 justify-center">
                <span className="text-gray-900 whitespace-nowrap">{name}</span>
                {currentDelegation?.company && (
                  <span className="text-gray-500 font-normal whitespace-nowrap">
                    {currentDelegation.company.name}
                  </span>
                )}
              </div>
            );
          },
        }
      ),
      columnHelper.accessor("complete", {
        id: "status",
        header: () => (
          <div className="flex w-full justify-end text-right">
            Statut inscr.
          </div>
        ),
        cell: (info) => (
          <div className="flex justify-end">
            {!info.getValue() ? (
              <Badge variant="orange">Pré-inscrit</Badge>
            ) : (
              <Badge variant="green">Inscrit</Badge>
            )}
          </div>
        ),
      }),
      columnHelper.accessor("payementStatus", {
        maxSize: 1,
        id: "preTestStatus",
        header: () => (
          <div className="flex w-full justify-end text-right">Statut vir.</div>
        ),
        cell: (info) => (
          <div className="flex justify-end">
            {PayementUtil.isWaitingValidation(info.getValue()) ? (
              <Badge variant="sky" className="gap-1">
                <Hourglass className="stoke-2 w-3 h-3" />
                En cours
              </Badge>
            ) : (
              <></>
            )}
          </div>
        ),
      }),
    ];
  }, [
    selected,
    notSelected,
    allSelected,
    setAllSelected,
    setNotSelected,
    setSelected,
  ]);

  const [rows, is_placeholder] = useTablePlaceholderRows(registrations);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!registrations) {
    if (isLoading) return <Skeleton className="h-full w-full min-h-56" />;
    else
      return (
        <LoadingError>Erreur de chargement des pré-inscriptions</LoadingError>
      );
  }

  return (
    <>
      <div className="rounded-lg overflow-auto border border-gray-200">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                  "opacity-0 border-none": is_placeholder(i),
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-16 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
        totalPages={registrations.totalPages}
      />
    </>
  );
};

export default PreRegisteredTable;
