import { useQueryCandidateInfo } from "@/api/candidate/candidate";
import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { Role } from "@/api/enums/Role";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { Button } from "@/components/atoms/Button";
import { Skeleton } from "@/components/atoms/Skeleton";
import FileLink from "@/components/molecules/FileLink";
import LoadingError from "@/components/molecules/LoadingError";
import PayingDialog from "@/components/molecules/PayingDialog/PayingDialog";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import PayementBadge from "@/components/pages/Candidate/Dashboard/Exams/Home/Registrations/RegistrationCard/Cards/PayementBadge";
import { RegistrationUtil } from "@/lib/registration-util";
import { useState } from "react";
interface PayementCardProps {
  registration: CandidateRegistrationDtoClient;
}

const PayementCard = ({ registration }: PayementCardProps) => {
  const { exam, id, reducedPriceStatus } = registration;

  const [open, setOpen] = useState(false);
  const { data, isLoading } = useQueryCandidateInfo();

  if (!data) {
    if (isLoading) return <Skeleton className="w-full h-full min-h-14" />;
    else return <LoadingError>Erreur de chargement du paiement</LoadingError>;
  }

  const {
    personalInfo: { firstName, lastName },
  } = data;

  return (
    <Card>
      <PayingDialog
        open={open}
        onOpenChange={setOpen}
        candidateFirstName={firstName}
        candidateLastName={lastName}
        registrationId={id}
        examDate={exam.date}
        examType={exam.type}
        reducedPriceStatus={reducedPriceStatus}
        registrationEndDate={exam.registeringEndDate}
        role={Role.CANDIDATE}
        displayReducedPriceWarning={[
          ValidationStatus.PENDING,
          ValidationStatus.REFUSED,
        ].includes(reducedPriceStatus)}
      />
      <div className="flex flex-row justify-between w-full items-center">
        <div className="flex flex-row gap-2">
          <CardTitle>Paiement</CardTitle>
          <PayementBadge registration={registration} />
        </div>

        <FileLink
          showName={false}
          file={RegistrationUtil.currentDebitNote(registration)}
        />

        {RegistrationUtil.candidateCanPay(registration) && (
          <Button onClick={() => setOpen(true)}>Régler maintenant</Button>
        )}
      </div>
    </Card>
  );
};

export default PayementCard;
