import { ExamLiveCandidateDtoClient } from "@/api/live-exam/dto/exam-live-candidate.dto";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/Popover";
import { Ellipsis } from "lucide-react";
import FinishTimeDIalog from "./FinishTimeDialog";
import CloseCandidateExamDialog from "./CloseCandidateExamDialog";

interface ActionsPopOverProps {
  candidate: ExamLiveCandidateDtoClient;
}

const ActionsPopOver = ({ candidate }: ActionsPopOverProps) => {
  return (
    <Popover>
      <PopoverTrigger asChild onClick={(e) => e.stopPropagation()}>
        <Ellipsis className="w-5 h-5" />
      </PopoverTrigger>
      <PopoverContent
        className="flex flex-col p-0 w-fit"
        onClick={(e) => e.stopPropagation()}
      >
        <FinishTimeDIalog registration={candidate} />
        <CloseCandidateExamDialog candidate={candidate} />
      </PopoverContent>
    </Popover>
  );
};

export default ActionsPopOver;
