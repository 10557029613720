import { formatDate } from "@/lib/utils";
import { z } from "zod";
import { GlobalFile } from "./globalFile";

const mandatoryString = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .max(50, { message: "50 caractères maximum" })
    .trim();

const optionalString = () => mandatoryString().or(z.literal("")).default("");

const email = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .email({ message: "Veuillez entrer une adresse email valide" })
    .trim();

const password = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .min(8, { message: "Le mot de passe doit contenir au moins 8 caractères" })
    .max(50, {
      message: "Le mot de passe doit contenir au plus 100 caractères",
    })
    .trim()
    .refine(
      (password) => {
        // backend DTO applies the same rules
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return hasUppercase && hasLowercase && hasNumber && hasSymbol;
      },
      {
        message:
          'Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un symbole (!@#$%^&*(),.?":{}|<></>)',
      }
    );

const optionalPhone = () => z.string().optional().default("");

const mandatoryPhone = () => mandatoryString();

// this must modified in dropzone component as well
const DEFAULT_ACCEPTED_FILE_TYPES = [
  "application/pdf",
  "image/jpeg",
  "image/png",
];
const DEFAULT_MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const emptyFile = () => new File([""], "");

const file = (
  accepted_types: string[] = DEFAULT_ACCEPTED_FILE_TYPES,
  max_size: number = DEFAULT_MAX_FILE_SIZE
) =>
  z
    .custom<GlobalFile>()
    // does file exist ?
    .refine(
      (file: GlobalFile) => {
        if (file instanceof File) return file.size > 0;
        return true;
      },
      {
        message: "Fichier manquant",
      }
    )
    // is file type accepted ?
    .refine(
      (file: GlobalFile) => {
        if (file instanceof File) return accepted_types.includes(file.type);
        return true;
      },
      {
        message: `Format invalide : seulement les formats ${accepted_types
          .map((type) => type.split("/")[1])
          .join(", ")}`,
      }
    )
    // is the file size acceptable ?
    .refine(
      (file: GlobalFile) => {
        if (file instanceof File) return file.size <= max_size;
        return true;
      },
      {
        message: `Fichier trop grand. Taille maximale autorisée : ${
          max_size / (1024 * 1024)
        } MB`,
      }
    );

const optionalFile = (
  accepted_types: string[] = DEFAULT_ACCEPTED_FILE_TYPES,
  max_size: number = DEFAULT_MAX_FILE_SIZE
) =>
  z
    .custom<GlobalFile>()
    .optional()
    // does file exist ?
    .refine(
      (file?: GlobalFile) => {
        if (file instanceof File) return file.size > 0;
        return true;
      },
      {
        message: "Fichier manquant",
      }
    )
    // is file type accepted ?
    .refine(
      (file?: GlobalFile) => {
        if (file instanceof File) return accepted_types.includes(file.type);
        return true;
      },
      {
        message: `Format invalide : seulement les formats ${accepted_types
          .map((type) => type.split("/")[1])
          .join(", ")}`,
      }
    )
    // is the file size acceptable ?
    .refine(
      (file?: GlobalFile) => {
        if (file instanceof File) return file.size <= max_size;
        return true;
      },
      {
        message: `Fichier trop grand. Taille maximale autorisée : ${
          max_size / (1024 * 1024)
        } MB`,
      }
    );

const nullDate = () => new Date(0);

const date = (minDate: Date | null = null, maxDate: Date | null = null) =>
  z
    .union([z.string(), z.date()])
    .transform((value) => {
      if (value instanceof Date) {
        return value;
      }

      const parsedDate = new Date(value);
      if (isNaN(parsedDate.getTime())) {
        throw new Error("Format invalide");
      }
      return parsedDate;
    })
    .refine((date) => date.getTime() !== nullDate().getTime(), {
      message: "Champ obligatoire",
    })
    .refine((date) => !minDate || date >= minDate, {
      message: `Veuillez choisir une date postérieure au ${
        minDate ? formatDate(minDate) : ""
      }`,
    })
    .refine((date) => !maxDate || date <= maxDate, {
      message: `Veuillez choisir une date antérieure au ${
        maxDate ? formatDate(maxDate) : ""
      }`,
    });

const utc = () =>
  z
    .number()
    .refine((n) => n >= -12 && n <= 14, {
      message: "La valeur UTC doit se trouver dans l'intervalle [-12, 14].",
    })
    .default(1); // France UTC

const id = (message?: string) =>
  z.coerce.number().refine((n) => n >= 0, {
    message: message ?? "Veuillez choisir une valeur.",
  });

export {
  date,
  email,
  emptyFile,
  file,
  id,
  mandatoryPhone,
  mandatoryString,
  nullDate,
  optionalFile,
  optionalPhone,
  optionalString,
  password,
  utc,
};
