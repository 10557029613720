import { cva } from "class-variance-authority";
import cn from "classnames";
import { Info } from "lucide-react";

interface AuraInfoProps {
  className?: string;
  variant?: "green" | "blue" | "red" | "orange";
}

const auraInfoVariants = cva(
  "flex relative items-center justify-center flex p-2",
  {
    variants: {
      variant: {
        green: "text-green-600 border-green-400 dark:border-green-200",
        blue: "text-blue-600 border-blue-400 dark:border-blue-200",
        red: "text-red-600 border-red-400 dark:border-red-200",
        orange: "text-orange-600 border-orange-400 dark:border-orange-200",
      },
    },
    defaultVariants: {
      variant: "green",
    },
  }
);

const AuraInfo = ({ className, variant = "green" }: AuraInfoProps) => (
  <div className={cn(auraInfoVariants({ variant }), className)}>
    <Info className="flex absolute w-4 h-4" />
    <div
      className={cn("absolute w-[23px] h-[23px] border-2 rounded-full", {
        "border-green-300": variant === "green",
        "border-blue-300": variant === "blue",
        "border-red-300": variant === "red",
        "border-orange-300": variant === "orange",
      })}
    />
    <div
      className={cn("absolute w-[31px] h-[31px] border-2 rounded-full", {
        "border-green-100": variant === "green",
        "border-blue-100": variant === "blue",
        "border-red-100": variant === "red",
        "border-orange-100": variant === "orange",
      })}
    />
  </div>
);

export default AuraInfo;
