"use client";

import { cn } from "@/lib/utils";
import { Button } from "@atoms/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@atoms/Command";
import { Popover, PopoverContent, PopoverTrigger } from "@atoms/Popover";
import { Check, ChevronDown } from "lucide-react";
import { useMemo, useState } from "react";
import { useFieldState } from "./use-field-state";

type Option<T> = {
  value: T;
  label: string | (() => JSX.Element);
  disabled?: boolean;
  disabledMessage?: string;
};

interface ComboboxFieldProps<T> {
  options: Option<T>[];
  placeholder: string;
  id?: string;
  optional?: boolean;
  disabled?: boolean;
  className?: string;
  contentWidth?: number;
  displaySearchBox?: boolean;
  stringifyValue?: (value: T) => string; // Function to convert value to string
  value?: T;
  onChange?: (value: T) => void;
}

export const ComboboxField = <T,>({
  options,
  placeholder,
  disabled = false,
  id,
  optional = false,
  className = "",
  contentWidth = -1,
  displaySearchBox = true,
  stringifyValue = (value) => String(value),
  value: valueProp,
  onChange: onChangeProp,
}: ComboboxFieldProps<T>) => {
  const [open, setOpen] = useState(false);
  const { error, value, onChange } = useFieldState(valueProp, onChangeProp);

  const option = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  const label = useMemo(
    () =>
      option
        ? typeof option.label === "function"
          ? option.label()
          : option.label
        : null,
    [option]
  );

  const handleSelect = (currentValue: string) => {
    const selectedOption = options.find(
      (opt) => stringifyValue(opt.value) === currentValue
    );
    if (selectedOption) {
      onChange(selectedOption.value);
    } else if (optional) {
      onChange(undefined as T);
    }
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "w-full justify-between disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 disabled:opacity-100",
            error ? "border-red-500 border" : "border-gray-300",
            "max-w-full overflow-hidden text-ellipsis whitespace-nowrap",
            className
          )}
          id={id}
          disabled={disabled}
        >
          <span className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[calc(100%-2rem)]">
            {label ?? placeholder}
          </span>
          <ChevronDown className="ml-2 h-5 w-5 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          contentWidth === -1
            ? "w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]"
            : `w-[${contentWidth}px]`,
          "p-0"
        )}
      >
        <Command>
          {displaySearchBox && (
            <CommandInput
              className="my-2 border-none focus:border-none"
              placeholder="Rechercher..."
            />
          )}
          <CommandList className="bg-blue-50">
            <CommandEmpty>Aucun résultat.</CommandEmpty>
            <CommandGroup>
              {[
                ...(optional ? [{ value: null as T, label: "-" }] : []),
                ...options,
              ].map((option) => {
                const label =
                  typeof option.label === "function"
                    ? option.label()
                    : option.label;

                return (
                  <CommandItem
                    key={stringifyValue(option.value)}
                    value={stringifyValue(option.value)}
                    disabled={option.disabled}
                    onSelect={handleSelect}
                    className="data-[selected=true]:bg-white"
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        stringifyValue(value) === stringifyValue(option.value)
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    {label}
                    {option.disabled && option.disabledMessage && (
                      <span className="italic">
                        {" "}
                        - {option.disabledMessage}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
