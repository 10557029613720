import { QuestionBasicDtoClient } from "@/api/question/dto/question.dto";
import { useQueryQuestionSearch } from "@/api/question/question";
import { Badge } from "@/components/atoms/Badge";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import RedCross from "@/components/atoms/RedCross";
import { ComboboxField } from "@/components/molecules/Combobox/Combobox";
import { ColumnPerimeterUtil } from "@/constants/column-perimeter.enum";
import useChildForm from "@/hooks/ChildForm";
import { Trash2 } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";

interface QuestionReplaceFormProps {
  index: number;
  remove?: () => void;
  qcmQuestions: QuestionBasicDtoClient[];
  addNumberToId: (number: number, id: number) => void;
}

const QuestionReplaceForm = ({
  index,
  remove,
  qcmQuestions,
  addNumberToId,
}: QuestionReplaceFormProps) => {
  const { control, transformPath } = useChildForm(`replacements.${index}`);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const number = useMemo(
    () => Number(debouncedSearchText),
    [debouncedSearchText]
  );
  const numberIsValid = useMemo(
    () => !Number.isNaN(number) && number >= 0,
    [number]
  );

  const query = useQueryQuestionSearch(number, {
    enabled: numberIsValid,
  });

  useEffect(() => {
    if (!query.data) return;
    const { number, id } = query.data;
    addNumberToId(number, id);
  }, [addNumberToId, number, query.data]);

  return (
    <div className="flex flex-row gap-4">
      <FormField
        control={control}
        name={transformPath("previousId")}
        render={() => (
          <FormItem className="w-full">
            <FormLabel>Question à remplacer</FormLabel>
            <FormControl>
              <ComboboxField
                displaySearchBox={false}
                className="-z-50"
                options={qcmQuestions
                  .sort((q0, q1) => q0.number - q1.number)
                  .map(({ id, number, perimeter }) => ({
                    value: id,
                    label: () => (
                      <div>
                        {number} -{" "}
                        <Badge variant="white">
                          {ColumnPerimeterUtil.fromPerimeter(perimeter)}
                        </Badge>
                      </div>
                    ),
                  }))}
                placeholder="-"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name={transformPath("newNumber")}
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel>Nouvelle question à ajouter</FormLabel>
            <FormControl>
              <div className="relative">
                <Input
                  {...field}
                  type="number"
                  className="w-full"
                  placeholder="Numéro de la question"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    field.onChange(e);
                  }}
                />
                <div className="absolute inset-y-0 right-2 flex items-center">
                  {query.data ? (
                    <Badge variant="white">
                      {ColumnPerimeterUtil.fromPerimeter(query.data.perimeter)}
                    </Badge>
                  ) : (
                    <RedCross />
                  )}
                </div>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {remove && (
        <button
          onClick={remove}
          type="button"
          className="flex flex-col justify-end pb-3"
        >
          <Trash2 className="w-5 h-5 text-red-600" />
        </button>
      )}
    </div>
  );
};

export default QuestionReplaceForm;
