import { keyFactory } from "@/api/keyFactory";
import { useMutationRegistrationAdditionalTime } from "@/api/registration/registration";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { ComboboxField } from "@/components/molecules/Combobox/Combobox";
import { useQueryClient } from "@tanstack/react-query";
import { Clock } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";

interface AdditionalTimeDialogProps {
  candidateName: string;
  currentAdditionalTimeMinutes?: number;
  registrationId: number;
}

export const AdditionalTimeDialog = ({
  currentAdditionalTimeMinutes,
  candidateName,
  registrationId,
}: AdditionalTimeDialogProps) => {
  const [additionalTimeMinutes, setAdditionalTimeMinutes] = useState(
    currentAdditionalTimeMinutes ?? 5
  );
  const queryClient = useQueryClient();
  const modifying = !!currentAdditionalTimeMinutes;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentAdditionalTimeMinutes !== undefined)
      setAdditionalTimeMinutes(currentAdditionalTimeMinutes);
  }, [currentAdditionalTimeMinutes]);

  const setAdditionalTimeMutation = useMutationRegistrationAdditionalTime({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keyFactory.exam.registrations(),
      });
      toast.success(
        `Le tiers temps a été ${!modifying ? "modifié" : "supprimé"}`
      );
      setOpen(false);
    },
    onError: () => {
      toast.error("Échec de la requête", {
        description: "Votre action n'a pas pu être prise en compte.",
      });
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className="flex flex-row gap-2 items-center p-2 hover:bg-gray-50 transition-colors rounded-sm"
        onClick={(e) => e.stopPropagation()}
      >
        {modifying ? (
          <>
            <Clock className="w-4 h-4" />
            <span>Modifier le tiers temps</span>
          </>
        ) : (
          <>
            <Clock className="w-4 h-4" />
            <span>Ajouter un tiers temps</span>
          </>
        )}
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-6"
      >
        <DialogHeader>
          <DialogTitle>
            {modifying ? "Modifier tiers temps : " : "Tiers temps : "}{" "}
            {candidateName}
          </DialogTitle>
          <DialogDescription hidden={true}>
            Modification du tiers temps
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-1.5">
          <label htmlFor="timeToAddMinutes">Combien de temps à ajouter</label>
          <ComboboxField
            disabled={modifying}
            options={Array.from({ length: 120 }, (_, i) => {
              const minutes = i * 5;
              const hours = Math.floor(minutes / 60);
              const minutesText = minutes % 60;

              return {
                value: i * 5,
                label: `${hours.toString().padStart(2, "0")}:${minutesText
                  .toString()
                  .padStart(2, "0")}`,
              };
            })}
            placeholder="-"
            value={additionalTimeMinutes}
            onChange={setAdditionalTimeMinutes}
            id="timeToAddMinutes"
          />
        </div>
        <div className="flex flex-row gap-2">
          <Button
            variant="ghostWithBorders"
            className="w-full"
            onClick={() => setOpen(false)}
          >
            Annuler
          </Button>
          {modifying ? (
            <Button
              className="w-full"
              variant="red"
              onClick={() =>
                setAdditionalTimeMutation.mutate(
                  {
                    registrationId,
                    additionalTimeMinutes: 0,
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: keyFactory.exam.base(),
                      });
                      toast.success("Le tiers temps a été supprimé");
                      setAdditionalTimeMinutes(0);
                    },
                    onError: () => {
                      toast.error("Échec de la requête", {
                        description:
                          "Votre action n'a pas pu être prise en compte.",
                      });
                    },
                  }
                )
              }
            >
              {setAdditionalTimeMutation.isPending
                ? "Suppression..."
                : "Supprimer"}
            </Button>
          ) : (
            <Button
              className="w-full"
              disabled={
                additionalTimeMinutes === 0 ||
                setAdditionalTimeMutation.isPending
              }
              onClick={() =>
                setAdditionalTimeMutation.mutate({
                  registrationId,
                  additionalTimeMinutes,
                })
              }
            >
              {setAdditionalTimeMutation.isPending
                ? "Validation..."
                : "Valider le tiers temps"}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AdditionalTimeDialog;
