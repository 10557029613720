import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Role } from "../enums/Role";
import { keyFactory } from "../keyFactory";
import { NestError } from "../nest-error";
import { AskChangePasswordDto } from "./dto/ask-change-password.dto";
import { ChangeEmailDto } from "./dto/change-email.dto";
import { ChangePasswordDto } from "./dto/change-password.dto";
import { LoginDto, LoginResponse } from "./dto/login.dto";
import { RegisterCandidateDto } from "./dto/register-candidate.dto";
import {
  CompanyAccountCreationDto,
  CompanyPreFilledInfo,
} from "./dto/register-company.dto";

// login

const logIn = async (data: LoginDto) =>
  await axios
    .post<LoginResponse>(ENDPOINT.auth.postLogin(), data)
    .then(({ data }) => data);

export const useLogInMutation = (
  props?: UseMutationOptions<LoginResponse, AxiosError, LoginDto>
) => {
  return useMutation({
    mutationFn: logIn,
    ...props,
  });
};

// log out

const logout = async () => {
  return await axios.post<void>(ENDPOINT.auth.postLogout());
};

export const useLogoutMutation = (
  props?: UseMutationOptions<AxiosResponse<void>, AxiosError>
) => {
  return useMutation({
    mutationFn: logout,
    ...props,
  });
};

// register candidate

const registerCandidate = async (data: RegisterCandidateDto) => {
  return await axios.post<null>(ENDPOINT.auth.register.postCandidate(), data);
};

export const useRegisterUserMutation = (
  props?: UseMutationOptions<
    AxiosResponse<null>,
    AxiosError,
    RegisterCandidateDto
  >
) => {
  return useMutation({
    mutationFn: registerCandidate,
    ...props,
  });
};

// get role

const getMyRole = async () => {
  return axios.get<Role>(ENDPOINT.auth.getRole()).then((res) => res.data);
};

export const useGetMyRoleQuery = (
  props?: UseQueryOptions<Role, AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.auth.role(),
    queryFn: getMyRole,
    ...props,
  });
};

// ask change password

const askChangePassword = async (data: AskChangePasswordDto) => {
  return await axios.post(ENDPOINT.auth.password.postAskChange(), data);
};

export const useAskChangePasswordMutation = (
  props?: UseMutationOptions<
    AxiosResponse<void>,
    AxiosError,
    AskChangePasswordDto
  >
) => {
  return useMutation({
    mutationFn: askChangePassword,
    ...props,
  });
};

// change password

const changePassword = async (data: ChangePasswordDto, token: string) => {
  return await axios.post(ENDPOINT.auth.password.postChange(), data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useChangePasswordMutation = (
  props?: UseMutationOptions<
    AxiosResponse,
    AxiosError,
    {
      data: ChangePasswordDto;
      token: string;
    }
  >
) => {
  return useMutation({
    mutationFn: ({ data, token }: { data: ChangePasswordDto; token: string }) =>
      changePassword(data, token),
    ...props,
  });
};

// change email

interface ChangeEmailProps {
  userId?: number;
  dto: ChangeEmailDto;
}

const changeEmail = async ({ dto, userId }: ChangeEmailProps) => {
  await axios.put(ENDPOINT.auth.putChangeEmail(userId), dto);
};

export const useMutationAuthChangeEmail = (
  props?: UseMutationOptions<void, AxiosError<NestError>, ChangeEmailProps>
) => {
  return useMutation({
    mutationFn: changeEmail,
    ...props,
  });
};

// company get prefilled info

const getCompanyPrefilledInfo = async (token: string) => {
  return axios
    .get<CompanyPreFilledInfo>(
      ENDPOINT.auth.register.company.getPrefilledInfo(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);
};

export const useCompanyPrefilledInfoQuery = (
  token: string,
  props?: UseQueryOptions<CompanyPreFilledInfo, AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.auth.register.company.prefilledInfo(),
    queryFn: () => getCompanyPrefilledInfo(token),
    ...props,
  });
};

// company register

interface CompanyRegisterInput {
  data: CompanyAccountCreationDto;
  token: string;
}

const companyRegister = async ({ data, token }: CompanyRegisterInput) => {
  await axios.post(ENDPOINT.auth.register.company.postRegister(), data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useCompanyRegisterMutation = (
  props?: UseMutationOptions<void, AxiosError, CompanyRegisterInput>
) => {
  return useMutation({
    mutationFn: companyRegister,
    ...props,
  });
};

// candidate exam login

const examLogin = async (body: LoginDto) =>
  await axios
    .post<LoginResponse>(ENDPOINT.auth.postCandidateExamLogin(), body)
    .then(({ data }) => data);

export const useMutationAuthExamLogin = (
  props?: Partial<UseMutationOptions<LoginResponse, AxiosError, LoginDto>>
) => {
  return useMutation({
    mutationFn: examLogin,
    ...props,
  });
};
