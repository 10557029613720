import { useMutationAdminDelete } from "@/api/admin/admin";
import { AdminDtoClient } from "@/api/admin/dto/admin.dto";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { useQueryClient } from "@tanstack/react-query";
import { Trash2 } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

interface DeleteAdminDialogProps {
  admin: AdminDtoClient;
}

const DeleteAdminDialog = ({
  admin: { email, id },
}: DeleteAdminDialogProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const deleteMutation = useMutationAdminDelete({
    onSuccess: () => {
      toast.success(`Administrateur ${email} supprimé`);

      queryClient.invalidateQueries({
        queryKey: keyFactory.admin.getAll(),
      });

      setOpen(false);
    },
    onError: () => {
      toast.error("Échec de la requête", {
        description: "L'administrateur n'a pas pu être supprimé",
      });
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Trash2 className="text-red-600 w-5 h-5 hover:text-red-800" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Supprimer l'administrateur {email} ?</DialogTitle>
          <DialogDescription hidden>
            Supprimer l'administrateur {email}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-row gap-4">
          <Button
            className="w-full"
            variant="ghostWithBorders"
            onClick={() => setOpen(false)}
          >
            Annuler
          </Button>
          <Button
            className="w-full"
            variant="red"
            onClick={() => deleteMutation.mutate(id)}
          >
            Supprimer
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteAdminDialog;
