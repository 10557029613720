import { Input } from "@/components/atoms/Input";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import CompaniesTable from "./CompaniesTable";

const Companies = () => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);

  return (
    <div className="flex flex-col w-full bg-white h-screen">
      <div className="flex w-full border-b border-b-gray-200 px-6 py-3 bg-white">
        <h1>Entreprises</h1>
      </div>
      <div className="py-4 px-6 flex flex-col gap-3 w-full h-full">
        <Input
          className="shadow-none"
          id="shadcn-input"
          searchIcon
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Rechercher"
        />
        <CompaniesTable searchText={debouncedSearchText} />
      </div>
    </div>
  );
};

export default Companies;
