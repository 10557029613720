import { cn } from "@/lib/utils";
import { Edit } from "lucide-react";
import { ReactNode } from "react";
import { Button } from "../atoms/Button";

interface ModifiableFormProps {
  children?: ReactNode;
  setModifiable: (b: boolean) => void;
  onCancel: () => void;
  isModifiable: boolean;
  modifyText?: string;
  saveText?: string;
  className?: string;
  pending?: boolean;
}

const ModifiableForm = ({
  children,
  onCancel,
  setModifiable,
  isModifiable,
  className,
  modifyText = "Modifier",
  saveText = "Enregistrer",
  pending = false,
}: ModifiableFormProps) => {
  return (
    <div
      className={cn(
        "flex flex-col flex-start p-6 self-stretch gap-4 rounded-lg items-end bg-white",
        className
      )}
    >
      <div className="absolute flex flex-row items-center gap-4">
        <Button
          type="button"
          onClick={() => {
            if (isModifiable) onCancel();
            setModifiable(!isModifiable);
          }}
          variant="outlineBlue"
          disabled={pending}
        >
          {!isModifiable && <Edit className="w-4 h-4" />}
          {!isModifiable ? modifyText : "Annuler"}
        </Button>
        {isModifiable && (
          <Button
            type="submit"
            variant="default"
            className="w-fit"
            disabled={pending}
          >
            {saveText}
          </Button>
        )}
      </div>
      {children}
    </div>
  );
};

export default ModifiableForm;
