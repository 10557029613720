import { useMutationCertificateGenerate } from "@/api/certificate/certificate";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import useLoadingToast from "@/hooks/use-loading-toast";
import { useQueryClient } from "@tanstack/react-query";
import { GraduationCap } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

interface GenerateCertificatesDialogProps {
  examId: number;
}

const GenerateCertificatesDialog = ({
  examId,
}: GenerateCertificatesDialogProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  useLoadingToast({
    message: "Génération des certificats en cours...",
    loading,
  });

  const { isPending, mutate } = useMutationCertificateGenerate({
    onSuccess: () => {
      setLoading(false);

      queryClient.invalidateQueries({
        queryKey: keyFactory.exam.base(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.registration.base(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.queues.jobs(),
      });

      toast.success("Génération demandée", {
        description:
          'Vous pouvez suivre l\'évolution de la tâche en cliquant sur le bouton  "Tâches"',
      });
    },
    onError: () => {
      setLoading(false);

      toast.error("Erreur", {
        description: "Les certificats n'ont pas pu être générés",
      });
    },
  });

  const onGenerate = () => {
    setLoading(true);
    setOpen(false);
    mutate(examId);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild disabled={isPending}>
        <Button className="flex flex-row items-center gap-2">
          <GraduationCap className="rounded-full w-6 h-6 text-white p-0.5" />
          {isPending ? "Génération en cours..." : "Générer les certificats"}
        </Button>
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-6 p-4"
      >
        <DialogHeader>
          <DialogTitle>Générer les certificats</DialogTitle>
          <DialogDescription hidden={true}>
            Génération des certificats
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-row gap-2 w-full">
          <Button
            disabled={isPending}
            variant="ghostWithBorders"
            onClick={() => setOpen(false)}
            className="flex-grow"
          >
            Annuler
          </Button>
          <Button
            onClick={onGenerate}
            disabled={isPending}
            className="flex-grow"
          >
            {isPending ? "En cours..." : "Générer"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GenerateCertificatesDialog;
