export enum ExamExport {
  Payments = "Payments",
  Registrations = "Registrations",
  Prerequisites = "Prerequisites",
  Results = "Results",
  Answers = "Answers",
  Live = "Live",
  CopiesComplete = "CopiesComplete",
  CopiesPartial = "CopiesPartial",
  QCMsExcel = "QCMsExcel",
  QCMsPDF = "QCMsPDF",
}
