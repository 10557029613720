import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms/Dialog";
import RedCross from "@/components/atoms/RedCross";
import { CheckIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const PayementAcknowledgeDialog = () => {
  const [params] = useSearchParams();
  const [state, setState] = useState<null | "success" | "cancelled">(null);

  const getBooleanParam = useCallback(
    (param: string) => params.get(param) === "true",
    [params]
  );

  const payementSuccessful = getBooleanParam("payementSuccessful");
  const payementCancelled = getBooleanParam("payementCancelled");

  useEffect(() => {
    if (payementSuccessful) setState("success");
    else if (payementCancelled) setState("cancelled");
    else setState(null);
  }, [payementCancelled, payementSuccessful]);

  return (
    <Dialog
      open={state !== null}
      onOpenChange={(e) => {
        if (!e) setState(null);
      }}
    >
      <DialogContent className="max-w-80 items-center flex flex-col">
        <DialogHeader>
          <DialogTitle>
            {state === "success" ? "Paiement réussi" : "Échec du paiement"}
          </DialogTitle>
          <DialogDescription hidden>
            {state === "success" ? "Paiement réussi" : "Échec du paiement"}
          </DialogDescription>
        </DialogHeader>
        {state === "success" ? (
          <CheckIcon className="w-5 h-5 text-white bg-green-600 rounded-full p-0.5" />
        ) : (
          <RedCross />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PayementAcknowledgeDialog;
