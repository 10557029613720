import { FormationInstituteDtoClient } from "@/api/dto/formation-institutes.dto";
import {
  useFormationInstituteDeleteMutation,
  useFormationInstitutesQuery,
} from "@/api/formation-institute/formation-institute";
import { keyFactory } from "@/api/keyFactory";
import { Skeleton } from "@/components/atoms/Skeleton";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import LoadingError from "@/components/molecules/LoadingError";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/molecules/Table";
import { cn } from "@/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Trash2 } from "lucide-react";
import { useMemo, useState } from "react";
import { toast } from "sonner";

const columnHelper = createColumnHelper<FormationInstituteDtoClient>();

const FormationInstitutesTable = () => {
  const {
    data: formationInstitutes,
    isLoading,
    isError,
  } = useFormationInstitutesQuery();
  const deleteMutation = useFormationInstituteDeleteMutation();
  const [deleteData, setDeleteData] =
    useState<null | FormationInstituteDtoClient>(null);
  const queryClient = useQueryClient();

  const columns = useMemo(
    () => [
      columnHelper.accessor("description", {
        id: "description",
        header: "OF",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor((x) => x, {
        id: "delete",
        header: () => <></>,
        cell: (info) => (
          <button
            onClick={() => setDeleteData(info.getValue())}
            className="ml-auto flex justify-center"
          >
            <Trash2 className="text-red-600 w-5 h-5" />
          </button>
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    data: formationInstitutes ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) return <Skeleton className="w-full h-full" />;
  if (isError)
    return (
      <LoadingError className="p-8">
        Erreur du chargement des informations de l'entreprise
      </LoadingError>
    );

  return (
    <>
      <ConfirmDialog
        open={deleteData !== null}
        onOpenChange={(open) => {
          if (!open) setDeleteData(null);
        }}
        onResult={(accepted) => {
          if (accepted) {
            if (!deleteData) return;
            deleteMutation.mutate(deleteData.id, {
              onError: () => {
                toast.error("Échec de la requête", {
                  description:
                    "L'institut de formation n'a pas pu être supprimé",
                });
              },
              onSuccess: () => {
                setDeleteData(null);
                queryClient.invalidateQueries({
                  queryKey: keyFactory.formationInstitutes.base(),
                });
                toast.success("Institut de formation supprimé");
              },
            });
          } else setDeleteData(null);
        }}
        title={`Suppression de ${deleteData?.description}`}
        validateStr="Supprimer"
        cancelStr="Annuler"
        validateButtonVariant="red"
        cancelButtonVariant="ghostWithBorders"
        validatePending={deleteMutation.isPending}
      />
      <div className="rounded-lg overflow-auto border border-gray-200">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-16 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default FormationInstitutesTable;
