import { useQueryAdminAll } from "@/api/admin/admin";
import { Skeleton } from "@/components/atoms/Skeleton";
import LoadingError from "@/components/molecules/LoadingError";
import AddAdminDialog from "./AddAdminDialog";
import AdminsTable from "./AdminsTable/AdminsTable";

const Admins = () => {
  const { data, isLoading } = useQueryAdminAll();

  if (!data) {
    if (isLoading) return <Skeleton className="w-full h-full" />;
    else
      return (
        <LoadingError>Erreur de chargement des administrateurs</LoadingError>
      );
  }

  return (
    <div className="flex flex-col gap-6 h-full w-full">
      <div className="flex flex-row justify-between">
        <h2>Administrateurs</h2>
        <AddAdminDialog />
      </div>
      <AdminsTable admins={data} />
    </div>
  );
};

export default Admins;
