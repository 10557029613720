export enum ExamStatus {
  QCMS_NOT_GENERATED = "QCMS_NOT_GENERATED",
  QCMS_GENERATED = "QCMS_GENERATED",
  PASSED = "PASSED",
  CORRECTED = "CORRECTED",
  NOTES_GENERATED = "NOTES_GENERATED",
  CERTIFICATES_GENERATED = "CERTIFICATES_GENERATED",
}

const order = {
  [ExamStatus.QCMS_NOT_GENERATED]: 0,
  [ExamStatus.QCMS_GENERATED]: 1,
  [ExamStatus.PASSED]: 2,
  [ExamStatus.CORRECTED]: 3,
  [ExamStatus.NOTES_GENERATED]: 4,
  [ExamStatus.CERTIFICATES_GENERATED]: 5,
};

export class ExamStatusUtil {
  static order = (exam: ExamStatus) => order[exam];
}
