import { useMutationExamResultModifyGrade } from "@/api/exam-result/exam-result";
import { keyFactory } from "@/api/keyFactory";
import { AdminRegistrationDtoClient } from "@/api/registration-dto/dto/admin-registration.dto";
import { Button } from "@/components/atoms/Button";
import { Checkbox } from "@/components/atoms/CheckBox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { Form, FormField } from "@/components/atoms/Form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { Edit2 } from "lucide-react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import RoundedInput from "./RoundedInput";

const nonNullNumber = () =>
  z.coerce
    .number()
    .min(0, { message: "Veuillez rentrer une note positive ou nulle." });

const modifyGradeSchema = z.object({
  qcmGrade: nonNullNumber().optional(),
  edcGrades: z.array(
    z.object({
      value: nonNullNumber(),
    })
  ),
});

type ModifyGradeSchema = z.infer<typeof modifyGradeSchema>;

interface ModifyGradeProps {
  registration: AdminRegistrationDtoClient;
}

const ModifyGradeDialog = ({
  registration: { examResult, edcResult, id },
}: ModifyGradeProps) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutationExamResultModifyGrade();

  const form = useForm<ModifyGradeSchema>({
    resolver: zodResolver(modifyGradeSchema),
    defaultValues: {
      edcGrades:
        edcResult?.notes.map((n) => ({
          value: n,
        })) ?? [],
      ...(examResult && { qcmGrade: examResult.grade }),
    },
  });

  const onSubmit = (data: ModifyGradeSchema) => {
    mutate(
      {
        registrationId: id,
        ...(data.qcmGrade && { qcmGrade: data.qcmGrade }),
        ...(data.edcGrades.length && {
          edcGrades: data.edcGrades.map((n) => n.value),
        }),
      },
      {
        onSuccess: () => {
          toast.success("Notes modifiées", {
            description: "Les documents affectés ont été mis à jour.",
          });
          queryClient.invalidateQueries({
            queryKey: keyFactory.exam.registrations(),
          });
          queryClient.invalidateQueries({
            queryKey: keyFactory.candidate.base(),
          });
          setOpen(false);
        },
        onError: () => {
          toast.error("Échec de la requête", {
            description: "Votre action n'a pas pu être prise en compte.",
          });
        },
      }
    );
  };

  const edcGradesFieldArray = useFieldArray({
    control: form.control,
    name: "edcGrades",
  });

  if (!edcResult && !examResult) return false;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className="flex flex-row items-center p-2 gap-2"
        onClick={(e) => e.stopPropagation()}
      >
        <Edit2 className="w-4 h-4" />
        Modifier les notes
      </DialogTrigger>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>Modifier les notes</DialogTitle>
          <DialogDescription hidden={true}>
            Modifier les notes
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            {examResult && (
              <FormField
                control={form.control}
                name="qcmGrade"
                render={({ field }) => (
                  <RoundedInput
                    title="QCM"
                    gradeTotal={examResult.gradeTotal}
                    {...field}
                  />
                )}
              />
            )}

            {edcResult &&
              edcGradesFieldArray.fields.map((field, index) => (
                <FormField
                  key={field.id}
                  control={form.control}
                  name={`edcGrades.${index}.value`}
                  render={({ field }) => (
                    <RoundedInput
                      title={
                        edcResult.notesTotal.length === 1
                          ? "Note Globale EDC"
                          : edcResult.descriptions[index]
                      }
                      gradeTotal={edcResult.notesTotal[index]}
                      {...field}
                    />
                  )}
                />
              ))}

            <div className="flex flex-row gap-2 items-center">
              <Checkbox
                name="check-confirm"
                checked={checked}
                onClick={() => setChecked((prev) => !prev)}
                id="check-confirm"
              />
              <label htmlFor="check-confirm">
                Je confirme la modification des notes
              </label>
            </div>

            <Button
              type="submit"
              className="w-full"
              disabled={isPending || !checked}
            >
              {isPending ? "En cours..." : "Modifier"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyGradeDialog;
