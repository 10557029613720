import { Input } from "@/components/atoms/Input";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { CandidateContext } from "../candidate-context";
import ResultsTable from "./ResultsTable/ResultsTable";

const Results = () => {
  const { candidate } = useOutletContext<CandidateContext>();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);

  return (
    <div className="py-4 px-6 flex flex-col gap-3 w-full h-full">
      <Input
        className="shadow-none"
        id="shadcn-input"
        searchIcon
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Rechercher"
      />
      <ResultsTable
        candidateId={candidate.id}
        searchText={debouncedSearchText}
      />
    </div>
  );
};

export default Results;
