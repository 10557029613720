import { ExamDtoClient } from "@/api/dto/exam.dto";
import { useQueryRegistration } from "@/api/registration/registration";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/atoms/Collapsible";
import { Skeleton } from "@/components/atoms/Skeleton";
import FileLink from "@/components/molecules/FileLink";
import LoadingError from "@/components/molecules/LoadingError";
import { RegistrationUtil } from "@/lib/registration-util";
import { cn, formatDate } from "@/lib/utils";
import { useState } from "react";
import DocumentTypeBadge from "./DocumentTypeBadge";

interface SessionDocumentsProps {
  candidateId: number;
  registrationId: number;
  isLast: boolean;
  exam: ExamDtoClient;
}

const SessionDocuments = ({
  isLast,
  registrationId,
  exam,
}: SessionDocumentsProps) => {
  const registrationQuery = useQueryRegistration(registrationId, {
    enabled: false,
  });
  const [open, setOpen] = useState(false);

  const data = registrationQuery.data;

  const debitUnpaid = data ? RegistrationUtil.unpaidDebitNote(data) : undefined;
  const debitPaid = data ? RegistrationUtil.paidDebitNote(data) : undefined;
  const rows = [
    debitPaid,
    debitUnpaid,
    data?.convocation,
    data?.examResult?.attendanceCertificate,
    data?.examResult?.resultsDocument,
    data?.examResult?.certificate,
  ];

  return (
    <Collapsible
      open={open}
      onOpenChange={(open) => {
        if (open && !registrationQuery.data) {
          registrationQuery.refetch();
        }
        setOpen(open);
      }}
    >
      <CollapsibleTrigger
        className={cn(
          "flex flex-row items-center bg-gray-50 border border-gray-200 w-full text-gray-900",
          { "rounded-b-lg": isLast && !open, "mt-3": open, "border-t-0": !open }
        )}
      >
        <span className="flex px-4 py-3 w-1/3">{exam.number}</span>
        <span className="flex px-4 py-3 w-1/3">{formatDate(exam.date)}</span>
      </CollapsibleTrigger>
      <CollapsibleContent
        className={cn("flex flex-col w-full", {
          "mb-3 border-b": open,
        })}
      >
        <div className="flex flex-row items-center w-full border-b border-x border-gray-200 bg-gray-50 text-gray-500">
          <span className="flex px-4 py-3 w-1/3">Document</span>
          <span className="flex px-4 py-3 w-1/3">Date du dépôt</span>
        </div>
        {!registrationQuery.data ? (
          <div className="px-4 py-3">
            {registrationQuery.isLoading ? (
              <Skeleton className="w-full h-full min-h-12" />
            ) : (
              <LoadingError>Erreur de chargement des documents</LoadingError>
            )}
          </div>
        ) : (
          rows
            .filter((f) => !!f)
            .map((file, i) => (
              <div
                key={i}
                className={cn(
                  "flex flex-row items-center w-full border-b border-x border-gray-200",
                  {
                    "border-b-0": i === rows.length - 1,
                    "bg-gray-50": i % 2,
                  }
                )}
              >
                <div className="w-1/3 py-3 px-4">
                  <DocumentTypeBadge type={file.type} />
                </div>
                <span className="w-1/3 py-3 px-4">
                  {file.createdAt && formatDate(file.createdAt)}
                </span>
                <div className="flex w-1/3 py-3 px-4 justify-end">
                  <FileLink file={file}>Télécharger</FileLink>
                </div>
              </div>
            ))
        )}
      </CollapsibleContent>
    </Collapsible>
  );
};

export default SessionDocuments;
