import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import {
  useMutationFormationAttestationAllProvided,
  useMutationFormationAttestationNewDocuments,
} from "@/api/formation-attestation/formation-attestation";
import { keyFactory } from "@/api/keyFactory";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import { Checkbox } from "@/components/atoms/CheckBox";
import GreenCheck from "@/components/atoms/GreenCheck";
import { Label } from "@/components/atoms/Label";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";
import AttestationsDialog from "./AttestationsDialog";

interface AttestationCardProps {
  registration: CandidateRegistrationDtoClient;
}

const AttestationCard = ({ registration }: AttestationCardProps) => {
  const [confirmAttestationDialogOpen, setConfirmAttestationDialogOpen] =
    useState(false);
  const queryClient = useQueryClient();
  const [attestationsDialogOpen, setAttestationsDialogOpen] = useState(false);

  // api calls

  const allAttestationsProvidedMutation =
    useMutationFormationAttestationAllProvided({
      onSuccess: (_, { allAttestationsProvided }) => {
        toast.success("Information envoyée", {
          description: !allAttestationsProvided
            ? "Le CIFMD vous informera de la validation de votre attestation de formation."
            : "Vous pouvez déposer d'autres attestations de formation.",
        });

        queryClient.invalidateQueries({
          queryKey: keyFactory.candidate.registrations(),
        });

        setAttestationsDialogOpen(false);
      },
      onError: () => {
        toast.error("Échec de la requête", {
          description: "Votre action n'a pas pu être prise en compte.",
        });
      },
    });

  const formationCertificatesMutation =
    useMutationFormationAttestationNewDocuments({
      onSuccess: () => {
        toast.success("Attestations déposées");
        queryClient.invalidateQueries({
          queryKey: keyFactory.candidate.registrations(),
        });
        setAttestationsDialogOpen(false);
      },
      onError: () => {
        toast.error("Échec de la requête", {
          description: "Votre action n'a pas pu être prise en compte.",
        });
      },
    });

  // component

  if (registration.formationCertificatesStatus === ValidationStatus.VALIDATED)
    return (
      <Card>
        <CardTitle>Attestation(s) de formation</CardTitle>
        <GreenCheck />
      </Card>
    );

  return (
    <>
      <AttestationsDialog
        isOpen={attestationsDialogOpen}
        close={() => setAttestationsDialogOpen(false)}
        registration={registration}
        onSubmitMutation={formationCertificatesMutation.mutate}
        isPending={formationCertificatesMutation.isPending}
      />
      <ConfirmDialog
        open={confirmAttestationDialogOpen}
        cancelStr="Annuler"
        validateStr="Confirmer"
        onResult={(accepted) => {
          if (accepted) {
            allAttestationsProvidedMutation.mutate({
              registrationId: registration.id,
              allAttestationsProvided: !registration.allAttestationsProvided,
            });
          }
          setConfirmAttestationDialogOpen(false);
        }}
        title="Confirmer cette action ?"
      />
      <Card>
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-1 items-center">
              <CardTitle>Attestation(s) de formation</CardTitle>
              {registration.formationCertificatesStatus ===
              ValidationStatus.REFUSED ? (
                <Badge variant="red" className="text-[10px]">
                  Nouvelle attestation requise
                </Badge>
              ) : registration.allAttestationsProvided ? (
                <Badge variant="orange">En attente de validation</Badge>
              ) : (
                <Badge variant="secondary" className="font-medium">
                  À déposer
                </Badge>
              )}
            </div>
            <div className="flex flex-row gap-2 items-center">
              <Checkbox
                checked={registration.allAttestationsProvided}
                id="checkbox"
                disabled={
                  registration.formationCertificatesStatus ===
                    ValidationStatus.REFUSED ||
                  registration.formationCertificates.length === 0
                }
                onClick={() => setConfirmAttestationDialogOpen(true)}
              />
              <Label htmlFor="checkbox">
                Tous mes justificatifs ont été déposés
              </Label>
            </div>
          </div>
          <Button
            disabled={
              registration.allAttestationsProvided &&
              registration.formationCertificatesStatus ===
                ValidationStatus.PENDING
            }
            onClick={() => setAttestationsDialogOpen(true)}
          >
            Mes attestations
          </Button>
        </div>
      </Card>
    </>
  );
};

export default AttestationCard;
