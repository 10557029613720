import { JobState } from "@/api/queues/dto/queues-jobs.dto";
import { useQueryQueuesJobs } from "@/api/queues/queues";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { SquareCode } from "lucide-react";
import { useEffect, useState } from "react";
import QueueJobCard from "./QueueJobCard";
import { Skeleton } from "@/components/atoms/Skeleton";
import LoadingError from "@/components/molecules/LoadingError";
import { ScrollArea } from "@/components/atoms/ScrollArea";

interface QueuesJobsDialogProps {
  expanded?: boolean;
}

const QueuesJobsDialog = ({ expanded = false }: QueuesJobsDialogProps) => {
  const [open, setOpen] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState(1000 * 60);

  const {
    data: jobs,
    isLoading,
    refetch,
  } = useQueryQueuesJobs({
    enabled: open,
    refetchInterval,
  });

  // refetch on open
  useEffect(() => {
    if (open) refetch();
  }, [open, refetch]);

  // reduced staleTime when a job is ongoing
  useEffect(() => {
    if (!jobs) return;
    if (jobs.some(({ state }) => state.state === JobState.Active))
      setRefetchInterval(1000 * 5);
    else setRefetchInterval(1000 * 60);
  }, [jobs]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="flex flex-row items-center gap-2.5 rounded-lg py-2 px-4 text-sm font-medium transition-colors duration-200">
        <SquareCode className="w-4 h-4" />
        {expanded && <span>Tâches</span>}
      </DialogTrigger>
      <DialogContent className="min-w-44 max-w-xl max-h-screen p-0">
        <DialogHeader>
          <DialogTitle className="p-4">Tâches</DialogTitle>
          <DialogDescription hidden>Tâches</DialogDescription>
        </DialogHeader>
        <ScrollArea className="pb-4">
          <div className="flex flex-col gap-4 min-h-20 max-h-[70vh] p-4">
            {!jobs ? (
              isLoading ? (
                <Skeleton className="w-full h-full" />
              ) : (
                <LoadingError className="h-full items-center">
                  Erreur de chargement
                </LoadingError>
              )
            ) : (
              jobs.map((job, i) => <QueueJobCard job={job} key={`job-${i}`} />)
            )}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default QueuesJobsDialog;
