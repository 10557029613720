import { AdminDtoClient } from "@/api/admin/dto/admin.dto";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/molecules/Table";
import { cn } from "@/lib/utils";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import ChangeAdminPasswordDialog from "./ChangeAdminPasswordDialog";
import DeleteAdminDialog from "./DeleteAdminDialog";

const columnHelper = createColumnHelper<AdminDtoClient>();

interface AdminsTableProps {
  admins: AdminDtoClient[];
}

const AdminsTable = ({ admins }: AdminsTableProps) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor("email", {
        id: "email",
        header: "email",
        cell: (info) => <span className="w-full">{info.getValue()}</span>,
      }),
      columnHelper.accessor((x) => x, {
        id: "delete",
        header: () => <></>,
        cell: (info) => (
          <div className="max-w-1 flex-shrink">
            <DeleteAdminDialog admin={info.getValue()} />
          </div>
        ),
      }),
      columnHelper.accessor((x) => x, {
        id: "changePassword",
        header: () => <></>,
        cell: (info) => (
          <div className="max-w-12 flex-shrink">
            <ChangeAdminPasswordDialog admin={info.getValue()} />
          </div>
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    data: admins,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 1,
      minSize: 1,
    },
  });

  return (
    <div className="rounded-lg overflow-auto border border-gray-200">
      <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
        <TableBody>
          {table.getRowModel().rows.map((row, i) => (
            <TableRow
              key={row.id}
              className={cn({
                "bg-gray-50": i % 2,
              })}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className="h-16 py-0">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AdminsTable;
