import { CandidateBasicDtoClient } from "@/api/candidate/dto/candidate.dto";
import ArchiveCandidateDialog from "@/components/molecules/ArchiveCandidateDialog";
import CandidateStatusBadge from "@/components/molecules/Badges/CandidateStatusBadge";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<CandidateBasicDtoClient>();

const columns = [
  columnHelper.accessor(
    ({ firstName, lastName }) => firstName + " " + lastName,
    {
      id: "name",
      header: "Candidat",
      cell: (info) => (
        <span className="text-gray-900 font-medium whitespace-nowrap">
          {info.getValue()}
        </span>
      ),
    }
  ),

  columnHelper.accessor("email", {
    id: "email",
    header: "Adresse email",
    cell: (info) => info.getValue(),
  }),

  columnHelper.accessor((row) => row, {
    id: "status",
    header: "Statut",
    cell: (info) => <CandidateStatusBadge basicInfo={info.getValue()} />,
  }),
  columnHelper.accessor((x) => x, {
    id: "archiveButton",
    header: "",
    cell: (info) => {
      const { id, archived, firstName, lastName } = info.getValue();
      return (
        <div className="flex justify-end">
          <ArchiveCandidateDialog
            archived={archived}
            candidateId={id}
            firstName={firstName}
            lastName={lastName}
          />
        </div>
      );
    },
  }),
];

export { columns };
