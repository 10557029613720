import { PaginationResponseDto } from "@/api/dto/pagination.dto";
import { CandidateConnectionDtoClient } from "@/api/live-exam/dto/candidate-connection.dto";
import { CandidateAnswersDtoClient } from "@/api/live-exam/dto/exam-answer.dto";
import { ExamLiveCandidateDtoClient } from "@/api/live-exam/dto/exam-live-candidate.dto";
import { socket } from "@/hooks/socket";
import React, { useEffect } from "react";

export const useCandidatesUpdate = (
  setData: React.Dispatch<
    React.SetStateAction<
      PaginationResponseDto<ExamLiveCandidateDtoClient> | undefined
    >
  >
) => {
  useEffect(() => {
    socket.on(
      "candidateConnection",
      (connections: CandidateConnectionDtoClient[]) => {
        setData((prev) =>
          prev?.data
            ? {
                ...prev,
                data: prev.data.map((registration) => {
                  const connection = connections.find(
                    ({ registrationId }) =>
                      registration.registration.id === registrationId
                  );

                  if (!connection) return registration;
                  else
                    return {
                      ...registration,
                      ...connection,
                    };
                }),
              }
            : undefined
        );
      }
    );

    socket.on("candidateAnswers", (answers: CandidateAnswersDtoClient[]) => {
      setData((prev) =>
        prev?.data
          ? {
              ...prev,
              data: prev.data.map((registration) => {
                const answer = answers.find(
                  ({ registrationId }) =>
                    registration.registration.id === registrationId
                );

                if (!answer) return registration;
                else
                  return {
                    ...registration,
                    ...answer,
                  };
              }),
            }
          : undefined
      );
    });

    return () => {
      socket.off("candidateConnection");
      socket.off("registrationsPartial");
    };
  }, [setData]);
};
