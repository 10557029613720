import { ExamLiveCandidateDtoClient } from "@/api/live-exam/dto/exam-live-candidate.dto";
import { Badge } from "@/components/atoms/Badge";
import PercentageProgressColor from "@/components/molecules/Progress/PercentageProgressColor";
import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import ActionsPopOver from "./ActionsPopOver/ActionsPopOver";
import ConnectionsSummaryDialog from "./ConnectionsSummaryDialog";

const columnHelper = createColumnHelper<ExamLiveCandidateDtoClient>();

export const columns = [
  columnHelper.accessor(
    ({ candidate: { firstName, lastName } }) => firstName + " " + lastName,
    {
      id: "name",
      header: "Candidat",
      cell: (info) => (
        <span className="text-gray-900 font-medium whitespace-nowrap">
          {info.getValue()}
        </span>
      ),
    }
  ),
  columnHelper.accessor(
    ({ connected, examConnection }) => ({ connected, examConnection }),
    {
      id: "status",
      header: "Statut",
      cell: (info) => {
        const { connected, examConnection } = info.getValue();

        return examConnection?.adminFinishTime ? (
          <Badge variant="purple">Examen clôturé</Badge>
        ) : examConnection?.userFinishTime ? (
          <Badge variant="white">Examen soumis</Badge>
        ) : connected ? (
          <Badge variant="green">Actif</Badge>
        ) : (
          <Badge variant="red">Absent</Badge>
        );
      },
    }
  ),
  columnHelper.accessor("registration.registrationNumber", {
    id: "registrationNumber",
    header: "N° insc.",
    cell: (info) => <span>{info.getValue()}</span>,
  }),
  columnHelper.accessor("registration.perimeter", {
    id: "perimeter",
    header: "Pér.",
    cell: (info) => <span>{info.getValue()}</span>,
  }),
  columnHelper.accessor("examConnection", {
    id: "connectionsCount",
    header: "Nb con.",
    cell: (info) => (
      <ConnectionsSummaryDialog examConnection={info.getValue()} />
    ),
  }),
  columnHelper.accessor("registration.additionalTimeMinutes", {
    id: "additionalTime",
    header: "Tiers tps",
    cell: (info) => {
      const minutes = info.getValue();
      const hours = Math.floor(minutes / 60);
      const minutesText = minutes % 60;

      if (!minutes) return <></>;

      return (
        <Badge variant="white">
          {hours.toString().padStart(2, "0")}:
          {minutesText.toString().padStart(2, "0")}
        </Badge>
      );
    },
  }),
  columnHelper.accessor("examConnection", {
    id: "firstConnection",
    header: "Démarrage",
    cell: (info) => {
      const x = info.getValue()?.connectedAt?.[0];
      return (
        <span>{x ? format(new Date(x), "HH:mm", { locale: fr }) : "-"}</span>
      );
    },
  }),
  columnHelper.accessor("examConnection", {
    id: "finishTime",
    header: "Horaire fin",
    cell: (info) => {
      const examConnection = info.getValue();
      const x =
        examConnection?.userFinishTime ??
        examConnection?.adminFinishTime ??
        examConnection?.plannedFinishTime;

      return (
        <span>{x ? format(new Date(x), "HH:mm", { locale: fr }) : "-"}</span>
      );
    },
  }),
  columnHelper.accessor(
    ({ answersCount, questionsCount }) => `${answersCount}/${questionsCount}`,
    {
      id: "answersCount",
      header: "Réponses",
      cell: (info) => <span>{info.getValue()}</span>,
    }
  ),
  columnHelper.accessor(
    ({ answersCount, questionsCount }) => (answersCount / questionsCount) * 100,
    {
      id: "answersPercentage",
      header: "% d'avancement",
      cell: (info) => <PercentageProgressColor percentage={info.getValue()} />,
    }
  ),
  columnHelper.accessor("examConnection", {
    id: "submitted",
    header: "Soumis",
    cell: (info) =>
      info.getValue()?.userFinishTime ? (
        <Badge variant="green">Oui</Badge>
      ) : (
        <Badge variant="red">Non</Badge>
      ),
  }),
  columnHelper.accessor((x) => x, {
    id: "actions",
    header: "",
    cell: (info) => <ActionsPopOver candidate={info.getValue()} />,
    maxSize: 1,
  }),
];
