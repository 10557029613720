import { ExamConnectionDtoClient } from "@/api/dto/exam-connection.dto";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { cn } from "@/lib/utils";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useMemo } from "react";

// [connection, disconnection]
// Since some disconnections can be missed by the server,
// the associated connections are alone.
type ConnectionPair = {
  connection: Date;
  disconnection?: Date;
};

const columnHelper = createColumnHelper<ConnectionPair>();

interface ConnectionsSummaryDialogProps {
  examConnection?: ExamConnectionDtoClient;
}

const ConnectionsSummaryDialog = ({
  examConnection,
}: ConnectionsSummaryDialogProps) => {
  const pairs = useMemo(() => {
    if (!examConnection) return [];
    const { connectedAt, disconnectedAt } = examConnection;

    const events = [
      ...connectedAt.map((date) => ({ date: new Date(date), connect: true })),
      ...disconnectedAt.map((date) => ({
        date: new Date(date),
        connect: false,
      })),
    ];

    events.sort((a, b) => a.date.getTime() - b.date.getTime());

    const pairs: ConnectionPair[] = [];

    events.forEach(({ date: connection, connect }, i) => {
      if (!connect) return;
      const next = events[i + 1];
      // missing disconnection
      if (!next || next.connect) pairs.push({ connection });
      else pairs.push({ connection: connection, disconnection: next.date });
    });

    return pairs;
  }, [examConnection]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((x) => x, {
        id: "index",
        header: "N°",
        cell: ({ row }) => row.index + 1,
      }),
      columnHelper.accessor("connection", {
        id: "connection",
        header: "Connexion",
        cell: (info) => (
          <span>{format(info.getValue(), "HH:mm:ss", { locale: fr })}</span>
        ),
      }),
      columnHelper.accessor("disconnection", {
        id: "disconnection",
        header: "Déconnexion",
        cell: (info) => {
          const date = info.getValue();
          return (
            <span>{date ? format(date, "HH:mm:ss", { locale: fr }) : "-"}</span>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: pairs,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Dialog>
      <DialogTrigger onClick={(e) => e.stopPropagation()} asChild>
        <Button
          variant="white"
          className="min-w-11"
          onClick={(e) => e.stopPropagation()}
        >
          {examConnection?.connectedAt.length ?? "0"}
        </Button>
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="max-h-[95%] overflow-auto"
      >
        <DialogHeader>
          <DialogTitle>Nombre de connexions/déconnexions</DialogTitle>
          <DialogDescription hidden>
            Nombre de connexions/déconnexions
          </DialogDescription>
        </DialogHeader>
        <Table className="text-gray-600 font-medium text-sm leading-6">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                  className="whitespace-nowrap"
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-14 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectionsSummaryDialog;
