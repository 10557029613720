import { countries } from "@/constants/countries";
import useChildForm from "@/hooks/ChildForm";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { Input } from "@atoms/Input";
import { ComboboxField } from "@/components/molecules/Combobox/Combobox";
import { AddressFields } from "./AddressFields";

type AddressFormProps = {
  fieldName?: string;
  title?: string;
  isMandatory?: boolean;
  hiddenFields?: AddressFields[];
  disabledFields?: AddressFields[];
};

const AddressForm = ({
  fieldName,
  isMandatory = false,
  title = "Adresse",
  hiddenFields = [],
  disabledFields = [],
}: AddressFormProps) => {
  const { transformPath, control } = useChildForm(fieldName);
  const transformFieldName = (fieldName: string) =>
    isMandatory ? fieldName + " *" : fieldName;

  const rowClasses = "flex flex-row gap-2 w-full";

  return (
    <div className="flex flex-col w-full">
      <h2>{title}</h2>
      <div className="flex flex-col gap-1">
        {!hiddenFields.includes(AddressFields.Country) && (
          <div className={rowClasses}>
            <FormField
              control={control}
              name={transformPath("country")}
              render={() => (
                <FormItem className="w-full">
                  <FormLabel htmlFor="country">
                    {transformFieldName("Pays")}
                  </FormLabel>
                  <FormControl>
                    <ComboboxField
                      options={countries.map((country) => ({
                        value: country.libelle,
                        label: country.libelle,
                      }))}
                      placeholder={"-"}
                      optional={true}
                      id="country"
                      disabled={disabledFields.includes(AddressFields.Country)}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        )}
        {!hiddenFields.includes(AddressFields.PersonalAddress) && (
          <div className={rowClasses}>
            <FormField
              control={control}
              name={transformPath("personalAddress")}
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>{transformFieldName("Adresse")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"-"}
                      {...field}
                      disabled={disabledFields.includes(
                        AddressFields.PersonalAddress
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}
        {!hiddenFields.includes(AddressFields.Complement) && (
          <div className={rowClasses}>
            <FormField
              control={control}
              name={transformPath("complement")}
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Complément</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"-"}
                      {...field}
                      disabled={disabledFields.includes(
                        AddressFields.Complement
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}
        <div className={rowClasses}>
          {!hiddenFields.includes(AddressFields.PostalCode) && (
            <FormField
              control={control}
              name={transformPath("postalCode")}
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>{transformFieldName("Code postal")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"-"}
                      {...field}
                      disabled={disabledFields.includes(
                        AddressFields.PostalCode
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(AddressFields.City) && (
            <FormField
              control={control}
              name={transformPath("city")}
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>{transformFieldName("Ville")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"-"}
                      {...field}
                      disabled={disabledFields.includes(AddressFields.City)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
