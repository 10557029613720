import { useEffect } from "react";

const ZendeskChat = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${
      import.meta.env.VITE_ZENDESK_KEY
    }`;
    script.async = true;
    script.id = "ze-snippet";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default ZendeskChat;
