import { useMutationExamRemovePreRegistered } from "@/api/exam/exam";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import { Input } from "@/components/atoms/Input";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@atoms/Dialog";
import { useQueryClient } from "@tanstack/react-query";
import { User2 } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import { useDebounce } from "use-debounce";
import { useSessionContext } from "../../session-context";
import PreRegisteredTable from "./PreRegisteredTable";

const RemovePreRegisteredDialog = () => {
  const [selected, setSelected] = useState<Set<number>>(new Set());
  const [notSelected, setNotSelected] = useState<Set<number>>(new Set());
  const [allSelected, setAllSelected] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const { exam } = useSessionContext();
  const [open, setOpen] = useState(false);

  const { isPending, mutate } = useMutationExamRemovePreRegistered();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="gap-1.5 rounded-lg">
          <User2 />
          Retirer les pré-inscrits
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col gap-6 p-4 max-w-3xl">
        <DialogHeader>
          <DialogTitle>Retirer les pré-inscrits de la session</DialogTitle>
        </DialogHeader>
        <DialogDescription hidden={true}>
          Retirer les pré-inscrits de la session
        </DialogDescription>
        <ConfirmDialog
          open={confirmDialogOpen}
          onOpenChange={setConfirmDialogOpen}
          onResult={(confirmed) => {
            setConfirmDialogOpen(false);

            if (!confirmed) return;

            mutate(
              {
                allSelected,
                selected: Array.from(selected),
                notSelected: Array.from(notSelected),
                examId: exam.id,
              },
              {
                onSuccess: () => {
                  toast.success("Suppression(s) effectuée(s)");
                  queryClient.invalidateQueries({
                    queryKey: keyFactory.exam.base(),
                  });
                  queryClient.invalidateQueries({
                    queryKey: keyFactory.candidate.base(),
                  });
                },
                onError: () => {
                  toast.error("Erreur", {
                    description:
                      "La suppression n'a pas pu être prise en compte",
                  });
                },
              }
            );
          }}
          title="Suppression"
          message="Supprimer les pré-inscrits sélectionnés de la session ?"
          cancelButtonVariant="ghostWithBorders"
          validateButtonVariant="red"
          validateStr="Supprimer"
        />
        <Input
          className="shadow-none"
          id="shadcn-input"
          searchIcon
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value as string)}
          placeholder="Rechercher"
        />
        <PreRegisteredTable
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          selected={selected}
          setSelected={setSelected}
          notSelected={notSelected}
          setNotSelected={setNotSelected}
          searchText={debouncedSearchText}
        />
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => setOpen(false)}
            variant="ghostWithBorders"
            className="w-full"
            disabled={isPending}
          >
            Annuler
          </Button>
          <Button
            disabled={isPending || !(allSelected || selected.size > 0)}
            onClick={() => setConfirmDialogOpen(true)}
            className="w-full"
          >
            {isPending ? "Suppression..." : "Supprimer"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RemovePreRegisteredDialog;
