import { useQueryCandidateOnGoingRegistration } from "@/api/candidate/candidate";
import { Badge } from "@/components/atoms/Badge";
import { Skeleton } from "@/components/atoms/Skeleton";
import RegistrationTypeBadge from "@/components/molecules/Badges/RegistrationTypeBadge";
import LoadingError from "@/components/molecules/LoadingError";
import GrayCard from "@/components/molecules/RegistrationCard/GrayCard";
import { formatDate } from "@/lib/utils";
import { Calendar, User } from "lucide-react";
import { useOutletContext } from "react-router-dom";
import { CandidateContext } from "../candidate-context";
import ActionsButton from "./ActionsButton/ActionsButton";
import DelegationCard from "./Cards/DelegationCard";
import PayementCard from "./Cards/PayementCard/PayementCard";
import PreTestCard from "./Cards/PreTestCard/PreTestCard";
import ReducedPriceCard from "./Cards/ReducedPriceCard/ReducedPriceCard";
import FormationAttestations from "./FormationAttestations/FormationAttestations";
import RegistrationStatusBadge from "./RegisteringStatusBadge";

const OnGoingSession = () => {
  const { candidate } = useOutletContext<CandidateContext>();
  const { data, isLoading } = useQueryCandidateOnGoingRegistration(
    candidate.id
  );

  if (!data) {
    return (
      <div className="px-6 py-4 h-full">
        {isLoading ? (
          <Skeleton className="w-full h-full" />
        ) : (
          <LoadingError>Erreur de chargement des certificats</LoadingError>
        )}
      </div>
    );
  }

  if (data === "no-registration")
    return (
      <span className="m-5">Aucune inscription à une session en cours</span>
    );

  return (
    <div className="flex w-full p-4">
      <div className="flex flex-col gap-4 p-4 bg-white border border-gray-200 rounded-lg w-full">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row w-full gap-6">
            <GrayCard>Session : {data.exam.number}</GrayCard>
            <GrayCard>
              <User className="w-4 h-4 text-gray-500" />
              N° inscription : {data.registrationNumber ?? "Non généré"}
              <RegistrationTypeBadge registrationType={data.type} />
            </GrayCard>
            <GrayCard>
              <Calendar className="w-4 h-4 text-gray-500" />
              Date de l'examen : {formatDate(data.exam.date)}
            </GrayCard>
          </div>
          <ActionsButton registration={data} candidateId={candidate.id} />
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <h2>Inscription</h2>
              <RegistrationStatusBadge registration={data} />
              <div className="flex flex-row gap-2 ml-2">
                <span>Clôture inscrip.</span>
                <Badge variant="white">
                  {formatDate(data.exam.registeringEndDate)}
                </Badge>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
              <DelegationCard registration={data} />
              <PayementCard registration={data} candidateId={candidate.id} />
              <ReducedPriceCard
                registration={data}
                candidateId={candidate.id}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h2>Test préalable</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
              <PreTestCard registration={data} candidateId={candidate.id} />
            </div>
          </div>
          <FormationAttestations
            registration={data}
            candidateId={candidate.id}
          />
        </div>
      </div>
    </div>
  );
};

export default OnGoingSession;
