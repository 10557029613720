import { replaceNullsWith } from "@/api/app";
import { useMutationAuthChangeEmail } from "@/api/auth/auth";
import {
  useMutationCandidateChangeInfo,
  useQueryCandidateInfo,
} from "@/api/candidate/candidate";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import { Form } from "@/components/atoms/Form";
import { Skeleton } from "@/components/atoms/Skeleton";
import { AddressFields } from "@/components/molecules/AddressForm/AddressFields";
import AddressForm from "@/components/molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  optionalAddressSchema,
} from "@/components/molecules/AddressForm/AddressFormSchema";
import DisconnectPrompt from "@/components/molecules/DisconnectPrompt";
import ErrorDisplay from "@/components/molecules/ErrorDisplay";
import LoadingError from "@/components/molecules/LoadingError";
import ModifiableForm from "@/components/molecules/ModifiableForm";
import { PersonalInfoField } from "@/components/molecules/PersonalInfoForm/PersonalInfoFields";
import PersonalInformationForm from "@/components/molecules/PersonalInfoForm/PersonalInfoForm";
import {
  emailDefaultValues,
  emailSchema,
  personalInfoDefaultValues,
  personalInfoSchema,
} from "@/components/molecules/PersonalInfoForm/PersonalInfoFormSchema";
import { ROUTE } from "@/constants/routes";
import { useAuthStore } from "@/hooks/AuthStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { HttpStatusCode } from "axios";
import _ from "lodash";
import { Edit } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const accountModificationSchema = z.object({
  address: optionalAddressSchema,
  personalInfo: personalInfoSchema,
  credentials: emailSchema,
});

const PersonalInformation = () => {
  const queryClient = useQueryClient();
  const [errorMsg, setErrorMsg] = useState("");
  const [PIIsModifiable, setPIIsModifiable] = useState(false);
  const [addressIsModifiable, setAddressIsModifiable] = useState(false);
  const { session, setNewEmail } = useAuthStore();
  const [disconnectsOpen, setDisconnectsOpen] = useState(false);

  /////////////
  // api calls
  /////////////

  const { data: candidate, isLoading } = useQueryCandidateInfo(undefined, {
    staleTime: Infinity,
  });

  const changeCandidateMutation = useMutationCandidateChangeInfo(undefined, {
    onSuccess: async () => {
      setErrorMsg("");

      toast.success("Informations modifiées avec succès");

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.info(),
      });

      setAddressIsModifiable(false);
      setPIIsModifiable(false);
    },
    onError: (error) => {
      switch (error.status) {
        case HttpStatusCode.InternalServerError:
          setErrorMsg(
            "Le serveur a rencontré une erreur lors de la modification de vos informations."
          );
          return;

        default:
          setErrorMsg(
            "Une erreur est survenue lors de la modification de vos informations."
          );
          return;
      }
    },
  });

  const changeUserEmailMutation = useMutationAuthChangeEmail({
    onSuccess: () => {
      const { credentials, ...pi } = watch();
      changeCandidateMutation.mutate(pi);
    },
  });

  /////////////
  // form
  /////////////

  const form = useForm<z.infer<typeof accountModificationSchema>>({
    resolver: zodResolver(accountModificationSchema),
    defaultValues: {
      address: addressDefaultValues,
      personalInfo: personalInfoDefaultValues,
      credentials: emailDefaultValues,
    },
  });

  const { reset, watch } = form;

  const resetForm = useCallback(() => {
    const newState = watch();

    if (candidate) {
      newState.address = _.merge(
        addressDefaultValues,
        replaceNullsWith(candidate.address, "")
      );
      newState.personalInfo = _.merge(
        personalInfoDefaultValues,
        replaceNullsWith(candidate.personalInfo, "")
      );
    }

    if (session) {
      newState.credentials = {
        email: session?.email as string,
        emailConfirmation: session?.email as string,
      };
    }

    reset(newState);
  }, [candidate, session, reset, watch]);

  useEffect(resetForm, [resetForm]);

  const onSubmit = (data: z.infer<typeof accountModificationSchema>) => {
    const {
      credentials: { email: newEmail },
      ...pi
    } = data;

    if (newEmail !== session?.email) {
      changeUserEmailMutation.mutate({ dto: { newEmail } });
      setNewEmail(newEmail);
    } else {
      changeCandidateMutation.mutate(pi);
    }
  };

  if (!candidate) {
    if (isLoading)
      return <Skeleton className="w-full h-full bg-gray-300 min-h-96" />;
    else
      return (
        <LoadingError>Erreur de chargement de vos informations</LoadingError>
      );
  }

  return (
    <>
      <DisconnectPrompt
        isOpen={disconnectsOpen}
        close={() => setDisconnectsOpen(false)}
        redirectionPage={ROUTE.forgotten.password.ask()}
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-8"
        >
          <ModifiableForm
            setModifiable={(b: boolean) => {
              setAddressIsModifiable(false);
              resetForm();
              setPIIsModifiable(b);
            }}
            isModifiable={PIIsModifiable}
            onCancel={resetForm}
            modifyText="Modifier mes informations"
            saveText="Sauvegarder mes informations personnelles"
          >
            <PersonalInformationForm
              hiddenFields={[
                PersonalInfoField.Password,
                PersonalInfoField.PasswordConfirmation,
              ]}
              disabledFields={
                PIIsModifiable
                  ? [PersonalInfoField.FirstName, PersonalInfoField.LastName]
                  : Object.values(PersonalInfoField)
              }
              unmodifiableToolTipFields={
                PIIsModifiable
                  ? [PersonalInfoField.FirstName, PersonalInfoField.LastName]
                  : []
              }
            />
            <Button
              type="button"
              variant="outlineBlue"
              onClick={() => setDisconnectsOpen(true)}
            >
              <Edit className="w-4 h-4" />
              Modifier mon mot de passe
            </Button>
          </ModifiableForm>
          <ModifiableForm
            setModifiable={(b: boolean) => {
              setPIIsModifiable(false);
              resetForm();
              setAddressIsModifiable(b);
            }}
            isModifiable={addressIsModifiable}
            onCancel={resetForm}
            modifyText="Modifier mon adresse"
            saveText="Sauvegarder mon adresse"
          >
            <AddressForm
              fieldName="address"
              disabledFields={
                addressIsModifiable ? [] : Object.values(AddressFields)
              }
            />
          </ModifiableForm>
          {errorMsg && <ErrorDisplay msg={errorMsg} />}
        </form>
      </Form>
    </>
  );
};

export default PersonalInformation;
