import { cn } from "@/lib/utils";
import { CircleX } from "lucide-react";

interface LoadingErrorProps {
  children?: React.ReactNode;
  className?: string;
}

const LoadingError = ({ children, className }: LoadingErrorProps) => {
  return (
    <div className={cn("flex flex-row gap-2 items-center", className)}>
      <CircleX className="w-6 h-6 text-red-700" />
      {children}
    </div>
  );
};

export default LoadingError;
