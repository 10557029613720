import { Role } from "@/api/enums/Role";
import { Skeleton } from "@/components/atoms/Skeleton";
import { socket } from "@/hooks/socket";
import { useWebSocketData } from "@/hooks/websocket-data";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Live = () => {
  const { connected } = useWebSocketData();

  useEffect(() => {
    socket.io.opts.query = { role: Role.ADMIN };
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  return connected ? (
    <Outlet />
  ) : (
    <div className="relative h-full w-full p-8 bg-white">
      <Skeleton className="w-full h-full" />
      <span className="italic absolute top-1/2 right-1/2 text-gray-500">
        Connexion au serveur live...
      </span>
    </div>
  );
};

export default Live;
