import { ExamType } from "@/api/enums/ExamType";
import { Badge } from "@/components/atoms/Badge";
import { Input } from "@/components/atoms/Input";
import { formatDate } from "@/lib/utils";

interface CandidateDisplayProps {
  candidateFirstName: string;
  candidateLastName: string;
  examType: ExamType;
  registrationEndDate: Date;
  examDate: Date;
}

const CandidateDisplay = ({
  candidateFirstName,
  candidateLastName,
  examType,
  examDate,
  registrationEndDate,
}: CandidateDisplayProps) => {
  return (
    <>
      {examType === ExamType.INITIAL ? (
        <Badge variant="purple">Session initiale</Badge>
      ) : examType === ExamType.RENEW ? (
        <Badge variant="sky"> Session de renouvellement</Badge>
      ) : (
        <></>
      )}
      <div className="flex flex-col gap-1 text-gray-700">
        Candidat
        <Input
          disabled={true}
          placeholder={candidateLastName + " " + candidateFirstName}
        />
      </div>
      <div className="flex flex-row gap-2">
        <div className="flex flex-col gap-1 text-gray-700">
          Clôture des inscriptions
          <Input
            disabled={true}
            placeholder={formatDate(registrationEndDate)}
          />
        </div>
        <div className="flex flex-col gap-1 text-gray-700">
          Date examen
          <Input disabled={true} placeholder={formatDate(examDate)} />
        </div>
      </div>
    </>
  );
};

export default CandidateDisplay;
