import { useMutationAdminChangePassword } from "@/api/admin/admin";
import { AdminDtoClient } from "@/api/admin/dto/admin.dto";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { password } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { Pencil } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  password: password(),
});

type Schema = z.infer<typeof schema>;

interface ChangeAdminPasswordDialog {
  admin: AdminDtoClient;
}

const ChangeAdminPasswordDialog = ({
  admin: { email, id },
}: ChangeAdminPasswordDialog) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const changePasswordMutation = useMutationAdminChangePassword({
    onSuccess: () => {
      toast.success(`Mot de passe de ${email} créé`);

      queryClient.invalidateQueries({
        queryKey: keyFactory.admin.getAll(),
      });

      setOpen(false);
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.message ||
        "Le mot de pass n'a pas pu être modifié";
      toast.error("Échec de la requête", {
        description: errorMessage,
      });
    },
  });

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      password: "",
    },
  });

  const onSubmit = (body: Schema) =>
    changePasswordMutation.mutate({
      id,
      body,
    });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="flex w-fit gap-2" variant="ghostWithBorders">
          <Pencil className="w-4 h-4" />
          Changer le mot de passe
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-xl flex flex-col gap-4 overflow-auto max-h-[85%]">
        <DialogHeader>
          <DialogTitle>Changer le mot de passe de {email}</DialogTitle>
          <DialogDescription hidden={true}>
            Changer le mot de passe de {email}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input {...field} type="password" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" disabled={changePasswordMutation.isPending}>
              {changePasswordMutation.isPending ? "Création..." : "Valider"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeAdminPasswordDialog;
