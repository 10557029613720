import { useQueryCompanyInfo } from "@/api/company/company";
import { Role } from "@/api/enums/Role";
import { Button } from "@/components/atoms/Button";
import { Input } from "@/components/atoms/Input";
import { Skeleton } from "@/components/atoms/Skeleton";
import CompanyModifyInfoForm from "@/components/molecules/CompanyModifyInfoForm/CompanyModifyInfoForm";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import LoadingError from "@/components/molecules/LoadingError";
import { ROUTE } from "@/constants/routes";
import { ArrowLeft, Trash2 } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Company = () => {
  const { id: idStr } = useParams();
  const id = Number(idStr);
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { isLoading, data: company } = useQueryCompanyInfo<Role.ADMIN>(id);

  if (!idStr) navigate(ROUTE.admin.dashboard.companies());

  return (
    <>
      <ConfirmDialog
        open={deleteDialogOpen}
        cancelStr="Annuler"
        title="Supprimer le compte entreprise"
        validateStr="Supprimer"
        validateButtonVariant="red"
        cancelButtonVariant="ghostWithBorders"
        onResult={(accepted) => {
          if (accepted) {
            // TODO: delete company
            setDeleteDialogOpen(false);
          } else setDeleteDialogOpen(false);
        }}
      />
      <div className="flex flex-col w-full bg-white h-screen">
        <div className="flex flex-row justify-between w-full items-center border-b border-b-gray-200 px-6 py-3 gap-2">
          <div className="flex flex-row gap-4 items-center w-full h-full">
            <Button
              variant="ghostWithBorders"
              className="p-2 rounded-lg"
              onClick={() => navigate(ROUTE.admin.dashboard.companies())}
            >
              <ArrowLeft className="w-5 h-5 text-gray-500" />
            </Button>
            {company ? (
              <div className="flex flex-col justify-center">
                <h1>{company.name}</h1>
                {company.address && (
                  <span className="text-gray-600 text-xs">
                    {`${company.address.personalAddress} ${company.address.postalCode}, ${company.address.city}, ${company.address.country}`}
                  </span>
                )}
              </div>
            ) : isLoading ? (
              <Skeleton className="w-full h-full" />
            ) : (
              <LoadingError>Erreur de chargement de l'entreprise</LoadingError>
            )}
          </div>
          <div className="flex flex-row gap-2 items-center">
            <Button
              variant="ghostWithBorders"
              className="p-2 rounded-lg"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <Trash2 className="w-5 h-5 text-gray-500" />
            </Button>
            <Button
              variant="ghostWithBorders"
              className="text-gray-800 font-semibold"
              disabled={true}
            >
              Modifier
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-1.5 px-14 pb-0 pt-6">
          <span>Délégations en attente</span>
          <Input
            value={company?.waitingDelegations ?? "chargement..."}
            disabled={true}
          />
        </div>
        <CompanyModifyInfoForm companyId={id} />
      </div>
    </>
  );
};

export default Company;
