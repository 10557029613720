import { DocumentType } from "@/api/dto/document-type.enum";
import { Badge } from "@/components/atoms/Badge";

interface DocumentTypeBadgeProps {
  type: DocumentType;
}

const DocumentTypeBadge = ({ type }: DocumentTypeBadgeProps) => {
  switch (type) {
    case DocumentType.DEBIT_COMPANY_PAID:
    case DocumentType.DEBIT_PERSONAL_PAID:
      return <Badge variant="purple">Note de débit réglée</Badge>;
    case DocumentType.DEBIT_COMPANY_UNPAID:
    case DocumentType.DEBIT_PERSONAL_UNPAID:
      return <Badge variant="white">Note de débit</Badge>;
    case DocumentType.ATTENDANCE_ATTESTATION:
      return <Badge variant="red">Attestation de présence</Badge>;
    case DocumentType.RESULTS:
      return <Badge variant="sky">Relevé de notes</Badge>;
    case DocumentType.CONVOCATION:
      return <Badge variant="green">Convocation</Badge>;
    case DocumentType.CERTIFICATE:
      return <Badge variant="orange">Certificat</Badge>;
  }

  return false;
};

export default DocumentTypeBadge;
