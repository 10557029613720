import { useMutationAdminAdd } from "@/api/admin/admin";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { email, password } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { Plus } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  email: email(),
  key: password(),
});

type Schema = z.infer<typeof schema>;

const AddAdminDialog = () => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const addMutation = useMutationAdminAdd({
    onSuccess: (_, { email }) => {
      toast.success(`Administrateur ${email} créé`);

      queryClient.invalidateQueries({
        queryKey: keyFactory.admin.getAll(),
      });

      setOpen(false);
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.message ||
        "L'administrateur n'a pas pu être créé";
      toast.error("Échec de la requête", {
        description: errorMessage,
      });
    },
  });

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      key: "",
    },
  });

  const onSubmit = (data: Schema) => addMutation.mutate(data);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="flex w-fit">
          <Plus />
          Ajouter
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-xl flex flex-col gap-4 overflow-auto max-h-[85%]">
        <DialogHeader>
          <DialogTitle>Ajout d'un compte administrateur</DialogTitle>
          <DialogDescription hidden={true}>
            Ajout d'un institut de formation
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input {...field} type="email" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="key"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input {...field} type="password" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" disabled={addMutation.isPending}>
              {addMutation.isPending ? "Création..." : "Valider"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddAdminDialog;
