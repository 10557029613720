import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { ExamResultStatus } from "@/api/enums/exam-result-status.enum";
import GreenCheck from "@/components/atoms/GreenCheck";
import RedCross from "@/components/atoms/RedCross";
import { Search } from "lucide-react";

interface ResultsCardsProps {
  registration: CandidateRegistrationDtoClient;
}

const ResultsCards = ({
  registration: { examResult, edcResult },
}: ResultsCardsProps) => {
  if (!examResult && !edcResult)
    return (
      <div className="bg-gray-100 flex flex-col gap-4 items-center justify-center py-8 rounded-lg">
        <Search className="w-6 h-6 text-gray-400" />
        <span>Pas encore disponible</span>
      </div>
    );

  return (
    <div className="flex flex-row gap-4">
      {examResult &&
        ![ExamResultStatus.UNAVAILABLE, ExamResultStatus.MISSING].includes(
          examResult.status
        ) && (
          <div className="flex flex-col p-4 gap-4 border border-gray-200 rounded-lg max-w-80 w-full h-fit">
            <div className="flex flex-row items-center gap-4">
              <span className="text-black font-bold">QCM</span>
              {examResult.status === ExamResultStatus.PASSED ? (
                <GreenCheck />
              ) : (
                <RedCross />
              )}
            </div>
            <div className="flex flex-row justify-between">
              <span>Note</span>
              <span className="font-bold">
                {((examResult.grade * 20) / examResult.gradeTotal).toFixed(1)}
                /20
              </span>
            </div>
          </div>
        )}

      {edcResult &&
        ![ExamResultStatus.UNAVAILABLE, ExamResultStatus.MISSING].includes(
          edcResult.status
        ) && (
          <div className="flex flex-col p-4 gap-4 border border-gray-200 rounded-lg max-w-80 w-full h-fit">
            <div className="flex flex-row items-center gap-4">
              <span className="text-black font-bold">Étude de cas</span>
              {edcResult.status === ExamResultStatus.PASSED ? (
                <GreenCheck />
              ) : (
                <RedCross />
              )}
            </div>
            {edcResult.notes.length > 1 &&
              edcResult.notes.map((_, i) => (
                <div
                  className="flex flex-row justify-between"
                  key={`edcResults-${i}`}
                >
                  <span>{edcResult.descriptions[i]}</span>
                  <span className="font-bold">
                    {edcResult.notes[i]}/{edcResult.notesTotal[i]}
                  </span>
                </div>
              ))}
            <div className="flex flex-row justify-between">
              <span>Note finale EDC</span>
              <span className="font-bold">
                {edcResult.notes.reduce((acc, g) => acc + g, 0)}/
                {edcResult.notesTotal.reduce((acc, g) => acc + g, 0)}
              </span>
            </div>
          </div>
        )}
    </div>
  );
};

export default ResultsCards;
