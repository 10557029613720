import { clsx, type ClassValue } from "clsx";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export class DateHelper {
  static day = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export const formatDate = (date: Date = new Date()) =>
  format(new Date(date), "dd/MM/yyyy", { locale: fr });
