import { useQueryExportMinistry } from "@/api/export/export";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { downloadFile } from "@/lib/download-file";
import { formatDate } from "@/lib/utils";
import { HttpStatusCode } from "axios";
import { Download } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";

const ExportMinistryDialog = () => {
  const [open, setOpen] = useState(false);

  const { isLoading, isError, error, refetch, data } = useQueryExportMinistry();

  // display error
  useEffect(() => {
    if (!isError) return;

    switch (error.response?.status) {
      case HttpStatusCode.NotImplemented:
        toast.error("Export non implémenté pour l'instant.");
        return;

      default:
        toast.error("Échec de l'export");
    }
  }, [isError, error?.response?.status]);

  // download file
  useEffect(() => {
    if (!data) return;

    downloadFile(data, `données-ministère-${formatDate()}.xlsx`);
  }, [data]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Download className="w-5 h-5" />
      </DialogTrigger>
      <DialogContent className="p-4">
        <DialogHeader>
          <DialogTitle>
            Données réglementaires à transmettre au Ministère
          </DialogTitle>
          <DialogDescription hidden>
            Données réglementaires à transmettre au Ministère
          </DialogDescription>
        </DialogHeader>
        <Button disabled={isLoading} onClick={() => refetch()}>
          {isLoading ? "Export en cours..." : "Exporter"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ExportMinistryDialog;
