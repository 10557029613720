import {
  useConstantQuery,
  useConstantSetMutation,
} from "@/api/constant/constant";
import { ConstantKey } from "@/api/constant/constants";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { Skeleton } from "@/components/atoms/Skeleton";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import usePersistent from "@/hooks/use-persistent";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  full: z.coerce.number(),
  reduced: z.coerce.number(),
});

type Schema = z.infer<typeof schema>;

const Prices = () => {
  const [modifiable, setModifiable] = useState(false);
  const [currentTab, setCurrentTab] = usePersistent<"initial" | "renew">(
    "admin-administration-prices-tab",
    "initial"
  );
  const queryClient = useQueryClient();

  const pricesQuery = useConstantQuery(ConstantKey.Prices);
  const changeMutation = useConstantSetMutation();

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      full: 0,
      reduced: 0,
    },
  });

  useEffect(() => {
    if (!pricesQuery.data) return;

    form.reset({
      full:
        currentTab === "renew"
          ? pricesQuery.data.renewing.full
          : pricesQuery.data.initial.full,
      reduced:
        currentTab === "renew"
          ? pricesQuery.data.renewing.reduced
          : pricesQuery.data.initial.reduced,
    });
  }, [pricesQuery.data, form, modifiable, currentTab]);

  const onSubmit = (data: Schema) => {
    if (!pricesQuery.data) return;
    changeMutation.mutate(
      {
        key: ConstantKey.Prices,
        value: {
          initial: currentTab === "initial" ? data : pricesQuery.data.initial,
          renewing: currentTab === "renew" ? data : pricesQuery.data.renewing,
        },
      },
      {
        onError: () => {
          toast.error("Échec de la requête", {
            description: "Les tarifs n'ont pas pu être modifiés",
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: keyFactory.constant.byKey(ConstantKey.Prices),
          });
          toast.success("Tarifs modifiés");
          setModifiable(false);
        },
      }
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-center">
        <h2>Tarifs</h2>
        <Button
          onClick={() => setModifiable(!modifiable)}
          className="flex w-fit"
          variant="ghostWithBorders"
          disabled={!pricesQuery.data}
        >
          {!modifiable ? "Modifier" : "Annuler"}
        </Button>
      </div>
      <Tabs
        onValueChange={(e) => setCurrentTab(e as "initial" | "renew")}
        value={currentTab}
        className="border-b border-b-gray-200"
      >
        <TabsList variant="transparent" className="pb-0 mb-0">
          <TabsTrigger variant="underlineBlue" value={"initial"}>
            Initial
          </TabsTrigger>
          <TabsTrigger variant="underlineBlue" value={"renew"}>
            Renouvellement
          </TabsTrigger>
        </TabsList>
      </Tabs>
      {!pricesQuery.data ? (
        <Skeleton className="w-full h-full max-h-80 " />
      ) : (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4 max-w-80"
          >
            {(
              [
                {
                  name: "full",
                  label: "Tarif normal",
                },
                {
                  name: "reduced",
                  label: "Tarif réduit",
                },
              ] as const
            ).map(({ name, label }) => (
              <FormField
                key={name}
                control={form.control}
                disabled={!modifiable}
                name={name}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{label}</FormLabel>
                    <FormControl>
                      <div className="flex items-center">
                        <Input {...field} type="number" className="flex-grow" />
                        <span className="ml-2">€</span>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}
            {modifiable && (
              <Button type="submit" disabled={changeMutation.isPending}>
                {changeMutation.isPending
                  ? "Modification..."
                  : "Sauvegarder les modifications"}
              </Button>
            )}
          </form>
        </Form>
      )}
    </div>
  );
};

export default Prices;
