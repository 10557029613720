import { Input } from "@/components/atoms/Input";
import usePersistent from "@/hooks/use-persistent";
import { useDebounce } from "use-debounce";
import ArchivedExamsTable from "./ArchivedExamsTable/ArchivedExamsTable";

const ArchivedExams = () => {
  const [searchText, setSearchText] = usePersistent(
    "administration-archived-exams-searchText",
    ""
  );
  const [debouncedSearchText] = useDebounce(searchText, 300);

  return (
    <div className="flex flex-col gap-6 h-full w-full">
      <h2>Sessions archivées</h2>
      <Input
        className="shadow-none"
        id="shadcn-input"
        searchIcon
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value as string)}
        placeholder="Rechercher"
      />
      <ArchivedExamsTable searchText={debouncedSearchText} />
    </div>
  );
};

export default ArchivedExams;
