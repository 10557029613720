import { useQueryCandidateBasicInfo } from "@/api/candidate/candidate";
import { FormationCertificateDtoClient } from "@/api/dto/formation-certificate.dto";
import { useMutationFormationAttestationStatus } from "@/api/formation-attestation/formation-attestation";
import { keyFactory } from "@/api/keyFactory";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { Skeleton } from "@/components/atoms/Skeleton";
import FormationAttestationDisplay from "@/components/molecules/FormationAttestationsForm/FormationAttestationDisplay";
import LoadingError from "@/components/molecules/LoadingError";
import { Perimeter } from "@/lib/perimeter";
import { DialogTitle } from "@radix-ui/react-dialog";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";

interface FormationAttestationDialogProps {
  candidateId: number;
  registrationId: number;
  formationAttestation: FormationCertificateDtoClient;
  examPerimeter: Perimeter;
  disabled: boolean;
}

const FormationAttestationDialog = ({
  candidateId,
  formationAttestation,
  examPerimeter,
  registrationId,
  disabled,
}: FormationAttestationDialogProps) => {
  const { data, isLoading, isError } = useQueryCandidateBasicInfo(candidateId);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  const statusMutation = useMutationFormationAttestationStatus({
    onSuccess: (_data, { validated }) => {
      toast.success(
        "Attestation de formation " + validated ? "validée" : "refusée"
      );

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.onGoingRegistration(candidateId),
      });

      setOpen(false);
    },
    onError: () => {
      console.error("Échec de la requête", {
        description: "Votre action n'a pas pu être prise en compte.",
      });
    },
  });

  const mutate = (validated: boolean) =>
    statusMutation.mutate({
      registrationId,
      attestationId: formationAttestation.id,
      validated,
    });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button disabled={disabled} className="rounded-lg">
          Consulter
        </Button>
      </DialogTrigger>
      <DialogContent className="p-4 max-w-3xl">
        <DialogHeader>
          <DialogTitle>
            {!data ? (
              isLoading ? (
                <Skeleton className="w-full max-w-36 h-4" />
              ) : isError ? (
                <LoadingError>Erreur de chargement</LoadingError>
              ) : (
                false
              )
            ) : data === "id-not-found" ? (
              "id-not-found"
            ) : (
              `${data.firstName} ${data.lastName} - Attestation de formation`
            )}
          </DialogTitle>
          <DialogDescription hidden={true}>
            Vérification d'une attestation de formation
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-row items-center gap-4">
          <span>Périmètre de l'examen</span>
          <Badge variant="white">{Perimeter.toCode(examPerimeter)}</Badge>
        </div>
        <div className="flex flex-col w-full gap-4">
          <FormationAttestationDisplay attestation={formationAttestation} />
          <div className="flex flex-row gap-4">
            <Button
              variant="red"
              onClick={() => mutate(false)}
              className="w-1/2"
            >
              Refuser
            </Button>
            <Button
              variant="green"
              onClick={() => mutate(true)}
              className="w-1/2"
            >
              Valider
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FormationAttestationDialog;
