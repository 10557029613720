import { ExamStatus, ExamStatusUtil } from "@/api/enums/exam-status.enum";
import { ExamType } from "@/api/enums/ExamType";
import { ResultsTab } from "@/api/exam/dto/results-tab.enum";
import { Input } from "@/components/atoms/Input";
import usePersistent from "@/hooks/use-persistent";
import { Perimeter } from "@/lib/perimeter";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { useSessionContext } from "../session-context";
import { SessionTab } from "../session-tab.enum";
import CandidatesFilterTabs from "./CandidatesFilterTabs/CandidatesFilterTabs";
import CandidatesTable from "./CandidatesTable/CandidatesTable";
import ImportEdcResultsDialog from "./ImportEdcResultsDialog";
import InfoCards from "./InfoCards";
import PerimetersFilterSheet from "./PerimetersFilterSheet";
import RemovePreRegisteredDialog from "./RemovePreRegisteredDialog/RemovePreRegisteredDialog";
import ResultsFilterTabs from "./ResultsFilterTabs/ResultsFilterTabs";

const Candidates = () => {
  const [searchText, setSearchText] = usePersistent<string>(
    "admin-session-candidates-search-text",
    ""
  );
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const { exam, tab } = useSessionContext();
  const [perimeters, setPerimeters] = useState<string[]>([]);
  const [complete, setComplete] = useState<boolean | undefined>(undefined);

  const [resultsTab, setResultsTab] = usePersistent<ResultsTab>(
    "admin-session-results-tab",
    ResultsTab.All
  );

  return (
    <>
      <div className="p-6 flex flex-col gap-4 w-full h-full">
        <InfoCards />
        {tab === SessionTab.Candidates && (
          <CandidatesFilterTabs setComplete={setComplete} />
        )}
        {tab === SessionTab.Results && (
          <ResultsFilterTabs setTab={setResultsTab} tab={resultsTab} />
        )}
        <div className="flex flex-row gap-3">
          <Input
            className="shadow-none"
            id="shadcn-input"
            searchIcon
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value as string)}
            placeholder="Rechercher"
          />

          {ExamStatusUtil.order(exam.status) <=
            ExamStatusUtil.order(ExamStatus.QCMS_GENERATED) && (
            <RemovePreRegisteredDialog />
          )}

          {ExamStatusUtil.order(exam.status) >=
            ExamStatusUtil.order(ExamStatus.QCMS_GENERATED) && (
            <PerimetersFilterSheet
              examId={exam.id}
              perimeters={perimeters}
              setPerimeters={setPerimeters}
            />
          )}

          {/* Renewing exams don't include EDCs*/}
          {tab === SessionTab.Results && exam.type === ExamType.INITIAL && (
            <ImportEdcResultsDialog />
          )}
        </div>
        <CandidatesTable
          resultsTab={resultsTab}
          perimeters={perimeters.map(Perimeter.toDto)}
          searchText={debouncedSearchText}
          complete={complete}
        />
      </div>
    </>
  );
};

export default Candidates;
