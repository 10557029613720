import { QueueEnum } from "@/api/enums/queue.enum";
import { JobState, QueueJob } from "@/api/queues/dto/queues-jobs.dto";
import { Badge } from "@/components/atoms/Badge";
import ErrorDisplay from "@/components/molecules/ErrorDisplay";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useMemo } from "react";

interface QueueJobCardProps<T extends QueueEnum> {
  job: QueueJob<T>;
}

const QueueJobCard = <T extends QueueEnum>({
  job: { data, queue, state },
}: QueueJobCardProps<T>) => {
  const name = useMemo(() => {
    switch (queue) {
      case QueueEnum.ExamResult:
        return `Génération des relevés de notes et attestations de formation pour l'examen ${data.exam?.number}`;
      case QueueEnum.Certificate:
        return `Génération des certificats pour l'examen ${data.exam?.number}`;
      case QueueEnum.YouSign:
        return `Génération de la signature YouSign pour l'examen ${data.exam?.number}`;
    }
  }, [queue, data]);

  return (
    <div
      className={cn(
        "flex flex-col gap-2 border-gray-200 border-2 rounded-lg p-4 bg-gray-25",
        {
          "border-orange-200": state.state === JobState.Active,
          "border-green-200": state.state === JobState.Completed,
          "border-red-200": state.state === JobState.Failed,
          "border-purple-200": state.state === JobState.Waiting,
        }
      )}
    >
      <div className="flex flex-row gap-2 flex-wrap">
        <Badge
          variant={
            state.state === JobState.Active
              ? "orange"
              : state.state === JobState.Completed
              ? "green"
              : state.state === JobState.Failed
              ? "red"
              : state.state === JobState.Waiting
              ? "purple"
              : "white"
          }
          className={cn({
            "animate-pulse": state.state === JobState.Active,
          })}
        >
          {state.state === JobState.Active
            ? "En cours..."
            : state.state === JobState.Completed
            ? "Terminé"
            : state.state === JobState.Failed
            ? "Erreur"
            : state.state === JobState.Waiting
            ? "En attente"
            : "Inconnu"}
        </Badge>
        {[
          { name: "Création", time: state.timestamp },
          { name: "Début", time: state.processedOn },
          { name: "Fin", time: state.finishedOn },
        ]
          .filter((t) => t.time !== undefined)
          .map(({ name, time }) => (
            <Badge variant="white" key={name}>
              {name} :{" "}
              {format(new Date(time as number), "dd/MM/yyyy - HH:mm:ss", {
                locale: fr,
              })}
            </Badge>
          ))}
      </div>
      <span className="font-semibold">{name}</span>
      {state.state === JobState.Failed && state.failedReason && (
        <ErrorDisplay msg={state.failedReason} />
      )}
    </div>
  );
};

export default QueueJobCard;
