import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { PayementStatus } from "@/api/enums/PayementStatus";
import { keyFactory } from "@/api/keyFactory";
import { useMutationPayementTransferStatus } from "@/api/payement/payement";
import { Button } from "@/components/atoms/Button";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import FileLink from "@/components/molecules/FileLink";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import { PayementUtil } from "@/lib/payement-util";
import { RegistrationUtil } from "@/lib/registration-util";
import { formatDate } from "@/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";
import PayementBadge from "../PayementBadge";
import PayementAcknowledgeDialog from "./PayementAcknowledgeDialog";

interface PayementCardProps {
  registration: CandidateRegistrationDtoClient;
  candidateId: number;
}

const PayementCard = ({ registration, candidateId }: PayementCardProps) => {
  const { payementDate } = registration;
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const transferStatusMutation = useMutationPayementTransferStatus();

  return (
    <>
      <ConfirmDialog
        title="Annuler le paiement"
        open={cancelDialogOpen}
        onOpenChange={setCancelDialogOpen}
        validateButtonVariant="red"
        onResult={(confirmed) => {
          if (confirmed) {
            transferStatusMutation.mutate(
              {
                accepted: false,
                registrationId: registration.id,
              },
              {
                onSuccess: () => {
                  toast.success("Paiement annulé");

                  queryClient.invalidateQueries({
                    queryKey:
                      keyFactory.candidate.onGoingRegistration(candidateId),
                  });

                  queryClient.invalidateQueries({
                    queryKey: keyFactory.exam.base(),
                  });

                  queryClient.invalidateQueries({
                    queryKey: keyFactory.registration.base(),
                  });

                  setCancelDialogOpen(false);
                },
                onError: () => {
                  toast.error("Échec de la requête", {
                    description:
                      "Votre action n'a pas pu être prise en compte.",
                  });
                },
              }
            );
          } else setCancelDialogOpen(false);
        }}
      />
      <Card>
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row gap-2 items-center">
            <CardTitle>Paiement</CardTitle>
            <PayementBadge registration={registration} />
            {PayementUtil.isPaid(registration.payementStatus) && (
              <span>{payementDate && formatDate(payementDate)}</span>
            )}
          </div>
          <FileLink
            showName={false}
            file={RegistrationUtil.currentDebitNote(registration)}
          />
          {(registration.payementStatus ===
            PayementStatus.DELEGATION_PAID_TRANSFER ||
            registration.payementStatus ===
              PayementStatus.PERSONAL_PAID_TRANSFER) && (
            <Button
              variant="ghostRed"
              onClick={() => setCancelDialogOpen(true)}
            >
              Annuler
            </Button>
          )}{" "}
          {PayementUtil.isWaitingValidation(registration.payementStatus) && (
            <PayementAcknowledgeDialog
              registration={registration}
              candidateId={candidateId}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default PayementCard;
