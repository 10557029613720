import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { ChangePasswordDto } from "../auth/dto/change-password.dto";
import { RegisterUserDto } from "../auth/dto/register-user.dto";
import { keyFactory } from "../keyFactory";
import { AdminDtoClient } from "./dto/admin.dto";
import { NestError } from "../nest-error";

// post add

const add = async (body: RegisterUserDto) => {
  await axios.post<void>(ENDPOINT.admin.postAdd(), body);
};

export const useMutationAdminAdd = (
  props?: Partial<
    UseMutationOptions<void, AxiosError<NestError>, RegisterUserDto>
  >
) =>
  useMutation({
    mutationFn: add,
    ...props,
  });

// get all

const getAll = async () =>
  await axios
    .get<AdminDtoClient[]>(ENDPOINT.admin.getAll())
    .then((res) => res.data);

export const useQueryAdminAll = (
  props?: Partial<UseQueryOptions<AdminDtoClient[], AxiosError<NestError>>>
) =>
  useQuery({
    queryKey: keyFactory.admin.getAll(),
    queryFn: getAll,
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });

// put change password

const changePassword = async (id: number, body: ChangePasswordDto) => {
  await axios.put<void>(ENDPOINT.admin.putChangePassword(id), body);
};

export const useMutationAdminChangePassword = (
  props?: Partial<
    UseMutationOptions<
      void,
      AxiosError<NestError>,
      { id: number; body: ChangePasswordDto }
    >
  >
) =>
  useMutation({
    mutationFn: ({ id, body }: { id: number; body: ChangePasswordDto }) =>
      changePassword(id, body),
    ...props,
  });

// delete

const deleteAdmin = async (id: number) => {
  await axios.delete<void>(ENDPOINT.admin.delete(id));
};

export const useMutationAdminDelete = (
  props?: Partial<UseMutationOptions<void, AxiosError, number>>
) =>
  useMutation({
    mutationFn: deleteAdmin,
    ...props,
  });
