"use client";

import { nullDate } from "@/constants/zodTypes";
import { cn, formatDate } from "@/lib/utils";
import { Button } from "@atoms/Button";
import { Calendar } from "@atoms/Calendar";
import { FormControl, useFormField } from "@atoms/Form";
import { Popover, PopoverContent, PopoverTrigger } from "@atoms/Popover";
import { Calendar as CalendarIcon } from "lucide-react";
import { fr } from "react-day-picker/locale";

interface DatePickerProps {
  restrictToToday?: boolean;
  disabled?: boolean;
  disabledDates?: (date: Date) => boolean;
  placeholder?: string;
}

const DatePicker = ({
  restrictToToday = false,
  disabled = false,
  disabledDates,
  placeholder = "DD/MM/YYYY",
}: DatePickerProps) => {
  const { invalid, value, onChange } = useFormField();
  const valueIsNull =
    !value || new Date(value).getTime() === nullDate().getTime();

  return (
    <Popover modal={true}>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant={"outline"}
            className={cn(
              "w-full border-gray-300 justify-start text-left font-normal disabled:opacity-100 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50",
              !value && "text-muted-foreground",
              invalid && "border-red-500 border"
            )}
            disabled={disabled}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {!valueIsNull ? formatDate(value) : <span>{placeholder}</span>}
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          defaultMonth={!valueIsNull ? value : new Date()}
          locale={fr}
          required={true}
          mode="single"
          selected={value}
          onSelect={onChange}
          disabled={(date) =>
            restrictToToday
              ? date > new Date() || date < new Date("1900-01-01")
              : disabledDates
              ? disabledDates(date)
              : false
          }
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePicker;
