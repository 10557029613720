import { ROUTE } from "@/constants/routes";
import { socket } from "@/hooks/socket";
import { format } from "date-fns";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ExamDoneReason } from "./ExamDone/exam-done-reason.enum";

const Exam = () => {
  const navigate = useNavigate();

  // disconnect  socket
  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, []);

  // add listeners
  useEffect(() => {
    // exam finished
    socket.on("examFinished", () =>
      navigate(ROUTE.exam.done(ExamDoneReason.AdminsClosed))
    );

    // additional time
    socket.on("plannedFinishTime", (plannedFinishTime: Date) => {
      toast.success("Nouvel horaire de fin", {
        description: `Votre horaire de fin est maintenant ${format(
          plannedFinishTime,
          "HH:mm"
        )}.`,
      });
    });

    // connection refused
    socket.on("connectionRefused", (reason: string) => {
      toast.error("Déconnexion", { description: reason });
      navigate(ROUTE.exam.login());
      socket.disconnect();
    });

    return () => {
      socket.off("examFinished");
      socket.off("connectionRefused");
    };
  }, [navigate]);

  return <Outlet />;
};

export default Exam;
