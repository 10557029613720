import { useFormField } from "@/components/atoms/Form";

// Custom hook to manage field state
const useFieldState = <T>(valueProp?: T, onChangeProp?: (value: T) => void) => {
  try {
    return useFormField();
  } catch {
    if (valueProp === undefined || onChangeProp === undefined)
      throw new Error(
        "ComboboxField must be used with useFormField or with value and onChange props"
      );

    return { error: null, value: valueProp, onChange: onChangeProp };
  }
};

export { useFieldState };
