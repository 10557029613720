import {
  FormationType,
  FormationTypeDescriptions,
} from "@/api/enums/FormationType";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { useFormationInstitutesQuery } from "@/api/formation-institute/formation-institute";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Skeleton } from "@/components/atoms/Skeleton";
import { ComboboxField } from "@/components/molecules/Combobox/Combobox";
import DropzoneMolecule from "@/components/molecules/Dropzone";
import { PerimeterForm } from "@/components/molecules/PerimeterForm/PerimeterForm";
import useChildForm from "@/hooks/ChildForm";
import { Perimeter } from "@/lib/perimeter";
import { Ban, Check, Trash2 } from "lucide-react";
import LoadingError from "../LoadingError";

interface FormationAttestationFormProps {
  fieldName: string;
  id?: number;
  validationStatus?: ValidationStatus;
  forbiddenFormationTypes?: FormationType[];
  hiddenPerimeter?: Perimeter;
  remove?: (() => void) | null;
}

const FormationAttestationForm = ({
  id,
  fieldName,
  validationStatus,
  forbiddenFormationTypes = [],
  hiddenPerimeter = Perimeter.empty(),
  remove = null,
}: FormationAttestationFormProps) => {
  const { transformPath, control, watch } = useChildForm(fieldName);
  const formationType = watch(transformPath("formationType"));

  const formationInstitutesQuery = useFormationInstitutesQuery();

  if (!formationInstitutesQuery.data) {
    if (formationInstitutesQuery.isLoading)
      return <Skeleton className="w-full h-full min-h-64" />;
    else return <LoadingError>Erreur de chargement du certificat</LoadingError>;
  }

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="flex flex-row gap-2">
        <h2>Attestation {id !== undefined && `N°${id + 1}`}</h2>
        {validationStatus === ValidationStatus.PENDING && (
          <Badge variant="orange">En attente de validation</Badge>
        )}
        {validationStatus === ValidationStatus.REFUSED && (
          <>
            <Ban className="w-6 h-6 text-red-500" />
            <Badge variant="red">Refusée</Badge>
          </>
        )}
        {validationStatus === ValidationStatus.VALIDATED && (
          <Check className="w-6 h-6 text-green-500" />
        )}
      </div>
      <div className="flex flex-row w-full gap-2 mb-4">
        <FormField
          control={control}
          name={transformPath("formationType")}
          render={() => (
            <FormItem className="w-full">
              <FormLabel>Type de formation</FormLabel>
              <FormControl>
                <ComboboxField
                  options={Object.values(FormationType)
                    .filter((type) => !forbiddenFormationTypes.includes(type))
                    .map((type) => ({
                      value: type,
                      label: FormationTypeDescriptions[type],
                    }))}
                  placeholder="-"
                  contentWidth={400}
                  displaySearchBox={false}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {formationType === FormationType.INSTITUTE && (
          <FormField
            control={control}
            name={transformPath("formationInstituteId")}
            render={() => (
              <FormItem className="w-full">
                <FormLabel>Organisme de formation</FormLabel>
                <FormControl>
                  <ComboboxField
                    options={formationInstitutesQuery.data.map((institute) => ({
                      label: institute.description,
                      value: institute.id,
                    }))}
                    placeholder={""}
                    stringifyValue={(value) => value?.toString() ?? "-"}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
      </div>
      <FormField
        control={control}
        name={transformPath("certificate")}
        render={() => (
          <FormItem className="w-full">
            <FormControl>
              <DropzoneMolecule canBeRemoved={true} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <PerimeterForm
        fieldName={transformPath("perimeter")}
        transportsTitleElement={
          <>Quel(s) mode(s) est/sont couvert(s) par l'attestation ?</>
        }
        merchandisesTitleElement={
          <>
            Quelle(s) classe(s) de marchandises dangereuses est/sont couverte(s)
            par l'attestation ?
          </>
        }
        hiddenPerimeter={hiddenPerimeter}
      />
      {remove !== null && (
        <Button
          className="w-full  gap-2 "
          variant={"red"}
          onClick={(e) => {
            e.preventDefault();
            remove();
          }}
        >
          Supprimer cette attestation
          <Trash2 className="w-5 h-5" />
        </Button>
      )}
    </div>
  );
};

export default FormationAttestationForm;
