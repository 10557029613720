import CompanyModifyInfoForm from "@/components/molecules/CompanyModifyInfoForm/CompanyModifyInfoForm";

const Information = () => {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col w-full border-b border-b-gray-200">
        <div className="py-3 px-6 bg-white w-full">
          <h1>Informations entreprise</h1>
        </div>
      </div>
      <CompanyModifyInfoForm />
    </div>
  );
};

export default Information;
