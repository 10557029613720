import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import FileLink from "@/components/molecules/FileLink";
import { Search } from "lucide-react";
import { useMemo } from "react";

interface PostExamDocumentsProps {
  registration: CandidateRegistrationDtoClient;
}

const PostExamDocuments = ({
  registration: { examResult },
}: PostExamDocumentsProps) => {
  const documents = useMemo(() => {
    if (!examResult) return undefined;
    return [
      {
        title: "Attestation de présence",
        file: examResult.attendanceCertificate,
      },
      { title: "Certificat signé", file: examResult.certificate },
      { title: "Relevé de notes", file: examResult.resultsDocument },
    ].filter(({ file }) => !!file);
  }, [examResult]);

  if (!documents?.length)
    return (
      <div className="bg-gray-100 flex flex-col gap-4 items-center justify-center py-8 rounded-lg">
        <Search className="w-6 h-6 text-gray-400" />
        <span>Pas encore disponible</span>
      </div>
    );

  return (
    <div className="flex flex-row gap-6">
      {documents.map(({ file, title }) => (
        <div
          className="flex flex-row border border-gray-200 rounded-lg py-6 px-4 justify-between w-full"
          key={title}
        >
          <span>{title}</span>
          <FileLink file={file} showName={false} />
        </div>
      ))}
    </div>
  );
};

export default PostExamDocuments;
