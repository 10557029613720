import { useQueryExportAccounting } from "@/api/export/export";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import DatePicker from "@/components/molecules/DatePicker";
import { date, nullDate } from "@/constants/zodTypes";
import { downloadFile } from "@/lib/download-file";
import { formatDate } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { HttpStatusCode } from "axios";
import { Download } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z
  .object({
    startDate: date(),
    endDate: date(),
  })
  .superRefine(({ endDate, startDate }, ctx) => {
    if (startDate > endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "La date de fin doit être postérieure à la date de début",
      });
    }
  });

type Schema = z.infer<typeof schema>;

const ExportAccountingDialog = () => {
  const [open, setOpen] = useState(false);

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      startDate: nullDate(),
      endDate: nullDate(),
    },
  });

  const startDate = form.watch("startDate");
  const endDate = form.watch("endDate");

  const { isLoading, isError, error, refetch, data } = useQueryExportAccounting(
    {
      startDate,
      endDate,
    }
  );

  // display error
  useEffect(() => {
    if (!isError) return;

    switch (error.response?.status) {
      case HttpStatusCode.NotImplemented:
        toast.error("Export non implémenté pour l'instant.");
        return;

      default:
        toast.error("Échec de l'export");
    }
  }, [isError, error?.response?.status]);

  // download file
  useEffect(() => {
    if (!data) return;

    downloadFile(
      data,
      `suivi-comptable-${formatDate(startDate)}-${formatDate(endDate)}.xlsx`
    );
  }, [data, startDate, endDate]);

  const onSubmit = () => refetch();

  const globalErrors = useMemo(
    () => Object.entries(form.formState.errors).filter(([key]) => key === ""),
    [form.formState.errors]
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Download className="w-5 h-5" />
      </DialogTrigger>
      <DialogContent className="p-4">
        <DialogHeader>
          <DialogTitle>Suivi comptable</DialogTitle>
          <DialogDescription hidden>Suivi comptable</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="startDate"
              render={() => (
                <FormItem>
                  <FormLabel>Date de début</FormLabel>
                  <DatePicker />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="endDate"
              render={() => (
                <FormItem>
                  <FormLabel>Date de fin</FormLabel>
                  <DatePicker disabledDates={(d) => d <= startDate} />
                  <FormMessage />
                </FormItem>
              )}
            />
            {globalErrors.length > 0 && (
              <div className="p-2 text-red-600">
                {globalErrors.map(([, error], i) => (
                  <div key={i}>{error?.message as string}</div>
                ))}
              </div>
            )}
            <Button disabled={isLoading}>
              {isLoading ? "Export en cours..." : "Exporter"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ExportAccountingDialog;
