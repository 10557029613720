import { ROUTE } from "@/constants/routes";
import { useAuthStore } from "@/hooks/AuthStore";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./ConfirmDialog";
import { useLogoutMutation } from "@/api/auth/auth";
import { useQueryClient } from "@tanstack/react-query";

interface DisconnectPromptProps {
  isOpen: boolean;
  close: () => void;
  redirectionPage?: string;
}

const DisconnectPrompt = ({
  isOpen,
  close,
  redirectionPage = ROUTE.connectionHome(),
}: DisconnectPromptProps) => {
  const queryClient = useQueryClient();
  const { logout } = useAuthStore();
  const navigate = useNavigate();
  const logoutMutation = useLogoutMutation();

  return (
    <ConfirmDialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) close();
      }}
      onResult={(disconnects) => {
        if (disconnects) {
          logoutMutation.mutate();
          logout(queryClient);
          navigate(redirectionPage);
        } else {
          close();
        }
      }}
      title="Déconnexion"
      message="Êtes vous sûr de vouloir vous déconnecter ?"
      validateStr="Oui"
      cancelStr="Non"
    />
  );
};

export default DisconnectPrompt;
