import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import NextStepButton from "../NextStepButtons";
import { RegisterContext } from "../RegisterContext";
import CGU from "./CGU";
import CGV from "./CGV";
import MustBeScrolledBox from "./MustBeScrolledBox";

const Acknowledgement = () => {
  const { onNextStep, pending } = useOutletContext<RegisterContext>();
  const [CGUChecked, setCGUChecked] = useState(false);
  const [CGVChecked, setCGVChecked] = useState(false);

  return (
    <div className="w-full flex flex-col gap-8 items-start max-w-4xl">
      <div className="flex p-4 flex-col items-start gap-4 self-stretch rounded-md border-gray-200 bg-white border w-full text-gray-500 text-sm font-medium">
        <h1>Acceptation des CGU et CGV</h1>
        <MustBeScrolledBox
          checked={CGUChecked}
          title="Veuillez lire dans leur ensemble les conditions générales d'utilisation"
          checkedText="J'ai lu et j'accepte les CGU*"
          setChecked={setCGUChecked}
        >
          <CGU />
        </MustBeScrolledBox>
        <MustBeScrolledBox
          checked={CGVChecked}
          title="Veuillez lire dans leur ensemble les conditions générales de ventes"
          checkedText="J'ai lu et j'accepte les CGV*"
          setChecked={setCGVChecked}
        >
          <CGV />
        </MustBeScrolledBox>
      </div>
      <NextStepButton
        onNext={() => onNextStep(true)}
        onPrevious={() => onNextStep(false)}
        nextDisabled={!(CGUChecked && CGVChecked)}
        nextButtonText="Valider ma pré-inscription"
        pending={pending}
      />
    </div>
  );
};

export default Acknowledgement;
