import HelloPageLayout from "@/components/templates/hello-page";
import { CheckIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import { ExamDoneReason } from "./exam-done-reason.enum";

const ExamDone = () => {
  const { reason } = useParams<{ reason: ExamDoneReason }>();

  return (
    <HelloPageLayout useBackButton={false} className="max-w-3xl items-center">
      <CheckIcon className="w-5 h-5 text-white bg-green-600 rounded-full p-0.5" />
      <div className="flex flex-col items-center gap-2">
        <h2>
          {reason === ExamDoneReason.AdminsClosed
            ? "Examen clôturé par les administrateurs"
            : reason === ExamDoneReason.CandidateSubmitted
            ? "Examen soumis"
            : "L'examen est terminé"}
        </h2>
        <span className="text-gray-600">
          Vos réponses ont été prises en compte
        </span>
      </div>
    </HelloPageLayout>
  );
};

export default ExamDone;
