import { ExamDtoClient } from "@/api/dto/exam.dto";
import { useQueryExamAll } from "@/api/exam/exam";
import { SessionExport } from "@/api/export/dto/session-export.enum";
import { useQueryExportSession } from "@/api/export/export";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "@/components/atoms/Form";
import { ComboboxField } from "@/components/molecules/Combobox/Combobox";
import { date, nullDate } from "@/constants/zodTypes";
import { downloadFile } from "@/lib/download-file";
import { formatDate } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { HttpStatusCode } from "axios";
import { Download } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  date: date(),
});

type Schema = z.infer<typeof schema>;

const fileNames = {
  [SessionExport.Questions]: "questions.xlsx",
};

const titles = {
  [SessionExport.Questions]: "Questions",
};

interface SessionExportDialogProps {
  sessionExport: SessionExport;
}

const SessionExportDialog = ({ sessionExport }: SessionExportDialogProps) => {
  const [open, setOpen] = useState(false);

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      date: nullDate(),
    },
  });

  const date = form.watch("date");

  const { data: exams } = useQueryExamAll();
  const { isLoading, isError, error, refetch, data } = useQueryExportSession({
    sessionExport,
    date,
  });

  // display error
  useEffect(() => {
    if (!isError) return;

    switch (error.response?.status) {
      case HttpStatusCode.Conflict:
        toast.error("Pas d'examen pour cette date.");
        return;

      case HttpStatusCode.NotImplemented:
        toast.error("Export non implémenté pour l'instant.");
        return;

      default:
        toast.error("Échec de l'export");
    }
  }, [isError, error?.response?.status]);

  const options = useMemo(() => {
    if (!exams) return [];
    const dates: Record<string, ExamDtoClient[]> = {};
    exams.forEach((exam) => {
      const date = exam.date.toString();
      if (!dates[date]) dates[date] = [];
      dates[date].push(exam);
    });

    return Object.entries(dates)
      .sort(([aDate], [bDate]) => aDate.localeCompare(bDate))
      .map(([date, exams]) => ({
        value: date,
        label: `${formatDate(new Date(date))} (${exams
          .map((exam) => exam.number)
          .join(", ")})`,
      }));
  }, [exams]);

  // download file
  useEffect(() => {
    if (!data) return;
    downloadFile(data, `${date}-${fileNames[sessionExport]}`);
  }, [data, date, sessionExport]);

  const onSubmit = () => refetch();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Download className="w-5 h-5" />
      </DialogTrigger>
      <DialogContent className="p-4">
        <DialogHeader>
          <DialogTitle>{titles[sessionExport]}</DialogTitle>
          <DialogDescription hidden>{titles[sessionExport]}</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="date"
              render={() => (
                <FormItem className="w-full">
                  <FormControl>
                    <ComboboxField options={options} placeholder={"-"} />
                  </FormControl>
                </FormItem>
              )}
            />
            <Button disabled={isLoading}>
              {isLoading ? "Export en cours..." : "Exporter"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default SessionExportDialog;
