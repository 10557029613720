import {
  GlobalFile,
  GlobalFileHelper,
  useFetchFileQuery,
} from "@/constants/globalFile";
import { emptyFile } from "@/constants/zodTypes";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import { FileText } from "lucide-react";
import { ReactNode } from "react";
import { LoadingSpinner } from "./LoadingSpinner";

const fileLinkStyles = cva("font-bold", {
  variants: {
    intent: {
      primary: "text-blue-500 hover:text-blue-600",
      secondary: "text-gray-500 hover:text-gray-600",
      danger: "text-red-500 hover:text-red-600",
    },
    isLoading: {
      true: "opacity-50 cursor-not-allowed",
      false: "",
    },
  },
  defaultVariants: {
    intent: "primary",
    isLoading: false,
  },
});

interface FileLinkProps {
  file?: GlobalFile;
  children?: ReactNode;
  showName?: boolean;
  variant?: "primary" | "secondary" | "danger";
  outerBox?: boolean;
  className?: string;
}

const FileLink = ({
  file,
  children,
  showName = true,
  variant = "primary",
  outerBox = false,
  className = "",
}: FileLinkProps) => {
  const { isLoading, refetch, isError } = useFetchFileQuery(
    file ?? emptyFile()
  );

  if (!file) return false;

  const handleClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const { data } = await refetch();
    if (!data) return;
    window.open(URL.createObjectURL(data), "_blank");
  };

  return (
    <a
      href="#"
      className={cn(
        fileLinkStyles({ intent: variant, isLoading }),
        {
          "flex p-4 border border-gray-200 rounded-lg": outerBox,
        },
        className
      )}
      onClick={handleClick}
    >
      {isLoading || isError ? (
        <LoadingSpinner
          isLoading={isLoading}
          isError={isError}
          errorMessage=""
          loadingMessage=""
        />
      ) : (
        children ?? (
          <div className="flex flex-row gap-2 items-center">
            <FileText className="w-4 h-4" />
            {showName && GlobalFileHelper.fileName(file)}
          </div>
        )
      )}
    </a>
  );
};

export default FileLink;
