import { Perimeter } from "@/lib/perimeter";
import { formatDate } from "@/lib/utils";
import { ArchivedCandidatesPaginationRequestDto } from "./candidate/dto/archived-candidates-pagination.dto";
import { CandidateBasicPaginationRequestDto } from "./candidate/dto/candidate.dto";
import { ForeignCertificatesPaginationRequestDto } from "./certificate/dto/foreign-certificates-paginated.dto";
import { AdminCompanyPaginationRequestDto } from "./company/dto/company-admin.dto";
import { ConstantKey } from "./constant/constants";
import { ExamType } from "./enums/ExamType";
import { CandidateExamResultPaginationRequestDto } from "./exam-result/dto/candidate-exam-result-paginated.dto";
import { ArchivedExamsPaginationRequestDto } from "./exam/dto/archived-exams-pagination.dto";
import { ExamPaginationRequestDto } from "./exam/dto/exams-paginated.dto";
import { RegistrationsPaginationRequestDto } from "./exam/dto/registrations.dto";
import { AccountingDto } from "./export/dto/accounting.dto";
import { ExamExportDto } from "./export/dto/exam-export.dto";
import { SessionExportDto } from "./export/dto/session-export.dto";
import { DateDto } from "./qcm/dto/date.dto";
import { GetQuestionsDto } from "./qcm/dto/get-questions.dto";
import { QCMByDatePaginationRequestDto } from "./qcm/dto/qcm-by-date-paginated.dto";
import { QuestionBasicPaginationRequestDto } from "./question/dto/question.dto";
import { RegistrationPaginationRequestDto } from "./registration/dto/registration-paginated.dto";

const keyFactory = {
  hello: () => ["hello"],

  admin: {
    base: () => ["admins"],
    getAll: () => keyFactory.admin.base().concat(["all"]),
  },

  auth: {
    base: () => ["auth"],
    role: () => keyFactory.auth.base().concat(["role"]),
    register: {
      base: () => keyFactory.auth.base().concat(["register"]),
      company: {
        base: () => keyFactory.auth.register.base().concat(["company"]),
        prefilledInfo: () =>
          keyFactory.auth.register.company.base().concat(["prefilledInfo"]),
      },
    },
  },

  candidate: {
    base: () => ["candidate"],
    info: (candidateId?: number) =>
      keyFactory.candidate
        .base()
        .concat(["info", candidateId?.toString() ?? ""]),
    registrations: (candidateId?: number) =>
      keyFactory.candidate
        .base()
        .concat(["registrations", candidateId?.toString() ?? ""]),
    canRegister: () => keyFactory.candidate.base().concat(["can-register"]),
    allPaginated: ({
      pageIndex,
      searchText,
      status,
    }: CandidateBasicPaginationRequestDto) =>
      keyFactory.candidate
        .base()
        .concat(["all", pageIndex.toString(), searchText ?? "", status ?? ""]),
    basicInfo: (candidateId: number) =>
      keyFactory.candidate.base().concat(["basic", candidateId.toString()]),
    onGoingRegistration: (candidateId?: number) =>
      keyFactory.candidate
        .base()
        .concat([
          "ongoing-registration",
          ...(candidateId ? [candidateId.toString()] : []),
        ]),
    archived: (params?: ArchivedCandidatesPaginationRequestDto) =>
      keyFactory.candidate
        .base()
        .concat([
          "archived",
          ...(params
            ? [params.pageIndex.toString(), params.searchText ?? ""]
            : []),
        ]),
  },

  certificate: {
    base: () => ["certificate"],
    all: (candidateId?: number) =>
      keyFactory.certificate
        .base()
        .concat(["all", candidateId?.toString() ?? ""]),
    foreignCertificatesPaginated: (
      params?: ForeignCertificatesPaginationRequestDto
    ) =>
      keyFactory.certificate
        .base()
        .concat([
          "foreign-certificates",
          ...(params
            ? [
                params.pageIndex?.toString() ?? "",
                params.searchText ?? "",
                params.status ?? "",
              ]
            : []),
        ]),
  },

  company: {
    base: () => ["company"],
    info: (companyId?: number) =>
      keyFactory.company.base().concat(["info", companyId?.toString() ?? ""]),
    delegations: () => keyFactory.company.base().concat(["delegations"]),
    allPaginated: ({
      pageIndex,
      searchText,
    }: AdminCompanyPaginationRequestDto) =>
      keyFactory.company
        .base()
        .concat(["all", pageIndex.toString(), searchText ?? ""]),
  },

  constant: {
    base: () => ["constant"],
    byKey: (key: ConstantKey) => keyFactory.constant.base().concat([key]),
  },

  draft: {
    base: () => ["draft"],
    options: () => keyFactory.draft.base().concat(["options"]),
  },

  exam: {
    base: () => ["exam"],
    all: () => keyFactory.exam.base().concat(["all"]),
    detailedPaginated: ({
      pageIndex,
      searchText,
      status,
      type,
      registrationClosed,
    }: ExamPaginationRequestDto) =>
      keyFactory.exam
        .all()
        .concat([
          "detailed paginated",
          pageIndex.toString(),
          status?.join(",") ?? "",
          searchText ?? "",
          type ?? "",
          registrationClosed === undefined ? "" : String(registrationClosed),
        ]),
    detailed: (examId: number) =>
      keyFactory.exam.base().concat(["detailed", examId.toString()]),
    nextNumber: (type: ExamType) =>
      keyFactory.exam.base().concat(["nextNumber", type]),
    registrations: (props?: RegistrationsPaginationRequestDto) =>
      keyFactory.exam
        .base()
        .concat([
          "registrations",
          ...(props
            ? [
                props.examId.toString(),
                props.pageIndex.toString(),
                props.resultsTab || "",
                props.searchText ?? "",
                String(props.complete),
                ...(props.perimeters?.map(Perimeter.toCode).sort() ?? []),
              ]
            : []),
        ]),
    perimeters: (examId: number) =>
      keyFactory.exam.base().concat(["perimeters", examId.toString()]),
    archived: (params?: ArchivedExamsPaginationRequestDto) =>
      keyFactory.exam
        .base()
        .concat(
          ["archived"],
          params ? [params.pageIndex.toString(), params.searchText ?? ""] : []
        ),
  },

  examResult: {
    base: () => ["examResult"],
    candidate: (
      props?: CandidateExamResultPaginationRequestDto,
      candidateId?: number
    ) =>
      keyFactory.examResult
        .base()
        .concat([
          "candidate",
          candidateId?.toString() ?? "",
          ...(props
            ? [props.pageIndex.toString(), props.searchText ?? ""]
            : []),
        ]),
  },

  export: {
    base: () => ["export"],
    exam: ({ examExport, examId }: ExamExportDto) =>
      keyFactory.export.base().concat(["exam", examId.toString(), examExport]),
    session: ({ date, sessionExport }: SessionExportDto) =>
      keyFactory.export
        .base()
        .concat(["session", formatDate(date), sessionExport]),
    accounting: ({ startDate, endDate }: AccountingDto) =>
      keyFactory.export
        .base()
        .concat(["accounting", formatDate(startDate), formatDate(endDate)]),
    ministry: () => keyFactory.export.base().concat(["ministry"]),
  },

  file: (key: string) => ["file", key],

  formationInstitutes: {
    base: () => ["formationInstitutes"],
    all: () => keyFactory.formationInstitutes.base().concat(["all"]),
  },

  question: {
    base: () => ["question"],
    allPaginated: ({
      pageIndex,
      activationYears,
      active,
      deactivationYears,
      merchandises,
      searchText,
      transportModes,
      validationYears,
    }: QuestionBasicPaginationRequestDto) =>
      keyFactory.question
        .base()
        .concat([
          "all-paginated",
          pageIndex.toString(),
          searchText ?? "",
          active?.toString() ?? "",
          ...(validationYears?.map((y) => y.toString()) ?? [""]),
          ...(activationYears?.map((y) => y.toString()) ?? [""]),
          ...(deactivationYears?.map((y) => y.toString()) ?? [""]),
          transportModes?.join(",") ?? "",
          merchandises?.join(",") ?? "",
        ]),
    filters: () => keyFactory.question.base().concat(["filters"]),
    byId: (id: number) => keyFactory.question.base().concat([id.toString()]),
    search: (number: number) =>
      keyFactory.question.base().concat(["search", number.toString()]),
  },

  registration: {
    base: () => ["registration"],
    get: (registrationId: number) =>
      keyFactory.registration.base().concat([registrationId.toString()]),
    allPaginated: (params?: RegistrationPaginationRequestDto) =>
      keyFactory.registration
        .base()
        .concat([
          "all",
          ...(params
            ? [
                params.pageIndex.toString(),
                params.examIds?.map((examId) => examId.toString()).join(",") ??
                  "",
                params.searchText ?? "",
                String(params.complete),
                params.payementStatus?.join(",") ?? "",
                params.needingConfirmation ? "true" : "false",
              ]
            : []),
        ]),
    possibleExamIds: () =>
      keyFactory.registration.base().concat(["possible-exam-ids"]),
    examPerimeters: () =>
      keyFactory.registration.base().concat(["exam-perimeters"]),
  },

  queues: {
    base: () => ["queues"],
    jobs: () => keyFactory.queues.base().concat(["jobs"]),
  },

  qcm: {
    base: () => ["qcm"],
    byDate: (params?: QCMByDatePaginationRequestDto) =>
      keyFactory.qcm
        .base()
        .concat([
          "by-date",
          ...(params
            ? [
                params.pageIndex.toString(),
                params.searchText ?? "",
                params.status?.join(",") ?? "",
              ]
            : []),
        ]),
    byDateDetailed: ({ date }: DateDto) =>
      keyFactory.qcm.base().concat(["by-date-detailed", date.toISOString()]),
    questions: ({ date, perimeter }: GetQuestionsDto) =>
      keyFactory.qcm
        .base()
        .concat(["questions", date.toISOString(), Perimeter.toCode(perimeter)]),
  },

  payement: {
    base: () => ["payement"],
  },
};

export { keyFactory };
