import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { keyFactory } from "@/api/keyFactory";
import { useMutationReducedPriceStatus } from "@/api/reduced-price/reduced-price";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import GreenCheck from "@/components/atoms/GreenCheck";
import RedCross from "@/components/atoms/RedCross";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import FileLink from "@/components/molecules/FileLink";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import { PayementUtil } from "@/lib/payement-util";
import { RegistrationUtil } from "@/lib/registration-util";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";
import AddDocumentDialog from "./AddDocumentDialog";
import ConsultDocumentDialog from "./ConsultDocumentDialog";

interface ReducedPriceCardProps {
  registration: CandidateRegistrationDtoClient;
  candidateId: number;
}

const ReducedPriceCard = ({
  registration,
  candidateId: id,
}: ReducedPriceCardProps) => {
  const { reducedPriceStatus, reducedPriceDocument } = registration;
  const queryClient = useQueryClient();
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);

  const statusMutation = useMutationReducedPriceStatus({
    onSuccess: () => {
      toast.success("Justificatif de tarif réduit invalidé");
      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.onGoingRegistration(id),
      });
      setConfirmCancelDialogOpen(false);
    },
    onError: () => {
      toast.error("Échec de la requête", {
        description: "Votre action n'a pas pu être prise en compte.",
      });
    },
  });

  if (
    reducedPriceStatus === ValidationStatus.UNAVAILABLE ||
    RegistrationUtil.hasNonRefusedDelegation(registration) ||
    PayementUtil.isPaid(registration.payementStatus)
  )
    return false;

  return (
    <>
      <ConfirmDialog
        open={confirmCancelDialogOpen}
        onResult={(accepted) => {
          if (accepted) {
            statusMutation.mutate({
              registrationId: registration.id,
              validated: false,
            });
          } else setConfirmCancelDialogOpen(false);
        }}
        title="Invalidation du justificatif de tarif réduit"
        validateButtonVariant="red"
      />
      <Card>
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row gap-2 items-center">
            <CardTitle>Tarif réduit</CardTitle>
            {reducedPriceStatus === ValidationStatus.REFUSED && <RedCross />}
            {reducedPriceStatus === ValidationStatus.PENDING && (
              <Badge variant="orange">En attente validation</Badge>
            )}
            {reducedPriceStatus === ValidationStatus.VALIDATED && (
              <GreenCheck />
            )}
            {reducedPriceDocument && (
              <FileLink showName={false} file={reducedPriceDocument} />
            )}
          </div>

          {reducedPriceStatus === ValidationStatus.REFUSED && (
            <AddDocumentDialog registration={registration} />
          )}
          {reducedPriceStatus === ValidationStatus.PENDING && (
            <ConsultDocumentDialog registration={registration} />
          )}
          {reducedPriceStatus === ValidationStatus.VALIDATED && (
            <Button onClick={() => setConfirmCancelDialogOpen(true)}>
              Annuler
            </Button>
          )}
        </div>
      </Card>
    </>
  );
};

export default ReducedPriceCard;
