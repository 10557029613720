import {
  useMutationDraftConfirm,
  useMutationDraftCreate,
  useMutationDraftSetOptions,
  useQueryDraftOptions,
} from "@/api/draft/draft";
import { ExamOptionsDto } from "@/api/draft/dto/exam-options.dto";
import { keyFactory } from "@/api/keyFactory";
import { Skeleton } from "@/components/atoms/Skeleton";
import LoadingError from "@/components/molecules/LoadingError";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@atoms/BreadCrumb";
import ConfirmDialog from "@molecules/ConfirmDialog";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import ProgressBar from "./ProgressBar/ProgressBar";
import { RegisterStep, registerStepToPage } from "./register-step.enum";
import { RegisterContext } from "./RegisterContext";

const Register = () => {
  // loading or discarding draft
  const [loadDraftDialogOpen, setLoadDraftDialogOpen] = useState(false);
  const [userWasPrompted, setUserWasPrompted] = useState(false);
  // steps handling
  const [step, setStep] = usePersistent(
    "register-step",
    RegisterStep.CheckInfo
  );
  const [maxStep, setMaxStep] = usePersistent(
    "register-max-step",
    RegisterStep.CheckInfo
  );
  // used to compare new and old states, and check if changes have been made
  // this permits to do fewer api calls
  const [previousRegistrationDraft, setPreviousRegistrationDraft] =
    useState<ExamOptionsDto | null>(null);
  const queryClient = useQueryClient();
  const [draftWasCreated, setDraftWasCreated] = useState(false);
  const navigate = useNavigate();

  //////////////////
  // api calls
  //////////////////

  const getOptionsQuery = useQueryDraftOptions();
  const createResetDraftMutation = useMutationDraftCreate({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keyFactory.draft.options(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.registrations(),
      });
    },
  });
  const setOptionsMutation = useMutationDraftSetOptions({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keyFactory.draft.options(),
      });
    },
  });
  const confirmDraftMutation = useMutationDraftConfirm({
    onSuccess: async () => {
      changeStep(RegisterStep.CheckInfo, true);
      navigate(ROUTE.candidate.dashboard.exams.home());
      queryClient.invalidateQueries({
        queryKey: keyFactory.draft.options(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.registrations(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.canRegister(),
      });

      toast.success("Inscription réussie", {
        description:
          'Vous pouvez consulter le statut de votre inscription depuis l\'onglet "Mes examens"',
      });
    },
    onError: (error) => {
      toast.error("Votre inscription n'a pas pu être validée", {
        description: error.response?.data?.message,
      });
    },
  });

  //////////////////
  // navigation
  //////////////////

  // navigate to change page
  useEffect(() => {
    if (setOptionsMutation.isPending || confirmDraftMutation.isPending) return;
    navigate(registerStepToPage[step]);
  }, [
    step,
    navigate,
    setOptionsMutation.isPending,
    confirmDraftMutation.isPending,
  ]);

  const changeStep = useCallback(
    (newStep: RegisterStep, overrideMaxStep: boolean = false) => {
      if (maxStep < newStep || overrideMaxStep) setMaxStep(newStep);
      setStep(newStep);
    },
    [maxStep, setMaxStep, setStep]
  );

  // create draft or ask user to continue

  const { mutate: createResetDraftMutate } = createResetDraftMutation;
  useEffect(() => {
    if (!getOptionsQuery.isSuccess) return;

    if (getOptionsQuery.data === "no-draft") {
      if (draftWasCreated) return;
      // there is no draft yet
      setDraftWasCreated(true);
      createResetDraftMutate();
      changeStep(RegisterStep.CheckInfo, true);
      setUserWasPrompted(true);
      return;
    } else if (!userWasPrompted) {
      // there is a draft
      setLoadDraftDialogOpen(true);
      setPreviousRegistrationDraft(getOptionsQuery.data);
    }
  }, [
    getOptionsQuery.data,
    getOptionsQuery.isSuccess,
    createResetDraftMutate,
    userWasPrompted,
    changeStep,
    setDraftWasCreated,
    draftWasCreated,
  ]);

  // loading draft mechanism

  const handleLoadDraftResult = (userWantsToLoadDraft: boolean) => {
    if (!userWantsToLoadDraft) {
      if (!draftWasCreated) {
        createResetDraftMutation.mutate();
        setDraftWasCreated(true);
      }
      changeStep(RegisterStep.CheckInfo, true);
    }

    setUserWasPrompted(true);
    setLoadDraftDialogOpen(false);
  };

  // on page change

  const onNextStep = async (goNext: boolean) => {
    if (step === RegisterStep.Acknowledgment && goNext) {
      confirmDraftMutation.mutate();
      return;
    }

    changeStep(step + (goNext ? 1 : -1));
  };

  //////////////////
  // context
  //////////////////

  // minimum api calls to change draft
  const changeDraft = (newOptions: ExamOptionsDto) => {
    const newDraft = {
      ...previousRegistrationDraft,
      ...newOptions,
    };

    if (_.isEqual(newDraft, previousRegistrationDraft)) {
      onNextStep(true);
      return;
    }

    setOptionsMutation.mutate(newOptions, {
      onSuccess: () => {
        onNextStep(true);
      },
      onError: () => {
        toast.error(
          "Une erreur est survenue lors de la modification du brouillon"
        );
      },
    });
  };

  const context: RegisterContext = {
    registrationDraft:
      getOptionsQuery.data === "no-draft" ? {} : getOptionsQuery.data || {},
    changeDraft,
    onNextStep,
    pending: setOptionsMutation.isPending || confirmDraftMutation.isPending,
  };

  //////////////////
  // component
  //////////////////

  if (!getOptionsQuery.data)
    return getOptionsQuery.isLoading ? (
      <div className="pt-8 px-28 pb-2 w-full h-full">
        <Skeleton className="h-full w-full bg-gray-300" />
      </div>
    ) : (
      <LoadingError>Erreur de chargement du brouillon</LoadingError>
    );

  return (
    <>
      <ConfirmDialog
        open={loadDraftDialogOpen}
        onResult={handleLoadDraftResult}
        title="Inscription en cours"
        message="Vous avez déjà une inscription en cours."
        validateStr="Continuer mon inscription"
        cancelStr="Supprimer et recommencer"
      />
      <div className="flex flex-row pt-8 px-28 pb-2  items-start gap-6 self-stretch">
        <div className="flex flex-col items-start gap-1 self-stretch min-w-fit">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage className="text-sm font-medium text-gray-600">
                  <Link to={ROUTE.candidate.dashboard.exams.home()}>
                    Mes examens
                  </Link>
                </BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage className="font-semibold text-sm text-brand-700">
                  S'inscrire à un examen
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h1 className="text-2xl">S'inscrire à un examen</h1>
          <ProgressBar changeStep={changeStep} maxStep={maxStep} step={step} />
        </div>
        <div className="flex items-start gap-6 w-full">
          {getOptionsQuery.isSuccess && !loadDraftDialogOpen && (
            <Outlet context={context} />
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
