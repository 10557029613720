import { Badge } from "@/components/atoms/Badge";
import { formatDate } from "@/lib/utils";

interface ConvocationBadgeProps {
  sentDate?: Date;
}

const ConvocationBadge = ({ sentDate }: ConvocationBadgeProps) => {
  if (sentDate)
    return <Badge variant="green">Envoyée le {formatDate(sentDate)}</Badge>;
  else return <Badge variant="white">Non disponible</Badge>;
};

export default ConvocationBadge;
