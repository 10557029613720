import { CertificateDtoClient } from "@/api/dto/certificate.dto";
import { GreenSwitchArrayValue } from "@/components/atoms/GreenSwitch";
import useChildForm from "@/hooks/ChildForm";
import { Perimeter } from "@/lib/perimeter";
import { formatDate } from "@/lib/utils";
import { FormMessage } from "@atoms/Form";
import { GraduationCap } from "lucide-react";
import { ReactNode } from "react";

interface CertificatesSwitchesProps {
  fieldName: string;
  certificates: CertificateDtoClient[];
  titleElement?: ReactNode;
  unique?: boolean;
}

const CertificatesSwitches = ({
  fieldName,
  certificates,
  titleElement,
  unique,
}: CertificatesSwitchesProps) => {
  const { filteredErrors } = useChildForm(fieldName);

  return (
    <div className="w-full">
      <div className={`flex flex-col w-full gap-2 flex-start self-stretch`}>
        {titleElement}
        {filteredErrors && (
          <p className="text-red-600 text-sm">
            {filteredErrors.message as string}
          </p>
        )}
        <FormMessage />
        {certificates.map((certificate) => (
          <GreenSwitchArrayValue
            key={certificate.file.s3Key}
            name={fieldName}
            value={(certificate.id as number).toString()}
            unique={unique}
            transform={(val) => Number(val)}
          >
            <div className="flex flex-row gap-2">
              <GraduationCap className="w-6 h-6 text-gray-900 stroke-1" />
              <div className="flex flex-row text-gray-700  gap-6 font-bold text-sm leading-6">
                <span>
                  N° de certificat:{" "}
                  <span className="font-medium">
                    {certificate.number.padStart(7, "")}
                  </span>
                </span>
                <span>
                  Fin de validité:{" "}
                  <span className="font-medium">
                    {formatDate(certificate.expirationDate)}
                  </span>
                </span>
                <span>
                  Périmètre:{" "}
                  <span className="font-medium">
                    {Perimeter.toCode(certificate.perimeter)}
                  </span>
                </span>
              </div>
            </div>
          </GreenSwitchArrayValue>
        ))}
      </div>
    </div>
  );
};

export default CertificatesSwitches;
