import { useEffect, useState } from "react";
import { toast } from "sonner";

interface LoadingToastProps {
  message: string;
  loading: boolean;
}

const useLoadingToast = ({ message, loading }: LoadingToastProps) => {
  const [loadingToastId, setLoadingToastId] = useState<string | number | null>(
    null
  );

  // generate toast
  useEffect(() => {
    if (loading === (loadingToastId !== null)) return;

    if (loading) {
      // open
      const id = toast.loading(message, {
        duration: Infinity,
      });
      setLoadingToastId(id);
    } else {
      // close
      if (!loadingToastId) return;
      toast.dismiss(loadingToastId);
      setLoadingToastId(null);
    }
  }, [loading, loadingToastId, message]);
};

export default useLoadingToast;
