import { useMutationAuthChangeEmail } from "@/api/auth/auth";
import { keyFactory } from "@/api/keyFactory";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import ModifiableForm from "@/components/molecules/ModifiableForm";
import { email } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  email: email(),
});

type Schema = z.infer<typeof schema>;

interface ChangeCandidateEmailFormProps {
  userId: number;
  email: string;
}

const ChangeCandidateEmailForm = ({
  userId,
  email,
}: ChangeCandidateEmailFormProps) => {
  const [modifiable, setModifiable] = useState(false);
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutationAuthChangeEmail({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.base(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.registration.base(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.payement.base(),
      });

      toast.success("Email du candidat modifié");
      setModifiable(false);
    },
    onError: (e) => {
      toast.error("Échec de la requête", {
        description:
          e.response?.data?.message ??
          "L'email candidat n'a pas pu être modifié",
      });
    },
  });

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { email },
  });

  const onSubmit = ({ email }: Schema) =>
    mutate({
      userId,
      dto: { newEmail: email },
    });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col w-full gap-8"
      >
        <ModifiableForm
          setModifiable={(b: boolean) => {
            setModifiable(false);
            form.reset();
            setModifiable(b);
          }}
          isModifiable={modifiable}
          onCancel={form.reset}
          modifyText="Modifier"
          saveText="Sauvegarder"
          pending={isPending}
        >
          <div className="flex flex-col gap-8 w-full">
            <h2>Email</h2>
            <FormField
              control={form.control}
              name={"email"}
              render={({ field }) => (
                <FormItem className="w-full relative">
                  <FormControl>
                    <Input type={"email"} {...field} disabled={!modifiable} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </ModifiableForm>
      </form>
    </Form>
  );
};

export default ChangeCandidateEmailForm;
