import { ExamDtoClient } from "@/api/dto/exam.dto";
import ArchiveExamDialog from "@/components/molecules/ArchiveExamDialog";
import ExamStatusBadge from "@/components/molecules/Badges/ExamStatusBadge";
import { formatDate } from "@/lib/utils";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<ExamDtoClient>();

const columns = [
  columnHelper.accessor("number", {
    id: "number",
    header: "N° Session",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("date", {
    id: "date",
    header: "Date examen",
    cell: (info) => formatDate(info.getValue()),
  }),
  columnHelper.accessor((x) => x, {
    id: "status",
    header: "Statut",
    cell: (info) => {
      const { status, registeringEndDate } = info.getValue();
      return (
        <ExamStatusBadge
          status={status}
          registeringEndDate={new Date(registeringEndDate)}
        />
      );
    },
  }),
  columnHelper.accessor((x) => x, {
    id: "archiveButton",
    header: "",
    cell: (info) => {
      const { id, archived } = info.getValue();
      return (
        <div className="flex justify-end">
          <ArchiveExamDialog examId={id} archived={archived} />
        </div>
      );
    },
  }),
];

export { columns };
