import { useQueryExamDetailed } from "@/api/exam/exam";
import { Button } from "@/components/atoms/Button";
import { Skeleton } from "@/components/atoms/Skeleton";
import ExamStatusBadge from "@/components/molecules/Badges/ExamStatusBadge";
import LoadingError from "@/components/molecules/LoadingError";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import { formatDate } from "@/lib/utils";
import { ArrowLeft } from "lucide-react";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { SessionContext } from "./session-context";
import { SessionTab } from "./session-tab.enum";
import SessionButtons from "./SessionButtons/SessionButtons";
import SessionTabs from "./SessionTabs";

const Session = () => {
  const { id: idStr } = useParams();
  const examId = Number(idStr);
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = usePersistent(
    `admin-session-tab-exam-${examId}`,
    SessionTab.Candidates
  );

  const { data: exam, isLoading } = useQueryExamDetailed(examId);

  // navigate to current tab
  useEffect(() => {
    let path;

    switch (tab) {
      case SessionTab.Candidates:
      case SessionTab.Results:
        path = ROUTE.admin.dashboard.session.candidates(examId);
        break;
      case SessionTab.Information:
        path = ROUTE.admin.dashboard.session.information(examId);
        break;
    }

    if (location.pathname !== path) navigate(path, { state: location.state });
  }, [tab, examId, navigate, location]);

  const context: SessionContext | undefined = exam ? { exam, tab } : undefined;

  if (idStr === undefined) navigate(ROUTE.admin.dashboard.sessions.root());

  return (
    <div className="flex flex-col w-full bg-white h-screen">
      <div className="flex flex-row justify-between w-full border-b border-b-gray-200 px-6 gap-2">
        <div className="flex flex-col w-full items-start justify-between">
          <div className="flex flex-row gap-4 items-center m-0 pt-3">
            <Button
              variant="ghostWithBorders"
              className="p-2 rounded-lg"
              onClick={() =>
                navigate(
                  location.state?.from ?? ROUTE.admin.dashboard.sessions.root()
                )
              }
            >
              <ArrowLeft className="w-5 h-5 text-gray-500" />
            </Button>
            {!exam ? (
              isLoading ? (
                <Skeleton className="w-80 h-10" />
              ) : (
                <LoadingError>Erreur de chargement de l'examen</LoadingError>
              )
            ) : (
              <div className="flex flex-col justify-center">
                <div className="flex flex-row gap-2 items-center">
                  <h1>{exam.number}</h1>
                  <ExamStatusBadge
                    registeringEndDate={new Date(exam.registeringEndDate)}
                    status={exam.status}
                  />
                </div>
                <div className="flex flex-row text-gray-600 gap-4 items-center">
                  <span>
                    Clôture inscr. : {formatDate(exam.registeringEndDate)}
                  </span>
                  <span>
                    Clôture att. form. :{" "}
                    {formatDate(exam.formationCertificateEndDate)}
                  </span>
                  <span>Examen : {formatDate(exam.date)}</span>
                </div>
              </div>
            )}
          </div>
          <SessionTabs setTab={setTab} tab={tab} examStatus={exam?.status} />
        </div>
        {exam && <SessionButtons exam={exam} />}
      </div>
      {context && <Outlet context={context} />}
    </div>
  );
};

export default Session;
