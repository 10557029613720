import { YouSignJobData } from "../queues/yousign.types";
import { ExamStatus } from "./exam-status.enum";

export enum QueueEnum {
  ExamResult = "exam-result",
  Certificate = "certificate",
  YouSign = "you-sign",
}

export type QueueData = {
  [QueueEnum.ExamResult]: {
    exam: {
      id: number;
      number: string;
      status: ExamStatus;
    };
  };
  [QueueEnum.Certificate]: {
    exam: {
      id: number;
      number: string;
    };
  };
  [QueueEnum.YouSign]: YouSignJobData;
};
