import { useMutationExamResultUploadEdcResults } from "@/api/exam-result/exam-result";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/atoms/Form";
import DropzoneMolecule from "@/components/molecules/Dropzone";
import ErrorDisplay from "@/components/molecules/ErrorDisplay";
import { emptyFile, file } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { CloudUpload, Plus, Trash2 } from "lucide-react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import { useSessionContext } from "../session-context";

const schema = z.object({
  files: z.array(file(["text/csv"])),
});
type Schema = z.infer<typeof schema>;

const ImportEdcResultsDialog = () => {
  const { exam } = useSessionContext();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);

  const { mutate, isPending } = useMutationExamResultUploadEdcResults();

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { files: [emptyFile()] },
  });

  const onSubmit = ({ files }: Schema) => {
    mutate(
      {
        examId: exam.id,
        files: files as File[],
      },
      {
        onSuccess: () => {
          setErrorMessage("");

          queryClient.invalidateQueries({
            queryKey: keyFactory.exam.base(),
          });

          queryClient.invalidateQueries({
            queryKey: keyFactory.registration.base(),
          });

          toast.success("Import effectué");
          form.reset();
          setOpen(false);
        },
        onError: (err) => {
          setErrorMessage((err.response?.data as any)?.message);
        },
      }
    );
  };

  const { fields, append, remove } = useFieldArray({
    name: "files",
    control: form.control,
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="gap-1 px-3">
          <CloudUpload />
          Importer les résultats
        </Button>
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-4 p-4 max-h-[90%] overflow-auto"
      >
        <DialogHeader>
          <DialogTitle>Importer des résultats d'EDC</DialogTitle>
          <DialogDescription hidden={true}>
            Importer des résultats d'EDC
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            {fields.map((_, i) => (
              <FormField
                key={`files-${i}`}
                name={`files.${i}`}
                control={form.control}
                render={() => (
                  <FormItem className="w-full">
                    <DropzoneMolecule
                      id="file"
                      accept={{
                        "text/csv": [".csv"],
                      }}
                    >
                      <div className="flex flex-row gap-2 items-center">
                        <span>
                          <span className="text-brand-700">Cliquez</span> pour
                          déposer des résultats d'EDC
                        </span>
                        {(i !== 0 || fields.length > 1) && (
                          <button
                            className="text-red-600 hover:text-red-900"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              remove(i);
                            }}
                          >
                            <Trash2 className="w-5 h-5" />
                          </button>
                        )}
                      </div>
                    </DropzoneMolecule>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}

            <Button
              onClick={() => append(emptyFile())}
              className="flex flex-row gap-2 items-center"
            >
              <Plus className="w-5 h-5" />
              <span>Ajouter un fichier</span>
            </Button>

            {errorMessage && <ErrorDisplay msg={errorMessage} />}
            <div className="flex flex-row gap-4">
              <Button
                variant="red"
                className="w-full"
                onClick={() => setOpen(false)}
                type="button"
                disabled={isPending}
              >
                Annuler
              </Button>
              <Button
                variant="green"
                className="w-full"
                type="submit"
                disabled={isPending}
              >
                {isPending ? (
                  <span className="animate-pulse">Import en cours...</span>
                ) : (
                  "Importer"
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ImportEdcResultsDialog;
