// jobs

import { ENDPOINT } from "@/constants/endpoints";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { keyFactory } from "../keyFactory";
import { NestError } from "../nest-error";
import { QueuesJobsDto } from "./dto/queues-jobs.dto";

const getJobs = async () =>
  axios.get<QueuesJobsDto>(ENDPOINT.queues.getJobs()).then((res) => res.data);

export const useQueryQueuesJobs = (
  props?: Partial<UseQueryOptions<QueuesJobsDto, AxiosError<NestError>>>
) =>
  useQuery({
    queryKey: keyFactory.queues.jobs(),
    queryFn: getJobs,
    staleTime: 1000 * 60,
    gcTime: Infinity,
    ...props,
  });
