import { useConstantQuery } from "@/api/constant/constant";
import { ConstantKey } from "@/api/constant/constants";
import { AddressDto } from "@/api/dto/address.dto";
import { ExamType } from "@/api/enums/ExamType";
import { Role } from "@/api/enums/Role";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { keyFactory } from "@/api/keyFactory";
import {
  useMutationPayementPayByCardRedirect,
  useMutationPayementTransferDone,
} from "@/api/payement/payement";
import { Button } from "@/components/atoms/Button";
import { Form } from "@/components/atoms/Form";
import { ROUTE } from "@/constants/routes";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@atoms/Dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogDescription } from "@radix-ui/react-dialog";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Check } from "lucide-react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import AddressForm from "../AddressForm/AddressForm";
import {
  addressDefaultValues,
  mandatoryAddressSchema,
  MandatoryAddressSchema,
} from "../AddressForm/AddressFormSchema";
import AuraInfo from "../AuraLogos/AuraInfo";
import ConfirmDialog from "../ConfirmDialog";
import CandidateDisplay from "./CandidateDisplay";
import TransferInfo from "./TransferInfo";

interface PayingDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  candidateFirstName: string;
  candidateLastName: string;
  registrationId: number;
  examDate: Date;
  examType: ExamType;
  reducedPriceStatus?: ValidationStatus;
  registrationEndDate: Date;
  // is this for a company or a candidate ?
  role: Role;
  // warn candidates with pending reduced price
  // that the price is full
  displayReducedPriceWarning?: boolean;
}

const PayingDialog = ({
  open,
  onOpenChange,
  candidateFirstName,
  candidateLastName,
  examDate,
  reducedPriceStatus,
  registrationEndDate,
  registrationId,
  role,
  examType,
  displayReducedPriceWarning,
}: PayingDialogProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const [billingAddress, setBillingAddress] = useState<AddressDto | null>(null);

  const { data: prices } = useConstantQuery(ConstantKey.Prices);

  const price = useMemo(() => {
    if (!prices) return undefined;

    if (reducedPriceStatus === ValidationStatus.VALIDATED) {
      if (examType === ExamType.RENEW) return prices.renewing.reduced;
      else return prices.initial.reduced;
    } else {
      if (examType === ExamType.RENEW) return prices.renewing.full;
      else return prices.initial.full;
    }
  }, [reducedPriceStatus, examType, prices]);

  // api calls

  const payByCard = useMutationPayementPayByCardRedirect({
    onError: (error) => {
      toast.error("Erreur de chargement du paiement", {
        description: (error.response?.data as any)?.message,
      });
    },
  });

  const onSuccess = async () => {
    toast.success("Virement pris en compte", {
      description: "Le CIFMD vous confirmera bonne réception de celui-ci.",
    });

    queryClient.invalidateQueries({
      queryKey: keyFactory.company.delegations(),
    });

    queryClient.invalidateQueries({
      queryKey: keyFactory.candidate.registrations(),
    });

    onOpenChange(false);
  };

  const onError = (e: AxiosError) => {
    toast.error("Échec de la requête", {
      description:
        (e.response?.data as unknown as any)?.message ??
        "Votre action n'a pas pu être prise en compte.",
    });
  };

  const transferDoneMutation = useMutationPayementTransferDone({
    onSuccess,
    onError,
  });

  // form

  const form = useForm<MandatoryAddressSchema>({
    resolver: zodResolver(mandatoryAddressSchema),
    defaultValues: addressDefaultValues,
  });

  const onSubmit = (
    data: MandatoryAddressSchema,
    payementType: "card" | "transfer"
  ) => {
    setBillingAddress(data);

    if (payementType === "transfer") setConfirmDialogOpen(true);
    else
      payByCard.mutate({
        registrationId,
        successUrl: ROUTE.candidate.dashboard.exams.payementSuccessful(),
        cancelUrl: ROUTE.candidate.dashboard.exams.payementCancelled(),
        address: data,
      });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="max-w-lg max-h-[85%] overflow-auto"
        showCloseButton={!!close}
      >
        <ConfirmDialog
          open={confirmDialogOpen}
          onOpenChange={setConfirmDialogOpen}
          cancelStr="Annuler"
          cancelButtonVariant="ghostWithBorders"
          validateStr="Confirmer"
          onResult={(accepted) => {
            if (accepted)
              transferDoneMutation.mutate({
                registrationId,
                billingAddress: billingAddress ?? undefined,
              });
            setConfirmDialogOpen(false);
          }}
          title="Marquer le virement comme effectué"
        />

        <DialogHeader>
          <DialogTitle>Paiement de l'examen</DialogTitle>
          <DialogDescription>Paiement de l'examen</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((values) => onSubmit(values, "card"))}
            className="flex flex-col gap-4 w-full"
          >
            {displayReducedPriceWarning && (
              <div className="border border-orange-200 p-4 bg-orange-50 rounded-lg flex flex-row gap-4">
                <AuraInfo variant="orange" />
                <span>
                  Votre justificatif de tarif réduit n'est pas validé, vous
                  allez payer le prix sans réduction. Aucun remboursement ne
                  sera possible sur votre paiement.
                </span>
              </div>
            )}

            <CandidateDisplay
              candidateFirstName={candidateFirstName}
              candidateLastName={candidateLastName}
              examType={examType}
              examDate={examDate}
              registrationEndDate={registrationEndDate}
            />
            {role === Role.CANDIDATE && (
              <AddressForm isMandatory={true} title="Adresse de facturation" />
            )}
            <Button
              className="font-medium"
              type="button"
              onClick={() => {
                if (role === Role.CANDIDATE)
                  form.handleSubmit((values) => onSubmit(values, "card"))();
                else if (role === Role.COMPANY)
                  payByCard.mutate({
                    registrationId,
                    successUrl:
                      ROUTE.candidate.dashboard.exams.payementSuccessful(),
                    cancelUrl:
                      ROUTE.candidate.dashboard.exams.payementCancelled(),
                  });
                else throw new Error("Invalid role : " + role);
              }}
              disabled={payByCard.isPending || !price}
            >
              {price
                ? payByCard.isPending
                  ? "Chargement de la page de paiement..."
                  : `Régler ${price} € par carte bancaire`
                : "chargement..."}
            </Button>
            <TransferInfo
              isOpen={isExpanded}
              setOpen={setIsExpanded}
              registrationEndDate={registrationEndDate}
              price={price}
              candidateFirstName={candidateFirstName}
              candidateLastName={candidateLastName}
            />
            {isExpanded && (
              <Button
                variant="ghostWithBorders"
                className="w-full gap-2"
                onClick={() => {
                  if (role === Role.CANDIDATE)
                    form.handleSubmit((values) =>
                      onSubmit(values, "transfer")
                    )();
                  else if (role === Role.COMPANY) setConfirmDialogOpen(true);
                  else throw new Error("Invalid role : " + role);
                }}
                disabled={transferDoneMutation.isPending}
                type="button"
              >
                {transferDoneMutation.isPending ? (
                  "Envoi en cours..."
                ) : (
                  <>
                    <Check className="h-5 w-5" />
                    Marquer le virement comme effectué
                  </>
                )}
              </Button>
            )}
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default PayingDialog;
