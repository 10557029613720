import { ROUTE } from "@/constants/routes";

export enum RegisterStep {
  CheckInfo = 0,
  Register = 1,
  Pricing = 2,
  Payement = 3,
  Certificate = 4,
  Acknowledgment = 5,
}

export const registerStepToPage = {
  [RegisterStep.Acknowledgment]:
    ROUTE.candidate.dashboard.exams.register.acknowledgment(),
  [RegisterStep.Certificate]:
    ROUTE.candidate.dashboard.exams.register.certificate(),
  [RegisterStep.CheckInfo]:
    ROUTE.candidate.dashboard.exams.register.checkInfo(),
  [RegisterStep.Payement]: ROUTE.candidate.dashboard.exams.register.payement(),
  [RegisterStep.Pricing]: ROUTE.candidate.dashboard.exams.register.pricing(),
  [RegisterStep.Register]:
    ROUTE.candidate.dashboard.exams.register.registerStep(),
};
