import { RegistrationType } from "@/api/enums/RegistrationType";
import { User } from "lucide-react";
import RegistrationTypeBadge from "../Badges/RegistrationTypeBadge";
import GrayCard from "./GrayCard";

interface RegistrationNumberCardProps {
  registrationNumber?: string;
  registrationType: RegistrationType;
}

const RegistrationNumberCard = ({
  registrationNumber,
  registrationType,
}: RegistrationNumberCardProps) => (
  <GrayCard>
    <User className="w-4 h-4 text-gray-500" />
    N° inscription : {registrationNumber ?? "Non généré"}
    <RegistrationTypeBadge registrationType={registrationType} />
  </GrayCard>
);

export default RegistrationNumberCard;
