import { ENDPOINT } from "@/constants/endpoints";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { keyFactory } from "../keyFactory";
import { AccountingDto } from "./dto/accounting.dto";
import { ExamExportDto } from "./dto/exam-export.dto";
import { SessionExportDto } from "./dto/session-export.dto";

// exam exports

const getExam = async (params: ExamExportDto) =>
  await axios
    .get<Blob>(ENDPOINT.export.getExam(params), {
      responseType: "blob",
    })
    .then((res) => res.data);

export const useQueryExportExam = (
  params: ExamExportDto,
  props?: Partial<UseQueryOptions<Blob, AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.export.exam(params),
    queryFn: () => getExam(params),
    retry: false,
    enabled: false,
    gcTime: 0,
    staleTime: 0,
    ...props,
  });

// session exports

const getSession = async (params: SessionExportDto) =>
  await axios
    .get<Blob>(ENDPOINT.export.getSession(params), {
      responseType: "blob",
    })
    .then((res) => res.data);

export const useQueryExportSession = (
  params: SessionExportDto,
  props?: Partial<UseQueryOptions<Blob, AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.export.session(params),
    queryFn: () => getSession(params),
    retry: false,
    enabled: false,
    gcTime: 0,
    staleTime: 0,
    ...props,
  });

// accounting

const getAccounting = async (params: AccountingDto) =>
  await axios
    .get<Blob>(ENDPOINT.export.getAccounting(), {
      responseType: "blob",
      params,
    })
    .then((res) => res.data);

export const useQueryExportAccounting = (
  params: AccountingDto,
  props?: Partial<UseQueryOptions<Blob, AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.export.accounting(params),
    queryFn: () => getAccounting(params),
    retry: false,
    enabled: false,
    gcTime: 0,
    staleTime: 0,
    ...props,
  });

// ministry

const getMinistry = async () =>
  await axios
    .get<Blob>(ENDPOINT.export.getMinistry(), {
      responseType: "blob",
    })
    .then((res) => res.data);

export const useQueryExportMinistry = (
  props?: Partial<UseQueryOptions<Blob, AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.export.ministry(),
    queryFn: getMinistry,
    retry: false,
    enabled: false,
    gcTime: 0,
    staleTime: 0,
    ...props,
  });
