import { ExamExport } from "@/api/export/dto/exam-export.enum";
import { GlobalExport } from "@/api/export/dto/global-export.enum";
import { SessionExport } from "@/api/export/dto/session-export.enum";
import { cn } from "@/lib/utils";
import ExportAccountingDialog from "./ExportAccountingDialog";
import ExportExamDialog from "./ExportExamDialog";
import ExportMinistryDialog from "./ExportMinistryDialog";
import SessionExportDialog from "./ExportSessionDialog";

const examExports = [
  {
    title: "Suivi des paiements",
    examExport: ExamExport.Payments,
  },
  {
    title: "Liste des inscrits",
    examExport: ExamExport.Registrations,
  },
  {
    title: "Suivi des pré-requis avant l'examen pour les candidats inscrits",
    examExport: ExamExport.Prerequisites,
  },
  {
    title: "Résultats",
    examExport: ExamExport.Results,
  },
];

const globalExports = [
  {
    title: "Suivi comptable",
    globalExport: GlobalExport.Accounting,
  },
  {
    title: "Données réglementaires à transmettre au Ministère",
    globalExport: GlobalExport.Ministry,
  },
];

const qcmExports = [
  {
    title: "Export des QCMs sous format PDF",
    examExport: ExamExport.QCMsPDF,
  },
  {
    title: "Export des QCMs sous format excel",
    examExport: ExamExport.QCMsExcel,
  },
  {
    title: "Export des réponses des candidats",
    examExport: ExamExport.Answers,
  },
  {
    title: "Export des données de la session en temps réel",
    examExport: ExamExport.Live,
  },
  {
    title: "Export des copies complètes",
    examExport: ExamExport.CopiesComplete,
  },
  {
    title: "Export des copies partielles",
    examExport: ExamExport.CopiesPartial,
  },
];

const sessionExports = [
  {
    title: "Export des questions de la session",
    sessionExport: SessionExport.Questions,
  },
];

const Export = () => {
  return (
    <div className="flex flex-col gap-4 w-full">
      <h2>Exports</h2>
      {[
        { categoryTitle: "Par session", exportsArray: examExports },
        { categoryTitle: "Global", exportsArray: globalExports },
        {
          categoryTitle: "QCM",
          exportsArray: [...sessionExports, ...qcmExports],
        },
      ].map(({ categoryTitle, exportsArray }) => (
        <div
          key={categoryTitle}
          className="flex flex-col rounded-lg border border-gray-200 overflow-auto"
        >
          <div className="px-4 py-3 bg-gray-50">
            <span className="text-gray-500">{categoryTitle}</span>
          </div>
          {exportsArray.map((e, i) => (
            <div
              key={e.title}
              className={cn(
                "px-4 py-3 border-t border-gray-200 flex flex-row justify-between items-center text-gray-900",
                {
                  "bg-gray-50": i % 2,
                }
              )}
            >
              <span>{e.title}</span>
              {"examExport" in e ? (
                <ExportExamDialog examExport={e.examExport} />
              ) : "sessionExport" in e ? (
                <SessionExportDialog sessionExport={e.sessionExport} />
              ) : e.globalExport === GlobalExport.Accounting ? (
                <ExportAccountingDialog />
              ) : e.globalExport === GlobalExport.Ministry ? (
                <ExportMinistryDialog />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Export;
