import {
  useMutationCertificateRemoveProrogation,
  useQueryCertificateAll,
} from "@/api/certificate/certificate";
import { CertificateDtoClient } from "@/api/dto/certificate.dto";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { keyFactory } from "@/api/keyFactory";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import { Skeleton } from "@/components/atoms/Skeleton";
import CertificateStatusBadge from "@/components/molecules/Badges/CertificateStatusBadge";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import FileLink from "@/components/molecules/FileLink";
import LoadingError from "@/components/molecules/LoadingError";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { Perimeter } from "@/lib/perimeter";
import { cn, formatDate } from "@/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Trash2 } from "lucide-react";
import { useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "sonner";
import { CandidateContext } from "../candidate-context";
import AddProrogationDialog from "./AddProrogationDialog";

const columnHelper = createColumnHelper<CertificateDtoClient>();

const Certificates = () => {
  const { candidate } = useOutletContext<CandidateContext>();
  const [certificateIdRemove, setCertificateIdRemove] = useState<null | number>(
    null
  );
  const [certificateAdd, setCertificateIdAdd] = useState<
    undefined | CertificateDtoClient
  >(undefined);
  const queryClient = useQueryClient();

  const certificatesQuery = useQueryCertificateAll(candidate.id);
  const prorogationRemoveMutation = useMutationCertificateRemoveProrogation();

  const columns = useMemo(
    () => [
      columnHelper.accessor("file.createdAt", {
        id: "createdAt",
        header: "Date dépôt",
        cell: (info) => <span>{formatDate(info.getValue() as Date)}</span>,
      }),
      columnHelper.accessor(
        ({ status, expirationDate }) => ({ status, expirationDate }),
        {
          id: "status",
          header: "Statut",
          cell: (info) => {
            const { expirationDate, status } = info.getValue() as {
              expirationDate: Date;
              status: CertificateStatus;
            };
            return (
              <CertificateStatusBadge
                expirationDate={new Date(expirationDate)}
                status={status}
              />
            );
          },
        }
      ),
      columnHelper.accessor("country", {
        id: "country",
        header: "Pays",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor("number", {
        id: "number",
        header: "N° certificat",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor(
        ({ expirationDate, expirationDateWithoutProrogation }) =>
          formatDate(expirationDateWithoutProrogation ?? expirationDate),
        {
          id: "expirationDate",
          header: "Fin de validité",
          cell: (info) => <span>{info.getValue()}</span>,
        }
      ),
      columnHelper.accessor(
        ({ expirationDate, expirationDateWithoutProrogation }) =>
          expirationDateWithoutProrogation ? formatDate(expirationDate) : "-",
        {
          id: "prorogationDate",
          header: "Date de prorogation",
          cell: (info) => <span>{info.getValue()}</span>,
        }
      ),
      columnHelper.accessor((x) => x, {
        id: "prorogation",
        header: "Prorogation",
        cell: (info) => {
          const certificate = info.getValue();

          const {
            id: certificateId,
            prorogationDocument,
            expirationDateWithoutProrogation,
            status,
          } = certificate;

          return (
            <span>
              {expirationDateWithoutProrogation ? (
                <div className="flex flex-row items-center gap-2">
                  <FileLink file={prorogationDocument} showName={false} />
                  <Button
                    variant="red"
                    className="px-2.5 py-1.5"
                    onClick={() => setCertificateIdRemove(certificateId)}
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              ) : status === CertificateStatus.VALIDATED ? (
                <Button onClick={() => setCertificateIdAdd(certificate)}>
                  Ajouter
                </Button>
              ) : (
                "-"
              )}
            </span>
          );
        },
      }),
      columnHelper.accessor("perimeter", {
        id: "perimeter",
        header: "Périmètre",
        cell: (info) => (
          <Badge variant="white">{Perimeter.toCode(info.getValue())}</Badge>
        ),
      }),
      columnHelper.accessor("file", {
        id: "file",
        header: "Certificat",
        cell: (info) => <FileLink file={info.getValue()} showName={false} />,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: certificatesQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!certificatesQuery.data) {
    return (
      <div className="px-6 py-4 h-full">
        {certificatesQuery.isLoading ? (
          <Skeleton className="w-full h-full" />
        ) : (
          <LoadingError>Erreur de chargement des certificats</LoadingError>
        )}
      </div>
    );
  }

  return (
    <div className="px-6 py-4">
      <ConfirmDialog
        open={certificateIdRemove !== null}
        onOpenChange={(open) => {
          if (!open) setCertificateIdRemove(null);
        }}
        title="Supprimer la prorogation"
        validateStr="Supprimer"
        validateButtonVariant="red"
        cancelButtonVariant="ghostWithBorders"
        onResult={(accepted) => {
          if (accepted)
            prorogationRemoveMutation.mutate(certificateIdRemove as number, {
              onSuccess: () => {
                toast.success("Prorogation supprimée");
                queryClient.invalidateQueries({
                  queryKey: keyFactory.certificate.all(candidate.id),
                });
                setCertificateIdRemove(null);
              },
              onError: () => {
                toast.error("Échec", {
                  description: "La prorogation n'a pas pu être supprimée",
                });
              },
            });
          else setCertificateIdRemove(null);
        }}
        validatePending={prorogationRemoveMutation.isPending}
      />
      <AddProrogationDialog
        candidateId={candidate.id}
        close={() => setCertificateIdAdd(undefined)}
        certificate={certificateAdd}
      />
      <div className="rounded-lg overflow-auto border border-gray-200 w-full">
        <Table className="text-gray-600 font-medium text-sm leading-6">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow key={row.id} className={cn({ "bg-gray-50": i % 2 })}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="py-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Certificates;
