import HelloPageLayout from "../templates/hello-page";

const SeedData = () => {
  return (
    <HelloPageLayout className="max-w-3xl">
      <div>
        <h1 className="text-3xl font-extrabold text-center mb-6">
          Données de test
        </h1>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Comptes administrateurs
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {[
              {
                email: "admin0@example.com",
                password: "Password123!",
              },
              {
                email: "admin1@example.com",
                password: "Password123!",
              },
            ].map((admin, index) => (
              <div key={index} className="p-4 rounded-lg bg-blue-50 shadow-md">
                <p>
                  <span className="font-semibold">Email:</span> {admin.email}
                </p>
                <p>
                  <span className="font-semibold">Password:</span>{" "}
                  {admin.password}
                </p>
              </div>
            ))}
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Comptes entreprises
          </h2>
          <span>10 comptes</span>
          <div className="p-4 rounded-lg bg-green-50 shadow-md mt-2">
            <p>
              <span className="font-semibold">Email:</span> company
              <span className="italic font-bold">
                [numéro_entreprise entre]
              </span>
              @example.com
            </p>
            <p>
              <span className="font-semibold">Password:</span> Password123!
            </p>
            <span>
              e.g. <span className="italic">company0@example.com</span>
            </span>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Comptes candidats
          </h2>
          <span>3000 comptes</span>
          <div className="p-4 rounded-lg bg-yellow-50 shadow-md mt-2">
            <p>
              <span className="font-semibold">Email:</span> candidate
              <span className="italic font-bold">[numéro_candidat]</span>
              @example.com
            </p>
            <p>
              <span className="font-semibold">Password:</span> Password123!
            </p>
            <span>
              e.g. <span className="italic">candidat0@example.com</span>
            </span>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Comptes candidats vides
          </h2>
          <span>100 comptes</span>
          <div className="p-4 rounded-lg bg-purple-50 shadow-md mt-2">
            <p>
              <span className="font-semibold">Email:</span> candidateEmpty
              <span className="italic font-bold">[numéro_candidat]</span>
              @example.com
            </p>
            <p>
              <span className="font-semibold">Password:</span> Password123!
            </p>
            <span>
              e.g. <span className="italic">candidatEmpty0@example.com</span>
            </span>
          </div>
        </section>
      </div>
    </HelloPageLayout>
  );
};

export default SeedData;
