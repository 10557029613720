import { CandidateDelegationDtoClient } from "@/api/candidate/dto/candidate-delegation.dto";
import { DelegationStatus } from "@/api/enums/DelegationStatus";
import { Badge } from "@/components/atoms/Badge";
import { format } from "date-fns/format";

interface DelegationStatusBadgeProps {
  delegation: CandidateDelegationDtoClient;
}

const DelegationStatusBadge = ({
  delegation: { status, responseDate },
}: DelegationStatusBadgeProps) => {
  return status === DelegationStatus.ACCEPTED ? (
    <Badge variant="green">
      Acceptée{" "}
      {responseDate && `le ${format(new Date(responseDate), "dd/MM/yyyy")}`}
    </Badge>
  ) : status === DelegationStatus.REFUSED ? (
    <Badge variant="red">
      Refusée{" "}
      {responseDate && `le ${format(new Date(responseDate), "dd/MM/yyyy")}`}
    </Badge>
  ) : status === DelegationStatus.WAITING_ACCEPTATION ? (
    <Badge variant="orange">En attente d'acceptation</Badge>
  ) : status === DelegationStatus.WAITING_ACCOUNT_CREATION ? (
    <Badge variant="sky">En attente de création du compte</Badge>
  ) : (
    "error"
  );
};

export default DelegationStatusBadge;
