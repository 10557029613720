import SideBar from "@/components/molecules/SideBar";
import { ROUTE } from "@/constants/routes";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { cn } from "@/lib/utils";
import {
  Building2,
  Calendar,
  Euro,
  FileText,
  Folder,
  GraduationCap,
  List,
  Play,
  Settings,
  SquareUser,
} from "lucide-react";
import { NavLink, Outlet } from "react-router-dom";
import { Role } from "../../../../api/enums/Role";
import QueuesJobsDialog from "./QueuesJobsDialog/QueuesJobsDialog";

const buttons = [
  {
    logo: <FileText className="w-4 h-4" />,
    text: "Inscriptions",
    route: ROUTE.admin.dashboard.registrations(),
  },
  {
    logo: <Euro className="w-4 h-4" />,
    text: "Paiements",
    route: ROUTE.admin.dashboard.payements(),
  },
  {
    logo: <Calendar className="w-4 h-4" />,
    text: "Sessions",
    route: ROUTE.admin.dashboard.sessions.root(),
  },
  {
    logo: <SquareUser className="w-4 h-4" />,
    text: "Candidats",
    route: ROUTE.admin.dashboard.candidates(),
  },
  {
    logo: <GraduationCap className="w-4 h-4" />,
    text: "Certificats étrangers",
    route: ROUTE.admin.dashboard.foreignCertificates(),
  },
  {
    logo: <Building2 className="w-4 h-4" />,
    text: "Entreprises",
    route: ROUTE.admin.dashboard.companies(),
  },
  {
    logo: <Folder className="w-4 h-4" />,
    text: "Questions",
    route: ROUTE.admin.dashboard.questions(),
  },
  {
    logo: <List className="w-4 h-4" />,
    text: "QCM",
    route: ROUTE.admin.dashboard.qcms(),
  },
  {
    logo: <Play className="w-4 h-4" />,
    text: "Sessions en temps réel",
    route: ROUTE.admin.dashboard.live.sessions(),
  },
  {
    logo: <Settings className="w-4 h-4" />,
    text: "Administration",
    route: ROUTE.admin.dashboard.administration.root(),
  },
];

const SideBarButtons = ({ expanded = false }: { expanded?: boolean }) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-1 self-stretch h-full">
        {buttons
          .filter(({ route }) => !!route)
          .map(({ logo, route, text }) => (
            <NavLink
              key={route}
              to={route}
              className={({ isActive }) =>
                cn(
                  "flex flex-row items-center gap-2.5 rounded-lg py-2 px-4 text-sm font-medium transition-colors duration-200",
                  {
                    "text-brand-700 bg-gray-100 hover:bg-blue-50 hover:text-brand-700":
                      isActive,
                    "text-gray-700 hover:bg-gray-50 hover:text-gray-600":
                      !isActive,
                  }
                )
              }
            >
              {logo}
              {expanded && <span>{text}</span>}
            </NavLink>
          ))}
      </div>
      <QueuesJobsDialog expanded={expanded} />
    </div>
  );
};

const Dashboard = () => {
  useRoleBasedRedirect(Role.ADMIN);

  return (
    <div className="flex flex-row h-screen">
      <SideBar
        expandedChildren={<SideBarButtons expanded />}
        collapsedChildren={<SideBarButtons />}
      />
      <div className="flex-1 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
