import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { CertificateDto, CertificateDtoClient } from "../dto/certificate.dto";
import { PaginationResponseDto } from "../dto/pagination.dto";
import { keyFactory } from "../keyFactory";
import { AddProrogationDto } from "./dto/add-prorogation.dto";
import { ForeignCertificateStatusDto } from "./dto/foreign-certificate-status.dto";
import {
  ForeignCertificateDtoClient,
  ForeignCertificatesPaginationRequestDto,
} from "./dto/foreign-certificates-paginated.dto";

// upload

const upload = async (data: CertificateDto) => {
  await axios.post(ENDPOINT.certificate.postUpload(), data);
};

export const useMutationCertificateUpload = (
  props?: Partial<UseMutationOptions<void, AxiosError, CertificateDto>>
) =>
  useMutation({
    mutationFn: upload,
    ...props,
  });

// all

const getAll = async (candidateId?: number) =>
  axios
    .get<CertificateDtoClient[]>(ENDPOINT.certificate.getAll(candidateId))
    .then((res) => res.data);

export const useQueryCertificateAll = (
  candidateId?: number,
  props?: Partial<UseQueryOptions<CertificateDtoClient[], AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.certificate.all(candidateId),
    queryFn: () => getAll(candidateId),
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });

// add prorogation

const addProrogation = async (data: AddProrogationDto) => {
  await axios.post<void>(ENDPOINT.certificate.postProrogationAdd(), data);
};

export const useMutationCertificateAddProrogation = (
  props?: Partial<UseMutationOptions<void, AxiosError, AddProrogationDto>>
) =>
  useMutation({
    mutationFn: addProrogation,
    ...props,
  });

// remove prorogation

const removeProrogation = async (certificateId: number) => {
  await axios.post<void>(
    ENDPOINT.certificate.postProrogationRemove(certificateId)
  );
};

export const useMutationCertificateRemoveProrogation = (
  props?: Partial<UseMutationOptions<void, AxiosError, number>>
) =>
  useMutation({
    mutationFn: removeProrogation,
    ...props,
  });

// foreign certificates paginated

const getForeignCertificatesPaginated = async (
  params: ForeignCertificatesPaginationRequestDto
) =>
  axios
    .get<PaginationResponseDto<ForeignCertificateDtoClient>>(
      ENDPOINT.certificate.getForeignCertificatesPaginated(),
      {
        params,
      }
    )
    .then((res) => res.data);

export const useQueryCertificateForeignCertificatesPaginated = (
  params: ForeignCertificatesPaginationRequestDto,
  props?: Partial<
    UseQueryOptions<
      PaginationResponseDto<ForeignCertificateDtoClient>,
      AxiosError
    >
  >
) =>
  useQuery({
    queryKey: keyFactory.certificate.foreignCertificatesPaginated(params),
    queryFn: () => getForeignCertificatesPaginated(params),
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });

// foreign certificates set status

const foreignCertificatesSetStatus = async (
  data: ForeignCertificateStatusDto
) => {
  await axios.post<void>(
    ENDPOINT.certificate.postForeignCertificatesSetStatus(),
    data
  );
};

export const useMutationCertificateForeignCertificatesSetStatus = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, ForeignCertificateStatusDto>
  >
) =>
  useMutation({
    mutationFn: foreignCertificatesSetStatus,
    ...props,
  });

// foreign certificates set status

const certificateGenerate = async (examId: number) => {
  await axios.post<void>(ENDPOINT.certificate.postGenerate(), {
    examId,
  });
};

export const useMutationCertificateGenerate = (
  props?: Partial<UseMutationOptions<void, AxiosError, number>>
) =>
  useMutation({
    mutationFn: certificateGenerate,
    ...props,
  });
