import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { DelegationStatus } from "@/api/enums/DelegationStatus";
import { PayementStatus } from "@/api/enums/PayementStatus";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { PayementUtil } from "./payement-util";

export class RegistrationUtil {
  static isCompleted(registration: CandidateRegistrationDtoClient) {
    const { formationCertificatesStatus, reducedPriceStatus, preTestStatus } =
      registration;

    return (
      formationCertificatesStatus === ValidationStatus.VALIDATED &&
      [ValidationStatus.VALIDATED, ValidationStatus.UNAVAILABLE].includes(
        reducedPriceStatus
      ) &&
      preTestStatus === ValidationStatus.VALIDATED &&
      PayementUtil.isPaid(registration.payementStatus)
    );
  }

  static currentDebitNote = (registration: CandidateRegistrationDtoClient) => {
    const {
      delegation,
      debitCompanyPaidFile,
      debitCompanyUnpaidFile,
      debitPersonalPaidFile,
      debitPersonalUnpaidFile,
    } = registration;

    return delegation
      ? PayementUtil.delegationPaid(registration.payementStatus)
        ? debitCompanyPaidFile
        : debitCompanyUnpaidFile
      : PayementUtil.personalPaid(registration.payementStatus)
      ? debitPersonalPaidFile
      : debitPersonalUnpaidFile;
  };

  static candidateCanPay = (registration: CandidateRegistrationDtoClient) => {
    const { delegation, payementStatus } = registration;

    const onGoingDelegation =
      delegation && delegation.status !== DelegationStatus.REFUSED;
    const payementIsWaiting =
      payementStatus === PayementStatus.PERSONAL_WAITING_FOR_VALIDATION;
    const isPaid = PayementUtil.isPaid(payementStatus);

    return !isPaid && !onGoingDelegation && !payementIsWaiting;
  };

  static hasNonRefusedDelegation = (
    registration: CandidateRegistrationDtoClient
  ) => {
    return (
      registration.delegation &&
      registration.delegation.status !== DelegationStatus.REFUSED
    );
  };

  static paidDebitNote = (registration: CandidateRegistrationDtoClient) => {
    return RegistrationUtil.hasNonRefusedDelegation(registration)
      ? registration.debitCompanyPaidFile
      : registration.debitPersonalPaidFile;
  };

  static unpaidDebitNote = (registration: CandidateRegistrationDtoClient) => {
    return RegistrationUtil.hasNonRefusedDelegation(registration)
      ? registration.debitCompanyUnpaidFile
      : registration.debitPersonalUnpaidFile;
  };
}
