import { useMutationAuthChangeEmail } from "@/api/auth/auth";
import {
  useMutationCompanyChangeInfo,
  useQueryCompanyInfo,
} from "@/api/company/company";
import { keyFactory } from "@/api/keyFactory";
import { Form } from "@/components/atoms/Form";
import { Skeleton } from "@/components/atoms/Skeleton";
import { AddressFields } from "@/components/molecules/AddressForm/AddressFields";
import AddressForm from "@/components/molecules/AddressForm/AddressForm";
import { addressDefaultValues } from "@/components/molecules/AddressForm/AddressFormSchema";
import ErrorDisplay from "@/components/molecules/ErrorDisplay";
import ModifiableForm from "@/components/molecules/ModifiableForm";
import { useAuthStore } from "@/hooks/AuthStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { HttpStatusCode } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import LoadingError from "../LoadingError";
import {
  CompanyModification,
  companyModificationSchema,
} from "./company-modification-schema";
import InfoForm from "./InfoForm";

interface CompanyModifyInfoFormProps {
  companyId?: number;
}

const CompanyModifyInfoForm = ({ companyId }: CompanyModifyInfoFormProps) => {
  const queryClient = useQueryClient();
  const [errorMsg, setErrorMsg] = useState("");
  const [infoIsModifiable, setInfoIsModifiable] = useState(false);
  const [addressIsModifiable, setAddressIsModifiable] = useState(false);
  const { session, setNewEmail } = useAuthStore();

  //////////////////////////
  // api calls
  //////////////////////////

  const getInfoQuery = useQueryCompanyInfo(companyId);

  const changeInfoMutation = useMutationCompanyChangeInfo(companyId, {
    onSuccess: async () => {
      setErrorMsg("");

      toast.success("Informations modifiées avec succès");

      queryClient.invalidateQueries({
        queryKey: keyFactory.company.info(companyId),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.company.base(),
      });

      setAddressIsModifiable(false);
      setInfoIsModifiable(false);
    },
    onError: (error) => {
      switch (error.status) {
        case HttpStatusCode.InternalServerError:
          setErrorMsg(
            "Le serveur a rencontré une erreur lors de la modification de vos informations."
          );
          return;

        default:
          setErrorMsg(
            "Une erreur est survenue lors de la modification de vos informations."
          );
          return;
      }
    },
  });

  const changeUserEmailMutation = useMutationAuthChangeEmail({
    onSuccess: () => {
      const { email, ...pi } = watch();
      changeInfoMutation.mutate(pi);
    },
  });

  //////////////////////////
  // form
  //////////////////////////

  const form = useForm<CompanyModification>({
    resolver: zodResolver(companyModificationSchema),
    defaultValues: {
      phoneNumber: "",
      email: "",
      address: addressDefaultValues,
      personName: "",
      personSurname: "",
    },
  });

  // set form values when data is fetched
  const { data: companyInfo } = getInfoQuery;
  const { reset, watch } = form;

  const resetForm = useCallback(() => {
    if (!companyInfo) return;
    reset({
      ...watch(),
      ...companyInfo,
      email: session?.email as string,
    });
  }, [companyInfo, session, reset, watch]);

  useEffect(resetForm, [resetForm]);

  const onSubmit = (data: CompanyModification) => {
    const { email: newEmail, ...newCompanyInfo } = data;

    if (newEmail !== session?.email) {
      changeUserEmailMutation.mutate({ dto: { newEmail } });
      setNewEmail(newEmail);
    } else {
      changeInfoMutation.mutate(newCompanyInfo);
    }
  };

  //////////////////////////
  // component
  //////////////////////////

  if (getInfoQuery.isLoading) return <Skeleton className="w-full h-full m-8" />;
  if (getInfoQuery.isError)
    return (
      <LoadingError className="p-8">
        Erreur du chargement des informations de l'entreprise
      </LoadingError>
    );

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8 p-8"
      >
        <ModifiableForm
          setModifiable={(b: boolean) => {
            if (b && addressIsModifiable) {
              setAddressIsModifiable(false);
              resetForm();
            }
            setInfoIsModifiable(b);
          }}
          isModifiable={infoIsModifiable}
          onCancel={resetForm}
          modifyText="Modifier les informations"
          saveText="Sauvegarder les informations"
        >
          <InfoForm disabled={!infoIsModifiable} />
        </ModifiableForm>
        <ModifiableForm
          setModifiable={(b: boolean) => {
            if (b && infoIsModifiable) {
              setInfoIsModifiable(false);
              resetForm();
            }
            setAddressIsModifiable(b);
          }}
          isModifiable={addressIsModifiable}
          onCancel={resetForm}
          modifyText="Modifier l'adresse de facturation"
          saveText="Sauvegarder l'adresse de facturation"
        >
          <AddressForm
            fieldName="address"
            disabledFields={
              addressIsModifiable ? [] : Object.values(AddressFields)
            }
            title="Adresse facturation"
            isMandatory={true}
          />
        </ModifiableForm>
        {errorMsg && <ErrorDisplay msg={errorMsg} />}
      </form>
    </Form>
  );
};

export default CompanyModifyInfoForm;
