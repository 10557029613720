import { useQueryCompanyAllPaginated } from "@/api/company/company";
import { AdminCompanyDtoClient } from "@/api/company/dto/company-admin.dto";
import { Skeleton } from "@/components/atoms/Skeleton";
import LoadingError from "@/components/molecules/LoadingError";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import useTablePlaceholderRows from "@/hooks/table-placeholder-rows";
import usePersistent from "@/hooks/use-persistent";
import { cn } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const columnHelper = createColumnHelper<AdminCompanyDtoClient>();

interface CompaniesTableProps {
  searchText?: string;
}

const CompaniesTable = ({ searchText }: CompaniesTableProps) => {
  const [pageIndex, setPageIndex] = usePersistent(
    "admin-companies-pageIndex",
    0
  );
  const navigate = useNavigate();

  const { isLoading, data: companies } = useQueryCompanyAllPaginated(
    {
      pageIndex,
      searchText,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        header: "Entreprise",
        cell: (info) => (
          <span className="text-gray-900">{info.getValue()}</span>
        ),
      }),
      columnHelper.accessor(
        ({ personName, personSurname, email }) => ({
          name: `${personName ?? ""} ${personSurname ?? ""}`,
          email,
        }),
        {
          id: "person",
          header: "Responsable",
          cell: (info) => {
            const { email, name } = info.getValue();

            return (
              <div className="flex flex-col">
                <span>{name}</span>
                <span className="text-gray-500 font-normal">{email}</span>
              </div>
            );
          },
        }
      ),
      columnHelper.accessor("phoneNumber", {
        id: "phoneNumber",
        header: "Tél.",
        cell: (info) => <span>{info.getValue() ?? "-"}</span>,
      }),
      columnHelper.accessor("waitingDelegations", {
        id: "waitingDelegationsCount",
        header: "Délégations en attente",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
    ],
    []
  );

  const [rows, is_placeholder] = useTablePlaceholderRows(companies);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!companies) {
    if (isLoading) return <Skeleton className="h-full w-full" />;
    else
      return <LoadingError>Erreur de chargement des entreprises</LoadingError>;
  }

  return (
    <>
      <div className="rounded-lg overflow-auto border border-gray-200">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                  "opacity-0 border-none": is_placeholder(i),
                  "cursor-pointer": !is_placeholder(i),
                })}
                onClick={
                  is_placeholder(i)
                    ? undefined
                    : () =>
                        navigate(
                          ROUTE.admin.dashboard.company(row.original.id),
                          {
                            state: { from: location.pathname },
                          }
                        )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-16 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
        totalPages={companies.totalPages}
      />
    </>
  );
};

export default CompaniesTable;
