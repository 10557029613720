import AddInstituteDialog from "./AddInstituteDialog";
import FormationInstitutesTable from "./FormationInstitutesTable";

const FormationInstitutes = () => {
  return (
    <div className="flex flex-col gap-6 h-full w-full">
      <div className="flex flex-row justify-between">
        <h2>Organismes de formation</h2>
        <AddInstituteDialog />
      </div>
      <FormationInstitutesTable />
    </div>
  );
};

export default FormationInstitutes;
