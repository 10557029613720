import { RegisterStep } from "../register-step.enum";
import StepBox from "./StepBox";

interface ProgressBarProps {
  step: RegisterStep;
  maxStep: RegisterStep;
  changeStep: (step: RegisterStep) => void;
}

const ProgressBar = ({ maxStep, step, changeStep }: ProgressBarProps) => {
  return (
    <div className="flex flex-col justify-start items-start w-full max-w-[300px]">
      {[
        {
          label: "Vérifiez vos informations",
          boxStep: RegisterStep.CheckInfo,
        },
        {
          label: "Inscription à l'examen",
          boxStep: RegisterStep.Register,
        },
        {
          label: "Tarif",
          boxStep: RegisterStep.Pricing,
        },
        {
          label: "Payé",
          boxStep: RegisterStep.Payement,
        },
        {
          label: "Attestation de formation",
          boxStep: RegisterStep.Certificate,
        },
        {
          label: "Acceptation",
          boxStep: RegisterStep.Acknowledgment,
        },
      ].map(({ label, boxStep }) => (
        <StepBox
          key={label}
          label={label}
          step={step}
          maxStep={maxStep}
          boxStep={boxStep}
          changeStep={changeStep}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
