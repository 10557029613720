import { useQueryCertificateForeignCertificatesPaginated } from "@/api/certificate/certificate";
import { ForeignCertificateDtoClient } from "@/api/certificate/dto/foreign-certificates-paginated.dto";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import { Skeleton } from "@/components/atoms/Skeleton";
import CertificateStatusBadge from "@/components/molecules/Badges/CertificateStatusBadge";
import FileLink from "@/components/molecules/FileLink";
import LoadingError from "@/components/molecules/LoadingError";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import useTablePlaceholderRows from "@/hooks/table-placeholder-rows";
import usePersistent from "@/hooks/use-persistent";
import { Perimeter } from "@/lib/perimeter";
import { cn, formatDate } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import ForeignCertificateValidateDialog from "./ForeignCertificateValidateDialog";

interface ForeignCertificatesTableProps {
  tab: CertificateStatus;
  searchText?: string;
}

const columnHelper = createColumnHelper<ForeignCertificateDtoClient>();

const ForeignCertificatesTable = ({
  tab,
  searchText,
}: ForeignCertificatesTableProps) => {
  const [consultDialogCertificate, setConsultDialogCertificate] = useState<
    ForeignCertificateDtoClient | undefined
  >(undefined);
  const [pageIndex, setPageIndex] = usePersistent(
    "foreign-certificates-page-index",
    0
  );

  const { data, isLoading } = useQueryCertificateForeignCertificatesPaginated(
    {
      pageIndex,
      searchText,
      status: tab,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        ({ firstName, lastName }) => `${firstName} ${lastName}`,
        {
          id: "candidate",
          header: "Candidat",
          cell: (info) => (
            <span className="whitespace-nowrap">{info.getValue()}</span>
          ),
        }
      ),
      columnHelper.accessor((x) => x, {
        id: "status",
        header: "Statut",
        cell: (info) => {
          const certificate = info.getValue();
          const { status, expirationDate } = certificate;
          return (
            <div className="flex flex-row items-center justify-between gap-2">
              <CertificateStatusBadge
                expirationDate={new Date(expirationDate)}
                status={status}
                isAdmin={true}
              />
              {status === CertificateStatus.PENDING && (
                <Button
                  onClick={() => setConsultDialogCertificate(certificate)}
                  className="h-fit py-1.5 px-2 rounded-lg"
                >
                  Consulter
                </Button>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("country", {
        id: "country",
        header: "Pays certif.",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor("number", {
        id: "number",
        header: "Numéro",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor("expirationDate", {
        id: "expirationDate",
        header: "Date de validité",
        cell: (info) => <span>{formatDate(info.getValue())}</span>,
      }),
      columnHelper.accessor("perimeter", {
        id: "perimeter",
        header: "Périmètre",
        cell: (info) => (
          <Badge variant="white">{Perimeter.toCode(info.getValue())}</Badge>
        ),
      }),
      columnHelper.accessor("file", {
        id: "download",
        header: "",
        cell: (info) => (
          <FileLink file={info.getValue()} showName={false}>
            Télécharger
          </FileLink>
        ),
      }),
    ],
    []
  );

  const [rows, is_placeholder] = useTablePlaceholderRows(data);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!data) {
    if (isLoading) return <Skeleton className="w-full h-full" />;
    else
      return (
        <LoadingError>
          Erreur de chargement des certificats étrangers
        </LoadingError>
      );
  }

  return (
    <>
      <ForeignCertificateValidateDialog
        close={() => setConsultDialogCertificate(undefined)}
        certificate={consultDialogCertificate}
      />
      <div className="flex flex-col w-full gap-5">
        <div className="rounded-lg overflow-auto border border-gray-200 w-full">
          <Table className="text-gray-600 font-medium text-sm leading-6">
            <TableHeader className="items-center bg-gray-50">
              <TableRow>
                {table.getHeaderGroups()[0].headers.map((header) => (
                  <TableHead
                    key={header.id}
                    style={{ width: `${header.getSize()}px` }}
                  >
                    <div className="flex flex-row items-center gap-3 text-xs">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row, i) => (
                <TableRow
                  key={row.id}
                  className={cn({
                    "bg-gray-50": i % 2,
                    "opacity-0 border-none": is_placeholder(i),
                  })}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="h-14 py-0">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <SmartPagination
          currentPage={pageIndex}
          onPageChange={setPageIndex}
          totalPages={data.totalPages}
        />
      </div>
    </>
  );
};

export default ForeignCertificatesTable;
