import { ENDPOINT } from "@/constants/endpoints";
import { ROUTE } from "@/constants/routes";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { keyFactory } from "../keyFactory";
import { ExamOptionsDto } from "./dto/exam-options.dto";
import { NestError } from "../nest-error";

// create

const createRegistration = async () => {
  axios.post<void>(ENDPOINT.draft.postCreate());
};

export const useMutationDraftCreate = (
  props?: Partial<UseMutationOptions<void, AxiosError, void>>
) =>
  useMutation({
    mutationFn: createRegistration,
    ...props,
  });

// get

const getOptions = async () =>
  axios
    .get<ExamOptionsDto | "no-draft">(ENDPOINT.draft.getOptions())
    .then((res) => res.data);

export const useQueryDraftOptions = () =>
  useQuery({
    queryKey: keyFactory.draft.options(),
    queryFn: getOptions,
    staleTime: Infinity,
    gcTime: Infinity,
  });

// set

const setOptions = async (data: ExamOptionsDto) => {
  await axios.post<void>(ENDPOINT.draft.postOptions(), data);
};

export const useMutationDraftSetOptions = (
  props?: Partial<UseMutationOptions<void, AxiosError, ExamOptionsDto>>
) =>
  useMutation({
    mutationFn: setOptions,
    ...props,
  });

// confirm

const confirmRegistration = async () => {
  await axios.post<void>(ENDPOINT.draft.postConfirm(), {
    companyCreationUrl: ROUTE.company.accountCreation(),
  });
};

export const useMutationDraftConfirm = (
  props?: Partial<UseMutationOptions<void, AxiosError<NestError>, void>>
) =>
  useMutation({
    mutationFn: confirmRegistration,
    ...props,
  });
