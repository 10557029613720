import { ExamType } from "@/api/enums/ExamType";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { ExamDetailedDtoClient } from "@/api/exam/dto/exam-detailed.dto";
import { AdminRegistrationDtoClient } from "@/api/registration-dto/dto/admin-registration.dto";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/Popover";
import ReportDialog from "@/components/molecules/ReportDialog";
import { Ellipsis } from "lucide-react";
import { SessionTab } from "../../../session-tab.enum";
import AdditionalTimeDialog from "./AdditionalTimeDialog";
import ModifyGradeDialog from "./ModifyGradeDialog/ModifyGradeDialog";

interface ActionsPopOverProps {
  exam: ExamDetailedDtoClient;
  registration: AdminRegistrationDtoClient;
  tab: SessionTab;
}

const ActionsPopOver = ({ exam, registration, tab }: ActionsPopOverProps) => {
  const { candidate, id, additionalTimeMinutes } = registration;
  return (
    <>
      <Popover>
        <PopoverTrigger asChild onClick={(e) => e.stopPropagation()}>
          <Ellipsis className="w-5 h-5" />
        </PopoverTrigger>
        <PopoverContent
          className="flex flex-col p-0 w-fit"
          onClick={(e) => e.stopPropagation()}
        >
          {tab === SessionTab.Candidates && (
            <>
              <AdditionalTimeDialog
                registrationId={id}
                candidateName={`${candidate.lastName} ${candidate.firstName}`}
                currentAdditionalTimeMinutes={additionalTimeMinutes}
              />
              <ReportDialog
                candidateId={candidate.id}
                registrationId={id}
                registrationType={
                  // extension and initial registrations use
                  // the same exams
                  exam.type === ExamType.INITIAL
                    ? RegistrationType.INITIAL
                    : RegistrationType.RENEW
                }
                currentExamId={exam.id}
              />
            </>
          )}
          {tab === SessionTab.Results && (
            <ModifyGradeDialog registration={registration} />
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ActionsPopOver;
