import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import classNames from "classnames";
import { Check, Circle, EllipsisVertical } from "lucide-react";
import { useState } from "react";
import { RegisterStep } from "../register-step.enum";

interface StepBoxProps {
  label: string;
  step: RegisterStep;
  maxStep: RegisterStep;
  boxStep: RegisterStep;
  changeStep: (step: RegisterStep) => void;
}

const StepBox = ({
  boxStep,
  maxStep,
  step,
  label,
  changeStep,
}: StepBoxProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={(e) => setOpen(e)}>
      <DialogTrigger asChild>
        <button
          className="flex items-start gap-3 self-stretch hover:text-opacity-50 hover:bg-red"
          disabled={boxStep > maxStep || boxStep === step}
        >
          <div className="flex flex-col items-center justify-center">
            <div className="relative flex items-center justify-center w-7 h-7">
              {boxStep < step ? (
                <Check className="absolute z-10 w-6 h-6 p-1 stroke-white stroke-[3px] bg-brand-600 rounded-full border-0" />
              ) : boxStep <= maxStep ? (
                <>
                  {boxStep === step && (
                    <Circle className="absolute w-8 h-8 bg-brand-200 stroke-brand-200 rounded-full" />
                  )}
                  <Circle className="absolute z-10 w-6 h-6 bg-brand-600 stroke-brand-600 stroke-2 rounded-full" />
                  <Circle className="absolute z-20 w-2 h-2 bg-white stroke-white rounded-full" />
                </>
              ) : (
                <>
                  <Circle className="absolute w-6 h-6 stroke-gray-200 stroke-2" />
                  <Circle className="absolute z-10 w-2 h-2 bg-gray-200 stroke-gray-200 rounded-full" />
                </>
              )}
            </div>

            {boxStep !== RegisterStep.Acknowledgment && (
              <EllipsisVertical
                className={classNames("w-6 h-6 stroke-2", {
                  "stroke-gray-200": boxStep >= maxStep,
                  "stroke-brand-600": boxStep < maxStep,
                })}
              />
            )}
          </div>
          {label}
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Attention</DialogTitle>
          <DialogDescription hidden>Changer de page</DialogDescription>
        </DialogHeader>
        <span>
          Si vous changez de page, les changements que vous avez effectués
          seront perdus.
        </span>
        <div className="flex flex-row items-center gap-4">
          <Button
            variant="ghostWithBorders"
            className="w-1/2"
            onClick={() => setOpen(false)}
          >
            Annuler
          </Button>
          <Button
            variant="red"
            className="w-1/2"
            onClick={() => {
              changeStep(boxStep);
              setOpen(false);
            }}
          >
            Changer de page
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StepBox;
