import {
  useConstantQuery,
  useConstantSetMutation,
} from "@/api/constant/constant";
import {
  constantDefaultValue,
  ConstantKey,
  ConstantType,
  ConstantZodSchema,
} from "@/api/constant/constants";
import { TransportMode } from "@/api/enums/transport-mode.enum";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import { Form } from "@/components/atoms/Form";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import { ComboboxField } from "@/components/molecules/Combobox/Combobox";
import { QCMSizePerimeters } from "@/constants/possible-perimeters.enum";
import usePersistent from "@/hooks/use-persistent";
import { Perimeter } from "@/lib/perimeter";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import QCMParametersTable from "./ParametersTable";

const schema = ConstantZodSchema[ConstantKey.QCMParameters];
type Schema = z.infer<typeof schema>;

const transportModes = [
  [TransportMode.ROAD, TransportMode.TRAIN, TransportMode.BOAT],
  [TransportMode.TRAIN, TransportMode.BOAT],
  [TransportMode.ROAD, TransportMode.BOAT],
  [TransportMode.ROAD, TransportMode.TRAIN],
  [TransportMode.BOAT],
  [TransportMode.TRAIN],
  [TransportMode.ROAD],
];

const sizes = Object.keys(QCMSizePerimeters);

const QCMParameters = () => {
  const [modifiable, setModifiable] = useState(false);
  const queryClient = useQueryClient();
  const [size, setSize] = usePersistent("qcm-parameters-size", sizes[0]);
  const [transportMode, setTransportMode] = usePersistent<TransportMode[]>(
    "qcm-parameters-transportMode",
    transportModes[0]
  );

  const query = useConstantQuery(ConstantKey.QCMParameters);
  const changeMutation = useConstantSetMutation();

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: constantDefaultValue[ConstantKey.QCMParameters],
  });

  const { reset } = form;

  useEffect(() => {
    if (!query.data) return;
    reset(query.data);
  }, [query.data, reset]);

  const onSubmit = (data: Schema) => {
    changeMutation.mutate(
      {
        key: ConstantKey.QCMParameters,
        value: data as ConstantType[typeof ConstantKey.QCMParameters],
      },
      {
        onError: () => {
          toast.error("Échec de la requête", {
            description: "Les paramètres n'ont pas pu être modifiés",
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: keyFactory.constant.byKey(ConstantKey.QCMParameters),
          });
          toast.success("Paramètres modifiés");
          setModifiable(false);
        },
      }
    );
  };

  return (
    <div className="flex flex-col gap-4 h-full w-full">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row gap-3 items-center">
          <h2>QCM</h2>
          <Tabs
            value={size}
            onValueChange={(v) => setSize(v)}
            className="w-full"
          >
            <TabsList className="flex p-0 w-full">
              {sizes.map((size) => (
                <TabsTrigger
                  value={size}
                  className="flex m-0.5 w-full py-1 items-center justify-center"
                  key={size}
                >
                  {size}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
          <ComboboxField
            className="min-w-64"
            value={transportMode}
            onChange={(v) => setTransportMode(v)}
            options={transportModes.map((modes) => ({
              value: modes,
              label: modes.map(Perimeter.transportModeToWord).join(", "),
            }))}
            placeholder="Choisir les modes de transport"
          />
        </div>
        <Button
          onClick={() => {
            if (modifiable) form.reset();
            setModifiable(!modifiable);
          }}
          className="flex w-fit"
          variant="ghostWithBorders"
          disabled={!query.data}
        >
          {!modifiable ? "Modifier" : "Annuler"}
        </Button>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-3"
        >
          <QCMParametersTable
            disabled={!modifiable}
            size={Number(size)}
            transportModes={transportMode}
          />
          {modifiable && (
            <Button type="submit" disabled={changeMutation.isPending}>
              {changeMutation.isPending
                ? "Modification..."
                : "Sauvegarder les modifications"}
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
};

export default QCMParameters;
