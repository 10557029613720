import { Button, buttonVariants } from "@atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@atoms/Dialog";
import { VariantProps } from "class-variance-authority";

interface ConfirmDialogProps {
  open: boolean;
  onResult: (wasConfirmed: boolean) => void;
  title: string;
  message?: string;
  validateStr?: string;
  cancelStr?: string;
  validateButtonVariant?: VariantProps<typeof buttonVariants>["variant"];
  cancelButtonVariant?: VariantProps<typeof buttonVariants>["variant"];
  validatePending?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const ConfirmDialog = ({
  open,
  onResult,
  onOpenChange,
  title,
  message = "",
  validateStr = "Confirmer",
  cancelStr = "Annuler",
  cancelButtonVariant = "ghostWithBorders",
  validateButtonVariant,
  validatePending = false,
}: ConfirmDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-lg" onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{message}</DialogDescription>
        <DialogFooter>
          <Button
            variant={cancelButtonVariant}
            className="w-1/2"
            onClick={() => onResult(false)}
          >
            {cancelStr}
          </Button>
          <Button
            variant={validateButtonVariant}
            className="w-1/2"
            onClick={() => onResult(true)}
            disabled={validatePending}
          >
            {validatePending ? "En cours..." : validateStr}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
