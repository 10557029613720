import { ExamLiveDtoClient } from "@/api/live-exam/dto/exam-live.dto";
import { Button } from "@/components/atoms/Button";
import { Input } from "@/components/atoms/Input";
import { Skeleton } from "@/components/atoms/Skeleton";
import ExamTypeBadge from "@/components/molecules/Badges/ExamTypeBadge";
import { ComboboxField } from "@/components/molecules/Combobox/Combobox";
import { ROUTE } from "@/constants/routes";
import { socket } from "@/hooks/socket";
import usePersistent from "@/hooks/use-persistent";
import { Perimeter } from "@/lib/perimeter";
import { formatDate } from "@/lib/utils";
import { ArrowLeft, Calendar, Link } from "lucide-react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import CandidatesTable from "./CandidatesTable/CandidatesTable";
import CloseExamDialog from "./CloseExamDialog";
import InfoCards from "./InfoCards";

const LiveSession = () => {
  const { examId: examIdStr } = useParams();
  const examId = Number(examIdStr);
  const navigate = useNavigate();
  const [exam, setExam] = useState<ExamLiveDtoClient | undefined>();
  const [searchText, setSearchText] = usePersistent(
    `live-session-${examId}-searchText`,
    ""
  );
  const [possiblePerimeters, setPossiblePerimeters] = useState<string[]>([]);
  const [perimeter, setPerimeter] = useState<Perimeter>();

  // query data
  useEffect(() => {
    socket.emit("getExams", { examId }, (data: ExamLiveDtoClient[]) =>
      setExam(data.find((e) => e.id === examId))
    );

    socket.emit("getPossiblePerimeters", { examId }, (data: string[]) =>
      setPossiblePerimeters(data)
    );
  }, [examId]);

  return (
    <div className="flex flex-col w-full bg-white h-screen">
      <div className="flex flex-row justify-between w-full items-center border-b border-b-gray-200 px-6 py-3">
        <div className="flex flex-row gap-4 items-center w-full">
          <Button
            variant="ghostWithBorders"
            className="p-2 rounded-lg"
            onClick={() => navigate(ROUTE.admin.dashboard.live.sessions())}
          >
            <ArrowLeft className="w-5 h-5 text-gray-500" />
          </Button>
          <div className="flex flex-row items-center justify-between w-full">
            {exam ? (
              <div className="flex flex-row gap-2 items-center">
                <NavLink to={ROUTE.admin.dashboard.session.root(exam.id)}>
                  <h1>{exam.number}</h1>
                </NavLink>
                <ExamTypeBadge examType={exam.type} />
                <div className="flex flex-row text-gray-600 gap-1.5 items-center">
                  <Calendar className="w-4 h-4" />
                  <span>{formatDate(exam.date)}</span>
                </div>
              </div>
            ) : (
              <Skeleton />
            )}
            <div className="flex flex-row items-center gap-3">
              <Input
                className="shadow-none"
                id="shadcn-input"
                searchIcon
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value as string)}
                placeholder="Rechercher"
              />
              <NavLink to={ROUTE.exam.login()} target="_blank">
                <Button variant="ghostWithBorders" className="p-2 rounded-lg">
                  <Link className="w-5 h-5 text-gray-500" />
                </Button>
              </NavLink>
              <CloseExamDialog examId={examId} />
            </div>
          </div>
        </div>
      </div>
      {exam && (
        <div className="flex flex-col gap-4 px-6 py-4">
          <ComboboxField
            options={possiblePerimeters.map((p) => ({ value: p, label: p }))}
            placeholder="Périmètre : Tous"
            onChange={(p) => setPerimeter(p ? Perimeter.from(p) : undefined)}
            optional
            value={perimeter?.toCode() || ""}
          />
          <InfoCards exam={exam} />
          <CandidatesTable
            exam={exam}
            searchText={searchText}
            perimeter={perimeter}
          />
        </div>
      )}
    </div>
  );
};

export default LiveSession;
