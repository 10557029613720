import { X } from "lucide-react";
import { cva } from "class-variance-authority";

interface RedCrossProps {
  color?: "redOnRed" | "whiteOnRed";
}

const redCrossVariants = cva("rounded-full p-0.5 w-5 h-5", {
  variants: {
    color: {
      redOnRed: "text-red-500 bg-red-100",
      whiteOnRed: "text-white bg-red-600",
    },
  },
  defaultVariants: {
    color: "redOnRed",
  },
});

const RedCross: React.FC<RedCrossProps> = ({ color }) => {
  return <X className={redCrossVariants({ color })} />;
};

export default RedCross;
