import { CertificateDto } from "@/api/dto/certificate.dto";
import { formatDate } from "@/lib/utils";
import { GraduationCap } from "lucide-react";

interface CertificateCardProps {
  certificate: CertificateDto;
}

const CertificateCard = ({
  certificate: { number, expirationDate },
}: CertificateCardProps) => {
  return (
    <div className="bg-gray-100 flex flex-row p-4 rounded-md gap-2 items-center">
      <GraduationCap className="w-6 h-6" />
      <span className="font-bold">N° de certificat :</span>
      {number}
      <span className="font-bold ml-4">Fin de validité :</span>
      {formatDate(expirationDate)}
    </div>
  );
};

export default CertificateCard;
