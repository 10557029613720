import { useQueryCandidateRegistrations } from "@/api/candidate/candidate";
import GreenCheck from "@/components/atoms/GreenCheck";
import { Skeleton } from "@/components/atoms/Skeleton";
import LoadingError from "@/components/molecules/LoadingError";
import { useMemo } from "react";
import { MyExamsTab } from "../my-exams-tab.enum";
import RegistrationCard from "./RegistrationCard/RegistrationCard";

interface RegistrationsProps {
  tab: MyExamsTab;
}

const Registrations = ({ tab }: RegistrationsProps) => {
  const { data, isLoading, isError } = useQueryCandidateRegistrations();

  const registrations = useMemo(
    () =>
      data?.filter(({ onGoing }) => onGoing || tab === MyExamsTab.Results) ??
      [],
    [data, tab]
  );

  if (isLoading) return <Skeleton className="w-full h-96 bg-gray-200" />;
  if (isError)
    return <LoadingError>Erreur de chargement des inscriptions</LoadingError>;

  return (
    <div className="flex flex-col w-full gap-6">
      {tab === MyExamsTab.ToCome &&
        (registrations?.length ? (
          <div>
            <span className="flex flex-row gap-2 items-center">
              Votre inscription à l'examen de votre choix ne sera validée que
              lorsque tous les éléments obligatoires requis ci-dessous auront un
              "coche vert"
              <GreenCheck className="inline-flex" />.
            </span>
            <span>
              Assurez-vous de compléter chaque élément dans les délais prévus.
            </span>
          </div>
        ) : (
          <span className="flex flex-row gap-2 items-center">
            Vous n'avez pas d'inscription en cours.
          </span>
        ))}

      <div className="flex flex-col gap-6 pb-80">
        {registrations
          ?.sort(
            ({ exam: { date: date0 } }, { exam: { date: date1 } }) =>
              new Date(date1).getTime() - new Date(date0).getTime()
          )
          .map((registration) => (
            <RegistrationCard
              registration={registration}
              key={`registration-${registration.id}`}
              tab={tab}
            />
          ))}
      </div>
    </div>
  );
};

export default Registrations;
