import logo from "@/assets/logo.png";
import { ReactNode } from "react";
import BackButton from "@molecules/BackButton";
import { cn } from "@/lib/utils";

type PageLayoutProps = {
  children: ReactNode;
  className: string;
  useBackButton?: boolean;
};

const HelloPageLayout = ({
  children,
  className,
  useBackButton = true,
}: PageLayoutProps) => {
  return (
    <div className="flex flex-col items-center mt-6 min-h-screen">
      {useBackButton && <BackButton />}
      <img
        className="flex justify-center items-center mb-8 w-16 h-16"
        src={logo}
        alt=""
      />
      <div
        className={cn(
          "flex flex-col items-start px-10 py-8 rounded-lg border border-gray-200 bg-white shadow-sm gap-8 mb-32",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default HelloPageLayout;
