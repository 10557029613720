import { Input } from "@/components/atoms/Input";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import usePersistent from "@/hooks/use-persistent";
import { useDebounce } from "use-debounce";
import { CandidatesTab } from "./candidates-tab.enum";
import CandidatesTable from "./CandidatesTable/CandidatesTable";

const Candidates = () => {
  const [searchText, setSearchText] = usePersistent<string>(
    "admin-candidates-search-text",
    ""
  );
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [currentTab, setCurrentTab] = usePersistent<CandidatesTab>(
    "candidates-tab",
    CandidatesTab.All
  );

  return (
    <div className="flex flex-col w-full bg-white h-screen">
      <div className="flex flex-col w-full border-b border-b-gray-200 px-6 pt-3 gap-2 bg-white">
        <h1>Candidats</h1>
        <Tabs
          onValueChange={(e) => setCurrentTab(e as CandidatesTab)}
          value={currentTab}
        >
          <TabsList variant="transparent" className="pb-0 mb-0">
            <TabsTrigger variant="underlineBlue" value={CandidatesTab.All}>
              Tous
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={CandidatesTab.Expired}>
              Expiré
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={CandidatesTab.Valid}>
              Valide
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={CandidatesTab.OnGoing}>
              En cours
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="py-4 px-6 flex flex-col gap-3 w-full h-full">
        <Input
          className="shadow-none"
          id="shadcn-input"
          searchIcon
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Rechercher"
        />
        <CandidatesTable tab={currentTab} searchText={debouncedSearchText} />
      </div>
    </div>
  );
};

export default Candidates;
