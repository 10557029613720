import { ExamStatus, ExamStatusUtil } from "@/api/enums/exam-status.enum";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import { SessionTab } from "./session-tab.enum";

interface SessionTabsProps {
  setTab: (tab: SessionTab) => void;
  tab: SessionTab;
  examStatus?: ExamStatus;
}

const SessionTabs = ({ setTab, tab, examStatus }: SessionTabsProps) => (
  <Tabs onValueChange={(e) => setTab(e as SessionTab)} value={tab}>
    <TabsList variant="transparent" className="pb-0 mb-0">
      <TabsTrigger variant="underlineBlue" value={SessionTab.Candidates}>
        Suivi candidats
      </TabsTrigger>
      {examStatus &&
        ExamStatusUtil.order(examStatus) >=
          ExamStatusUtil.order(ExamStatus.CORRECTED) && (
          <TabsTrigger variant="underlineBlue" value={SessionTab.Results}>
            Résultats
          </TabsTrigger>
        )}
      <TabsTrigger variant="underlineBlue" value={SessionTab.Information}>
        Informations session
      </TabsTrigger>
    </TabsList>
  </Tabs>
);

export default SessionTabs;
