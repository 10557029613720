import { useMutationCandidateArchive } from "@/api/candidate/candidate";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { useQueryClient } from "@tanstack/react-query";
import { Archive } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

interface ArchiveCandidateDialogProps {
  candidateId: number;
  firstName: string;
  lastName: string;
  archived: boolean;
}

const ArchiveCandidateDialog = ({
  archived,
  candidateId,
  firstName,
  lastName,
}: ArchiveCandidateDialogProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutationCandidateArchive({
    onSuccess: () => {
      setOpen(false);

      queryClient.invalidateQueries({
        queryKey: keyFactory.exam.base(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.registration.base(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.payement.base(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.base(),
      });

      toast.success("Candidat archivé", {
        description:
          'Vous depuis le désarchiver depuis l\'onglet "Administration"',
      });
    },
    onError: (error) => {
      toast.error("Échec de la requête", {
        description:
          error?.response?.data?.message ??
          "Une erreur est survenue lors de l'archivage du candidat.",
      });
    },
  });

  const archiveText = archived ? "Désarchiver" : "Archiver";

  const text = archived
    ? isPending
      ? "Désarchivage..."
      : "Désarchiver"
    : isPending
    ? "Archivage..."
    : "Archiver";

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          onClick={(e) => e.stopPropagation()}
          variant="ghostWithBorders"
          className="flex flex-row items-center gap-1"
          disabled={isPending}
        >
          <Archive className="w-4 h-4" />
          <span>{text}</span>
        </Button>
      </DialogTrigger>
      <DialogContent
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className="flex flex-col gap-4 p-4"
      >
        <DialogHeader>
          <DialogTitle>
            {archiveText} le candidat {lastName.toUpperCase()} {firstName} ?
          </DialogTitle>
          <DialogDescription hidden>
            {archiveText} le candidat {lastName.toUpperCase()} {firstName} ?
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-row gap-2">
          <Button
            variant="ghostWithBorders"
            className="w-full"
            onClick={() => setOpen(false)}
            disabled={isPending}
          >
            Annuler
          </Button>
          <Button
            className="w-full"
            onClick={() => mutate({ candidateId, archived: !archived })}
            disabled={isPending}
          >
            {text}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ArchiveCandidateDialog;
