import { useQuestionById } from "@/api/question/question";
import { Badge } from "@/components/atoms/Badge";
import { Input } from "@/components/atoms/Input";
import { Skeleton } from "@/components/atoms/Skeleton";
import LoadingError from "@/components/molecules/LoadingError";
import { ROUTE } from "@/constants/routes";
import { ArrowLeft } from "lucide-react";
import { NavLink, useParams } from "react-router-dom";
import ActiveBadge from "../Questions/Badges/ActiveBadge";
import MerchandiseBadge from "../Questions/Badges/MerchandisesBadge";
import TransportModeBadge from "../Questions/Badges/TransportModeBadge";
import { formatDate } from "@/lib/utils";

const Question = () => {
  const { id: idStr } = useParams();
  const questionId = Number(idStr);

  const { data: question, isLoading } = useQuestionById(questionId);

  if (!question) {
    return (
      <div className="p-8 bg-white w-full h-full">
        {isLoading ? (
          <Skeleton className="h-full w-full" />
        ) : (
          <LoadingError>Erreur de chargement de la question</LoadingError>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-white h-full">
      <div className="flex flex-row items-center justify-between py-2 px-6 border-b border-gray-200">
        <div className="flex flex-row gap-4 items-center">
          <NavLink
            className="p-2 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
            to={ROUTE.admin.dashboard.questions()}
          >
            <ArrowLeft className="w-5 h-5 text-gray-500" />
          </NavLink>
          <div className="flex flex-row gap-2">
            <h1>N°{question.number}</h1>
            <TransportModeBadge
              transportModes={question.perimeter.transportModes}
            />
            <MerchandiseBadge merchandises={question.perimeter.merchandises} />
          </div>
        </div>
        <ActiveBadge active={question.active} />
      </div>
      <div className="flex flex-col gap-5 py-4 px-6">
        <div className="flex flex-row gap-3 w">
          <div className="flex flex-col gap-1.5 w-full">
            <span>Ajoutée le</span>
            <Input
              value={formatDate(question.createdAt)}
              disabled
              id="createdAt"
            />
          </div>
          <div className="flex flex-col gap-1.5 w-full">
            <span>Dernière revue</span>
            <Input
              value={
                question.revisionDate ? formatDate(question.revisionDate) : "-"
              }
              id="revisionDate"
              disabled
            />
          </div>
          <div className="flex flex-col gap-1.5 w-full">
            <span>Désactivée le</span>
            <Input
              value={
                question.deactivationDate
                  ? formatDate(question.deactivationDate)
                  : "-"
              }
              id="deactivationDate"
              disabled
            />
          </div>
          <div className="flex flex-col gap-1.5 w-full">
            <span>Activée le</span>
            <Input
              value={
                question.activationDate
                  ? formatDate(question.activationDate)
                  : "-"
              }
              id="activationDate"
              disabled
            />
          </div>
        </div>
        <div className="flex flex-col gap-1.5 w-full">
          <span>Question</span>
          <Input
            value={question.text}
            disabled
            id="questionText"
            className="overflow-auto"
          />
        </div>
        {question.answers
          .sort((a, b) => a.order - b.order)
          .map((answer, i) => (
            <div className="flex flex-col gap-1.5 w-full" key={i}>
              <div className="flex flex-row gap-1.5">
                <span>Réponse {String.fromCharCode(97 + i).toUpperCase()}</span>
                {answer.id === question.rightAnswerId && (
                  <Badge variant="green">Bonne réponse</Badge>
                )}
              </div>
              <Input value={answer.text} disabled id={`answerText${i}`} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Question;
