import { ROUTE } from "@/constants/routes";
import { cn } from "@/lib/utils";
import {
  Calendar,
  Download,
  Euro,
  FileText,
  GraduationCap,
  List,
  LockKeyhole,
  SquareUser,
} from "lucide-react";
import { NavLink, Outlet } from "react-router-dom";

const buttons = [
  {
    logo: <FileText className="w-4 h-4" />,
    text: "Organismes de formation",
    route: ROUTE.admin.dashboard.administration.formationInstitutes(),
  },
  {
    logo: <Euro className="w-4 h-4" />,
    text: "Tarifs",
    route: ROUTE.admin.dashboard.administration.prices(),
  },
  {
    logo: <Calendar className="w-4 h-4" />,
    text: "Sessions archivées",
    route: ROUTE.admin.dashboard.administration.archivedExams(),
  },
  {
    logo: <LockKeyhole className="w-4 h-4" />,
    text: "Administrateurs",
    route: ROUTE.admin.dashboard.administration.admins(),
  },
  {
    logo: <SquareUser className="w-4 h-4" />,
    text: "Candidats archivés",
    route: ROUTE.admin.dashboard.administration.archivedCandidates(),
  },
  {
    logo: <List className="w-4 h-4" />,
    text: "QCM",
    route: ROUTE.admin.dashboard.administration.QCMParameters(),
  },
  {
    logo: <GraduationCap className="w-4 h-4" />,
    text: "Notes d'admission",
    route: ROUTE.admin.dashboard.administration.admissionNotes(),
  },
  {
    logo: <Download className="w-4 h-4" />,
    text: "Exports",
    route: ROUTE.admin.dashboard.administration.exports(),
  },
];

const Administration = () => {
  return (
    <div className="flex flex-col h-full w-full overflow-hidden">
      <div className="flex w-full border-b border-b-gray-200 px-6 py-3 bg-white">
        <h1>Administration</h1>
      </div>
      <div className="flex flex-row h-full w-full">
        <div className="p-4 bg-gray-25 h-full border-r border-r-gray-200 flex-shrink-0">
          {buttons
            .filter(({ route }) => !!route)
            .map(({ logo, route, text }) => (
              <NavLink
                key={route}
                to={route}
                className={({ isActive }) =>
                  cn(
                    "flex flex-row items-center gap-2.5 rounded-lg py-2 px-4 text-sm font-medium transition-colors duration-200",
                    {
                      "text-brand-700 bg-gray-100 hover:bg-blue-50 hover:text-brand-700":
                        isActive,
                      "text-gray-700 hover:bg-gray-50 hover:text-gray-600":
                        !isActive,
                    }
                  )
                }
              >
                {logo}
                <span className="whitespace-nowrap">{text}</span>
              </NavLink>
            ))}
        </div>
        <div className="flex w-full h-full p-6 bg-white overflow-hidden">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Administration;
