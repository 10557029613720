import SexRadio from "@/components/molecules/SexRadio";
import { countries } from "@/constants/countries";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { ComboboxField } from "@/components/molecules/Combobox/Combobox";
import { PhoneInput } from "@molecules/PhoneInput";
import { useFormContext } from "react-hook-form";
import BirthDatePicker from "./BirthDatePicker";
import { formatFieldCapital } from "./Formatters/field-capitals";
import FieldWithOptionality from "./Formatters/FieldWithOptionality";
import { FieldMandatoryType } from "./Formatters/mandatory-type";
import { PersonalInfoField } from "./PersonalInfoFields";
import TextInputField from "./TextInputField";

const formattedOptionList = (options: string[]) => {
  options.sort();
  return options.map((option: string) => ({
    value: option,
    label: option.charAt(0).toUpperCase() + option.slice(1),
  }));
};

interface PersonalInformationFormProps {
  disabledFields?: PersonalInfoField[];
  hiddenFields?: PersonalInfoField[];
  unmodifiableToolTipFields?: PersonalInfoField[];
  title?: string;
}

const PersonalInformationForm = ({
  disabledFields = [],
  hiddenFields = [],
  unmodifiableToolTipFields = [],
  title = "Informations personnelles",
}: PersonalInformationFormProps) => {
  const { control } = useFormContext();

  const rowClasses = "flex flex-row gap-2 w-full mt-1";
  const modifiers = {
    disabledFields,
    hiddenFields,
    unmodifiableToolTipFields,
  };

  return (
    <div className="flex flex-col w-full">
      {title && <h2>{title}</h2>}
      <div className="flex flex-col gap-1">
        {!hiddenFields.includes(PersonalInfoField.Sex) && (
          <div className={rowClasses + " py-2"}>
            <SexRadio
              name="personalInfo.sex"
              disabled={disabledFields.includes(PersonalInfoField.Sex)}
            />
          </div>
        )}
        <div className={rowClasses}>
          <TextInputField
            fieldMandatoryType={FieldMandatoryType.MANDATORY_ON_CERTIFICATE}
            title="Nom"
            type={PersonalInfoField.LastName}
            formattingFunction={formatFieldCapital}
            modifiers={modifiers}
          />
          <TextInputField
            fieldMandatoryType={FieldMandatoryType.MANDATORY_ON_CERTIFICATE}
            title="Prénom"
            type={PersonalInfoField.FirstName}
            formattingFunction={formatFieldCapital}
            modifiers={modifiers}
          />
        </div>
        <div className={rowClasses}>
          <BirthDatePicker modifiers={modifiers} />
          <TextInputField
            fieldMandatoryType={FieldMandatoryType.MANDATORY_ON_CERTIFICATE}
            title="Ville de naissance"
            type={PersonalInfoField.BirthCity}
            formattingFunction={formatFieldCapital}
            modifiers={modifiers}
            placeholder="Constantine"
          />
        </div>
        <div className={rowClasses}>
          {!hiddenFields.includes(PersonalInfoField.BirthCountry) && (
            <FormField
              control={control}
              name="personalInfo.birthCountry"
              render={() => (
                <FormItem className="w-full">
                  <FormLabel>Pays de naissance</FormLabel>
                  <FormControl>
                    <ComboboxField
                      options={formattedOptionList(
                        countries.map((country) => country.libelle)
                      )}
                      placeholder={"-"}
                      optional={true}
                      disabled={disabledFields.includes(
                        PersonalInfoField.BirthCountry
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(PersonalInfoField.Nationality) && (
            <FormField
              control={control}
              name="personalInfo.nationality"
              render={() => (
                <FormItem className="w-full">
                  <FormLabel>
                    <FieldWithOptionality
                      str="Nationalité"
                      fieldMandatoryType={
                        FieldMandatoryType.MANDATORY_ON_CERTIFICATE
                      }
                    />
                  </FormLabel>
                  <FormControl>
                    <ComboboxField
                      options={formattedOptionList(
                        countries.map((country) => country.nationalite)
                      )}
                      placeholder={"-"}
                      disabled={disabledFields.includes(
                        PersonalInfoField.Nationality
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        {(!hiddenFields.includes(PersonalInfoField.Email) ||
          !hiddenFields.includes(PersonalInfoField.EmailConfirmation)) && (
          <div className={rowClasses}>
            <TextInputField
              fieldMandatoryType={FieldMandatoryType.MANDATORY}
              title="Adresse email"
              type={PersonalInfoField.Email}
              modifiers={modifiers}
              placeholder="email@example.fr"
            />
            <TextInputField
              fieldMandatoryType={FieldMandatoryType.MANDATORY}
              title="Confirmation adresse email"
              type={PersonalInfoField.EmailConfirmation}
              modifiers={modifiers}
              placeholder="email@example.fr"
            />
          </div>
        )}
        {(!hiddenFields.includes(PersonalInfoField.Password) ||
          !hiddenFields.includes(PersonalInfoField.PasswordConfirmation)) && (
          <div className={rowClasses}>
            <TextInputField
              fieldMandatoryType={FieldMandatoryType.MANDATORY}
              title="Mot de passe"
              type={PersonalInfoField.Password}
              modifiers={modifiers}
              isPassword
            />
            <TextInputField
              fieldMandatoryType={FieldMandatoryType.MANDATORY}
              title="Confirmation mot de passe"
              type={PersonalInfoField.PasswordConfirmation}
              modifiers={modifiers}
              isPassword
            />
          </div>
        )}
        <div className={rowClasses}>
          {!hiddenFields.includes(PersonalInfoField.HomePhone) && (
            <FormField
              control={control}
              name="personalInfo.homePhone"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Téléphone fixe</FormLabel>
                  <FormControl>
                    <PhoneInput
                      defaultCountry="FR"
                      placeholder={"01 XX XX XX XX"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonalInfoField.HomePhone
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(PersonalInfoField.MobilePhone) && (
            <FormField
              control={control}
              name="personalInfo.mobilePhone"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Mobile</FormLabel>
                  <FormControl>
                    <PhoneInput
                      defaultCountry="FR"
                      placeholder={"06 XX XX XX XX"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonalInfoField.MobilePhone
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalInformationForm;
