import { ExamType } from "@/api/enums/ExamType";
import { ExamResultStatus } from "@/api/enums/exam-result-status.enum";
import { ExamDetailedDtoClient } from "@/api/exam/dto/exam-detailed.dto";
import { AdminRegistrationDtoClient } from "@/api/registration-dto/dto/admin-registration.dto";
import { Badge } from "@/components/atoms/Badge";
import FormationAttestationsStatusBadge from "@/components/molecules/Badges/FormationAttestationsStatusBadge";
import PreTestStatusBadge from "@/components/molecules/Badges/PreTestStatusBadge";
import { Perimeter } from "@/lib/perimeter";
import { formatDate } from "@/lib/utils";
import { createColumnHelper } from "@tanstack/react-table";
import GradeDisplay from "../../../../../../molecules/GradeDisplay";
import { SessionTab } from "../../session-tab.enum";
import ActionsPopOver from "./ActionsPopOver/ActionsPopOver";

const columnHelper = createColumnHelper<AdminRegistrationDtoClient>();

export const generateColumns = (
  exam: ExamDetailedDtoClient,
  tab: SessionTab
) => [
  columnHelper.accessor(
    ({ candidate: { firstName, lastName }, currentDelegation }) => ({
      name: `${firstName} ${lastName}`,
      currentDelegation,
    }),
    {
      id: "candidate",
      header: "Candidat",
      cell: (info) => {
        const { currentDelegation, name } = info.getValue();
        return (
          <div className="flex flex-col h-9 justify-center">
            <span className="text-gray-900 whitespace-nowrap">{name}</span>
            {currentDelegation?.company && (
              <span className="text-gray-500 font-normal whitespace-nowrap">
                {currentDelegation.company.name}
              </span>
            )}
          </div>
        );
      },
    }
  ),
  columnHelper.accessor("registrationNumber", {
    id: "registrationNumber",
    header: "N° insc.",
    cell: (info) => <span>{info.getValue()}</span>,
  }),
  columnHelper.accessor("perimeter", {
    id: "perimeter",
    header: "Pér.",
    cell: (info) => (
      <Badge variant="white">{Perimeter.toCode(info.getValue())}</Badge>
    ),
  }),

  // Candidates tab columns

  ...(tab === SessionTab.Candidates
    ? [
        columnHelper.accessor("additionalTimeMinutes", {
          id: "additionalTimeMinutes",
          header: "Tiers temps",
          cell: (info) => {
            const minutes = info.getValue();
            const hours = Math.floor(minutes / 60);
            const minutesText = minutes % 60;

            if (!minutes) return <></>;

            return (
              <span>
                {hours.toString().padStart(2, "0")}:
                {minutesText.toString().padStart(2, "0")}
              </span>
            );
          },
        }),
        columnHelper.accessor("complete", {
          id: "status",
          header: "Statut inscr.",
          cell: (info) =>
            info.getValue() ? (
              <Badge variant="green">Inscrit</Badge>
            ) : (
              <Badge variant="orange">Pré-inscrit</Badge>
            ),
        }),
        columnHelper.accessor("preTestStatus", {
          id: "preTestStatus",
          header: "Test préalable",
          cell: (info) => (
            <PreTestStatusBadge status={info.getValue()} fromAdmin />
          ),
        }),
        columnHelper.accessor("formationCertificatesStatus", {
          id: "formationCertificatesStatus",
          header: "Attest. de form.",
          cell: (info) => (
            <FormationAttestationsStatusBadge status={info.getValue()} />
          ),
        }),
        columnHelper.accessor(({ candidate }) => candidate?.currentPerimeter, {
          id: "currentPerimeter",
          header: "Pér. actuel",
          cell: (info) => {
            const perimeter = info.getValue();
            if (!perimeter || Perimeter.from(perimeter).isEmpty()) return <></>;

            return <Badge variant="white">{Perimeter.toCode(perimeter)}</Badge>;
          },
        }),
        columnHelper.accessor("convocation", {
          id: "convocation",
          header: "Convocation",
          cell: (info) => {
            const convocation = info.getValue();
            if (!convocation)
              return <Badge variant="white"> Non disponible </Badge>;

            return (
              <Badge variant="green">
                Convocation envoyée{" "}
                {convocation.createdAt && (
                  <>le {formatDate(convocation.createdAt)}</>
                )}
              </Badge>
            );
          },
        }),
      ]
    : []),

  // Results tab columns

  ...(tab === SessionTab.Results
    ? [
        columnHelper.accessor("extensionPerimeter", {
          id: "extendedPerimeter",
          header: "Ext.",
          cell: (info) => {
            const perimeter = info.getValue();
            if (!perimeter) return <></>;

            return <Badge variant="white">{Perimeter.toCode(perimeter)}</Badge>;
          },
        }),

        columnHelper.accessor((x) => x, {
          id: "status",
          header: "État",
          cell: (info) => {
            const { examResult, missing, reportedExamsIds } = info.getValue();

            if (reportedExamsIds && reportedExamsIds.includes(exam.id))
              return <Badge variant="sky">Reporté</Badge>;
            if (
              (!examResult && !missing) ||
              examResult.status === ExamResultStatus.UNAVAILABLE
            )
              return <Badge variant="white">En attente</Badge>;
            if (examResult.status === ExamResultStatus.MISSING)
              return <Badge variant="white">Absent</Badge>;
            if (examResult.status === ExamResultStatus.PASSED)
              return <Badge variant="green">Admis</Badge>;
            else return <Badge variant="red">Ajourné</Badge>;
          },
        }),

        columnHelper.accessor("examResult", {
          id: "qcm",
          header: "QCM",
          cell: (info) => {
            const examResult = info.getValue();
            if (
              !examResult ||
              examResult.status === ExamResultStatus.UNAVAILABLE
            )
              return <Badge variant="white">En attente</Badge>;

            return (
              <GradeDisplay
                grade={examResult.grade}
                gradeTotal={examResult.gradeTotal}
                passed={examResult.status === ExamResultStatus.PASSED}
              />
            );
          },
        }),

        columnHelper.accessor("examResult", {
          id: "qcmAverage",
          header: "Moyenne QCM",
          cell: (info) => {
            const examResult = info.getValue();
            if (
              !examResult ||
              examResult.status === ExamResultStatus.UNAVAILABLE
            )
              return <Badge variant="white">En attente</Badge>;

            return (
              <GradeDisplay
                grade={(
                  (examResult.grade * 20) /
                  examResult.gradeTotal
                ).toFixed(2)}
                gradeTotal={20}
                passed={examResult.status === ExamResultStatus.PASSED}
              />
            );
          },
        }),

        // Initial exam columns

        ...(exam.type === ExamType.INITIAL
          ? [
              columnHelper.accessor("edcResult", {
                id: "edcNote",
                header: "Note finale EDC",
                cell: (info) => {
                  const edcResult = info.getValue();
                  if (!edcResult)
                    return <Badge variant="white">En attente</Badge>;

                  return (
                    <GradeDisplay
                      grade={edcResult.notes.reduce((acc, cur) => acc + cur, 0)}
                      gradeTotal={edcResult.notesTotal.reduce(
                        (acc, cur) => acc + cur,
                        0
                      )}
                      passed={edcResult.status === ExamResultStatus.PASSED}
                    />
                  );
                },
              }),

              columnHelper.accessor("edcResult", {
                id: "specialty1",
                header: "Spécialité 1",
                cell: (info) => {
                  const edcResult = info.getValue();
                  if (!edcResult)
                    return <Badge variant="white">En attente</Badge>;
                  if (edcResult.notes.length < 2) return "-";

                  return (
                    <GradeDisplay
                      grade={edcResult.notes[0]}
                      gradeTotal={edcResult.notesTotal[0]}
                      passed={edcResult.status === ExamResultStatus.PASSED}
                    />
                  );
                },
              }),

              columnHelper.accessor("edcResult", {
                id: "specialty2",
                header: "Spécialité 2",
                cell: (info) => {
                  const edcResult = info.getValue();
                  if (!edcResult)
                    return <Badge variant="white">En attente</Badge>;
                  if (edcResult.notes.length < 2) return "-";

                  return (
                    <GradeDisplay
                      grade={edcResult.notes[1]}
                      gradeTotal={edcResult.notesTotal[1]}
                      passed={edcResult.status === ExamResultStatus.PASSED}
                    />
                  );
                },
              }),
            ]
          : []),
      ]
    : []),

  columnHelper.accessor((x) => x, {
    id: "actions",
    header: "",
    cell: (info) => (
      <ActionsPopOver tab={tab} exam={exam} registration={info.getValue()} />
    ),
    maxSize: 1,
  }),
];
