import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { keyFactory } from "@/api/keyFactory";
import { useMutationPayementTransferStatus } from "@/api/payement/payement";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/atoms/Form";
import DatePicker from "@/components/molecules/DatePicker";
import { date, nullDate } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  date: date(),
});

interface PayementAcknowledgeDialogProps {
  registration: CandidateRegistrationDtoClient;
  candidateId: number;
}

type FormType = z.infer<typeof schema>;

const PayementAcknowledgeDialog = ({
  registration,
  candidateId,
}: PayementAcknowledgeDialogProps) => {
  const [open, setOpen] = useState(false);

  const transferStatusMutation = useMutationPayementTransferStatus();
  const queryClient = useQueryClient();

  const form = useForm<FormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      date: nullDate(),
    },
  });

  const onSubmit = (data: FormType) => {
    transferStatusMutation.mutate(
      {
        registrationId: registration.id,
        accepted: true,
        transferDate: data.date,
      },
      {
        onSuccess: () => {
          toast.success("Virement accepté");

          queryClient.invalidateQueries({
            queryKey: keyFactory.candidate.onGoingRegistration(candidateId),
          });

          queryClient.invalidateQueries({
            queryKey: keyFactory.exam.base(),
          });

          queryClient.invalidateQueries({
            queryKey: keyFactory.registration.base(),
          });

          setOpen(false);
        },
        onError: () => {
          toast.error("Échec de la requête", {
            description: "Votre action n'a pas pu être prise en compte.",
          });
        },
      }
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Accuser réception</Button>
      </DialogTrigger>
      <DialogContent className="w-96 p-4">
        <DialogHeader>
          <DialogTitle>Reconnaissance du virement</DialogTitle>
          <DialogDescription hidden={true}>
            Reconnaissance du virement
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="date"
              render={() => (
                <FormItem className="w-full">
                  <DatePicker placeholder="Date du virement" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-row w-full gap-4">
              <Button
                variant="red"
                onClick={() => setOpen(false)}
                type="button"
                className="w-1/2"
              >
                Annuler
              </Button>
              <Button
                variant="green"
                type="submit"
                className="w-1/2"
                disabled={transferStatusMutation.isPending}
              >
                {transferStatusMutation.isPending ? "En cours..." : "Valider"}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default PayementAcknowledgeDialog;
