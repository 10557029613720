import logo from "@/assets/logo.png";
import SubmitExamDialog from "./SubmitExamDialog";
import ZenDesk from "./ZenDesk";

interface TopBarProps {
  remainingQuestions: number;
}

const TopBar = ({ remainingQuestions }: TopBarProps) => {
  return (
    <div className="flex flex-row items-center justify-between bg-white py-4 px-20">
      <img className="w-11 h-11" src={logo} alt="" />
      <div className="flex flex-row items-center gap-3">
        <SubmitExamDialog remainingQuestions={remainingQuestions} />
        <ZenDesk />
      </div>
    </div>
  );
};

export default TopBar;
