import { ExamStatus, ExamStatusUtil } from "@/api/enums/exam-status.enum";
import { ExamDetailedDtoClient } from "@/api/exam/dto/exam-detailed.dto";
import { Button } from "@/components/atoms/Button";
import ArchiveExamDialog from "@/components/molecules/ArchiveExamDialog";
import { ROUTE } from "@/constants/routes";
import { useNavigate } from "react-router-dom";
import CancelQuestionDialog from "./CancelQuestionDialog";
import GenerateCertificatesDialog from "./GenerateCertificatesDialog";
import GenerateNotesDialog from "./GenerateNotesDialog";

interface SessionButtonsProps {
  exam: ExamDetailedDtoClient;
}

const SessionButtons = ({ exam }: SessionButtonsProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row gap-2 items-center flex-wrap p-2 w-full justify-end">
      {ExamStatusUtil.order(exam.status) >=
        ExamStatusUtil.order(ExamStatus.QCMS_GENERATED) && (
        <Button
          variant="ghostWithBorders"
          className="text-gray-800 font-semibold w-fit"
          onClick={() => navigate(ROUTE.admin.dashboard.live.session(exam.id))}
        >
          Accéder à la session en temps réel
        </Button>
      )}

      {ExamStatusUtil.order(exam.status) >=
        ExamStatusUtil.order(ExamStatus.PASSED) && (
        <CancelQuestionDialog examId={exam.id} />
      )}

      {ExamStatusUtil.order(exam.status) >=
        ExamStatusUtil.order(ExamStatus.PASSED) && (
        <GenerateNotesDialog examId={exam.id} />
      )}

      {ExamStatusUtil.order(exam.status) >=
        ExamStatusUtil.order(ExamStatus.NOTES_GENERATED) && (
        <GenerateCertificatesDialog examId={exam.id} />
      )}

      <ArchiveExamDialog examId={exam.id} archived={exam.archived} />
    </div>
  );
};

export default SessionButtons;
