import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { Input } from "@/components/atoms/Input";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import usePersistent from "@/hooks/use-persistent";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import ForeignCertificatesTable from "./ForeignCertificatesTable";

const ForeignCertificates = () => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [currentTab, setCurrentTab] = usePersistent<CertificateStatus>(
    "foreign-certificates-tab",
    CertificateStatus.PENDING
  );

  return (
    <div className="flex flex-col w-full bg-white h-screen">
      <div className="flex flex-col w-full border-b border-b-gray-200 px-6 pt-3 gap-2 bg-white">
        <h1>Certificats étrangers</h1>
        <Tabs
          onValueChange={(e) => setCurrentTab(e as CertificateStatus)}
          value={currentTab}
        >
          <TabsList variant="transparent" className="pb-0 mb-0">
            <TabsTrigger
              variant="underlineBlue"
              value={CertificateStatus.PENDING}
            >
              En attente de validation
            </TabsTrigger>
            <TabsTrigger
              variant="underlineBlue"
              value={CertificateStatus.VALIDATED}
            >
              Validés
            </TabsTrigger>
            <TabsTrigger
              variant="underlineBlue"
              value={CertificateStatus.REFUSED}
            >
              Refusés
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="py-4 px-6 flex flex-col gap-3 w-full h-full">
        <Input
          className="shadow-none"
          id="shadcn-input"
          searchIcon
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Rechercher"
        />
        <ForeignCertificatesTable
          tab={currentTab}
          searchText={debouncedSearchText}
        />
      </div>
    </div>
  );
};

export default ForeignCertificates;
