import { PerimeterDto } from "@/api/dto/perimeter.dto";
import { MerchandiseClass } from "@/api/enums/merchandise-class.enum";
import { TransportMode } from "@/api/enums/transport-mode.enum";
import { Perimeter } from "@/lib/perimeter";

enum ColumnPerimeter {
  TB = "TB",
  RB = "RB",
  FB = "FB",
  NB = "NB",
  TC = "TC",
  RC = "RC",
  FC = "FC",
  NC = "NC",
  T2 = "T2",
  R2 = "R2",
  F2 = "F2",
  N2 = "N2",
  T1 = "T1",
  R1 = "R1",
  F1 = "F1",
  N1 = "N1",
  T7 = "T7",
  R7 = "R7",
  F7 = "F7",
  N7 = "N7",
  TH = "TH",
  FH = "FH",
  RH = "RH",
  NH = "NH",
}

const codeToTransportModes = {
  ["T"]: Object.values(TransportMode),
  ["N"]: [TransportMode.BOAT],
  ["R"]: [TransportMode.ROAD],
  ["F"]: [TransportMode.TRAIN],
};

const codeToMerchandises = {
  ["1"]: [MerchandiseClass.EXPLOSIVES],
  ["2"]: [MerchandiseClass.GAS],
  ["7"]: [MerchandiseClass.RADIOACTIVE],
  ["C"]: [MerchandiseClass.CHEMICAL],
  ["H"]: [MerchandiseClass.HYDROCARBONS],
  ["B"]: Object.values(MerchandiseClass),
};

class ColumnPerimeterUtil {
  static toPerimeter = (columnPerimeter: ColumnPerimeter): Perimeter => {
    const [c0, c1] = columnPerimeter.split("");

    const transportModes =
      codeToTransportModes[c0 as keyof typeof codeToTransportModes];
    if (!transportModes) throw new Error(`invalid transport modes : ${c0}`);

    const merchandises =
      codeToMerchandises[c1 as keyof typeof codeToMerchandises];
    if (!merchandises) throw new Error(`invalid merchandises : ${c1}`);

    return new Perimeter(transportModes, merchandises);
  };

  static toNormalCode = (columnPerimeter: ColumnPerimeter): string =>
    Perimeter.toCode(ColumnPerimeterUtil.toPerimeter(columnPerimeter));

  static fromPerimeter = (
    perimeter: string | Perimeter | PerimeterDto
  ): ColumnPerimeter => {
    perimeter = Perimeter.from(perimeter).toCode();

    let c0 = "",
      c1 = "";

    if (
      Object.values(TransportMode).every((transportMode) =>
        perimeter.includes(transportMode)
      )
    )
      c0 = "T";
    else
      for (const transportMode of Object.values(TransportMode))
        if (perimeter.includes(transportMode)) {
          c0 = transportMode;
          break;
        }

    if (
      Object.values(MerchandiseClass).every((merchandise) =>
        perimeter.includes(merchandise)
      )
    )
      c1 = "B";
    else
      for (const merchandise of Object.values(MerchandiseClass))
        if (perimeter.includes(merchandise)) {
          c1 = merchandise;
          break;
        }

    return (c0 + c1) as ColumnPerimeter;
  };

  private static score = (p: string): number =>
    ["T", "R", "F", "N"].indexOf(p[0]) +
    ["B", "C", "2", "1", "7", "H"].indexOf(p[1]) * 1000;

  static sorted = () =>
    Object.keys(ColumnPerimeter).sort(
      (a, b) => ColumnPerimeterUtil.score(a) - ColumnPerimeterUtil.score(b)
    );

  static sortQuestions = <T extends { id: number; perimeter: string }>(
    questions: T[]
  ) => {
    const columnPerimeters = ColumnPerimeterUtil.sorted().map((p) =>
      ColumnPerimeterUtil.toNormalCode(p as ColumnPerimeter)
    );

    return questions.sort((a, b) => {
      const scoreDiff =
        columnPerimeters.indexOf(a.perimeter) -
        columnPerimeters.indexOf(b.perimeter);
      return scoreDiff !== 0 ? scoreDiff : a.id - b.id;
    });
  };
}

export { ColumnPerimeter, ColumnPerimeterUtil };
