import { Role } from "@/api/enums/Role";
import { ExamAnswerDto } from "@/api/live-exam/dto/exam-answer.dto";
import {
  ExamDtoClient,
  ExamQuestionStateDtoClient,
} from "@/api/live-exam/dto/exam.dto";
import { Badge } from "@/components/atoms/Badge";
import { ScrollArea } from "@/components/atoms/ScrollArea";
import { Skeleton } from "@/components/atoms/Skeleton";
import { ColumnPerimeterUtil } from "@/constants/column-perimeter.enum";
import { socket } from "@/hooks/socket";
import usePersistent from "@/hooks/use-persistent";
import { useWebSocketData } from "@/hooks/websocket-data";
import { Perimeter } from "@/lib/perimeter";
import { useEffect, useMemo, useRef, useState } from "react";
import ProgressBar from "./ProgressBar";
import QuestionCard from "./QuestionCard";
import { QuestionsTab, tabFilter } from "./questions-tab.enum";
import QuestionTabs from "./QuestionTabs";
import TopBar from "./TopBar/TopBar";

// This component sorts questions by column perimeter,
// then by question id.
//
// This is important : admins
// want to know the order of questions
// the candidate was presented with.

type QuestionsSection = {
  [perimeter: string]: {
    index: number;
    question: ExamQuestionStateDtoClient;
  }[];
};

type Scrolls = Record<string, number>;

const Questions = () => {
  const [exam, setExam] = useState<ExamDtoClient | undefined>(undefined);
  const [perimeter, setPerimeter] = usePersistent<string>(
    "exam-questions-perimeter",
    ""
  );
  const [tab, setTab] = useState<QuestionsTab>(QuestionsTab.All);
  const [waitingQuestions, setWaitingQuestions] = usePersistent<number[]>(
    "exam-waiting-questions",
    []
  );
  // to jump to a question
  const questionRefs = useRef<{ [questionId: number]: HTMLDivElement }>({});
  const { connected } = useWebSocketData();
  const [displayWaiting, setDisplayWaiting] = usePersistent(
    "display-waiting",
    false
  );
  // to keep scroll position between tabs
  const [scrolls, setScrolls] = useState<Scrolls>({});
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);

  // question id -> number
  const questionsOrder = useMemo(() => {
    if (!exam) return undefined;
    const ordered = ColumnPerimeterUtil.sortQuestions(
      exam.map(({ question }) => ({
        id: question.id,
        perimeter: Perimeter.toCode(question.perimeter),
      }))
    );

    const d: Record<number, number> = {};
    for (let i = 0; i < ordered.length; i++) d[ordered[i].id] = i;

    return d;
  }, [exam]);

  // reconnect if necessary
  useEffect(() => {
    if (!connected) {
      socket.io.opts.query = { role: Role.CANDIDATE };
      socket.connect();
    }
  }, [connected]);

  // query exam
  useEffect(() => {
    socket.emit("getExam", {}, (data: ExamDtoClient) => {
      setExam(data);
    });
  }, []);

  // compute displayed sections
  const questions = useMemo(
    () =>
      (exam ?? []).filter(({ question }) => {
        if (displayWaiting) return waitingQuestions.includes(question.id);
        else return Perimeter.toCode(question.perimeter) === perimeter;
      }),
    [exam, perimeter, displayWaiting, waitingQuestions]
  );

  const sections: QuestionsSection = useMemo(() => {
    const d: QuestionsSection = {};

    for (const question of questions) {
      const perimeter = Perimeter.toCode(question.question.perimeter);
      if (!d[perimeter]) d[perimeter] = [];
      d[perimeter].push({
        index: questionsOrder?.[question.question.id] ?? 0,
        question,
      });
    }

    return d;
  }, [questions, questionsOrder]);

  const jumpToQuestion = (questionId: number) => {
    // find question
    const question = questions.find(
      ({ question }) => question.id === questionId
    );

    if (!question) return;

    // change tab if the question is not visible
    for (const newTab of [tab, ...Object.values(QuestionsTab)]) {
      if (!tabFilter(question, newTab)) continue;
      setTab(newTab);
      break;
    }

    questionRefs.current[questionId]?.scrollIntoView({ behavior: "smooth" });
  };

  const onAnswerAcknowledged = (data: ExamAnswerDto) =>
    setExam((prev) =>
      prev?.map((question) => {
        if (question.question.id === data.questionId)
          question.answerOrder = data.answerOrder;

        return question;
      })
    );

  const setWaiting = (questionId: number, waiting: boolean) => {
    if (waiting) setWaitingQuestions([...waitingQuestions, questionId]);
    else
      setWaitingQuestions(waitingQuestions.filter((qId) => qId !== questionId));
  };

  const remainingQuestions = useMemo(
    () =>
      exam?.filter(({ answerOrder }) => answerOrder === undefined).length ?? 0,
    [exam]
  );

  if (!connected)
    return (
      <div className="relative h-screen w-full p-8 bg-white">
        <Skeleton className="w-full h-full" />
        <span className="italic absolute top-1/2 right-1/2 text-gray-500">
          Connexion au serveur live...
        </span>
      </div>
    );

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <TopBar remainingQuestions={remainingQuestions} />
      <div className="flex flex-row gap-4 px-24 py-8 flex-grow h-0">
        <ProgressBar
          exam={exam ?? []}
          jumpToQuestion={jumpToQuestion}
          perimeter={perimeter}
          setPerimeter={(p) => {
            setPerimeter(p);
            scrollAreaRef.current?.scrollTo({
              top: scrolls[p] ?? 0,
              behavior: "instant",
            });
          }}
          displayWaiting={displayWaiting}
          setDisplayWaiting={setDisplayWaiting}
          questionsOrder={questionsOrder ?? {}}
        />
        <div className="flex flex-col gap-7 flex-grow">
          {!displayWaiting && (
            <QuestionTabs questions={questions} tab={tab} setTab={setTab} />
          )}

          <ScrollArea
            ref={scrollAreaRef}
            onScroll={(e) =>
              setScrolls((prev) => ({
                ...prev,
                [perimeter]: (e.target as HTMLElement).scrollTop,
              }))
            }
          >
            <div className="flex flex-col gap-6">
              {Object.entries(sections).map(([perimeter, sectionQuestions]) => (
                <div className="flex flex-col gap-6" key={perimeter}>
                  <div className="flex flex-row items-center gap-2 sticky top-0 bg-gray-50 z-50 border-b border-b-gray-200 py-1">
                    <h2>{Perimeter.toExamText(perimeter)}</h2>
                    <Badge variant="white">
                      {sectionQuestions.length} questions
                    </Badge>
                  </div>
                  {sectionQuestions
                    .filter(({ question }) => tabFilter(question, tab))
                    // Sort by id.
                    .sort((a, b) => a.index - b.index)
                    .map(({ question, index }) => (
                      <QuestionCard
                        name={`Question ${index + 1}`}
                        key={`${perimeter}-${index}`}
                        ref={(el) => {
                          if (el)
                            questionRefs.current[question.question.id] = el;
                        }}
                        question={question}
                        waitingQuestions={waitingQuestions}
                        setWaiting={setWaiting}
                        onAnswerAcknowledged={onAnswerAcknowledged}
                      />
                    ))}
                </div>
              ))}
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};

export default Questions;
