const CGU = () => {
  return (
    <>
      Date de mise à jour : 13 juillet 2023
      <br />
      <br />
      Le CIFMD est une Association française Loi 1901, à but non-lucratif, non
      assujettie à la TVA, qui exploite le site web{" "}
      <a className="text-blue-500" href="https://cifmd.org/" target="_newPage">
        www.cifmd.org
      </a>{" "}
      où elle propose, à titre principal, une inscription et une gestion en
      ligne de l’examen de Conseiller à la Sécurité pour le Transport des
      Marchandises dangereuses (CSTMD), conformément aux dispositions prévues
      par l’Arrêté du 6 février 2019 en vigueur.
      <br />
      <br />
      <span className="text-lg font-semibold">Article 1 : Objet</span>
      <br />
      <br />
      Les présentes constituent, à ce jour, les conditions générales
      d’utilisation (CGU) pour l’inscription en ligne aux examens initiaux ou de
      renouvellement, la création d’un compte via le site internet du CIFMD et
      les conditions de passage de l’examen par voie électronique (en ligne).
      <br />
      <br />
      Pour être autorisé à s’inscrire et payer sur le site internet du CIFMD, le
      candidat doit, au préalable, avoir pris connaissance et avoir accepté les
      présentes conditions générales d’utilisation ainsi que notre politique de
      confidentialité des données à caractère personnel.
      <br />
      <br />
      Le CIFMD se réserve la faculté de modifier à tout moment tout ou partie
      des présentes conditions générales d’utilisation. En aucun cas, une
      réclamation ne pourra être effectuée sur la base d’une méconnaissance ou
      d’un refus de ces modalités.
      <br />
      <br />
      Toute question ou demande adressée au CIFMD se fera exclusivement par mail
      à l’adresse : contact@cifmd.fr.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 2 : Accès et utilisation de la plate-forme d’inscription
      </span>
      <br />
      <br />
      Le CIFMD met à disposition des candidats à l’examen une
      <a
        className="text-blue-500"
        href="https://cifmd-inscription.fr/"
        target="_newPage"
      >
        plate-forme d’inscription web
      </a>
      , lui appartenant, accessible uniquement depuis le site internet
      www.cifmd.org. Cette plate-forme permet au candidat de créer un compte
      personnel candidat, ou d’y accéder s’il en possède déjà un, et de
      s’inscrire à l’examen de son choix.
      <br />
      <br /> Le CIFMD offre aux candidats préalablement inscrits, un « service »
      en ligne, constitué d’un certain nombre de fonctionnalités dédiées à la
      gestion de leur compte candidat personnel : Le paiement en ligne des frais
      d’inscription et mise à disposition d’une note de débit La consultation et
      modification, sous certaines conditions, de l’inscription et des données
      personnelles La mise à disposition de l’attestation de présence, du
      dernier relevé de notes et du certificat électronique authentifié en cours
      de validité, délivré en cas de réussite à l’examen.
      <br />
      <br /> Les fonctionnalités proposées sur le site nécessitent un accès
      internet et du matériel compatible. Le candidat est seul responsable du
      bon fonctionnement de son accès internet et de son équipement
      informatique. Il est informé et accepte que les mises à jour et
      maintenances régulières du site puissent impacter les performances des
      services proposés, sans que cela puisse être de nature à engager la
      responsabilité du CIFMD.
      <br />
      <br />
      Par ailleurs, le CIFMD ne peut être tenu responsable des éventuels
      dysfonctionnements du réseau ou des serveurs ou de tout autre évènement
      comme, par exemple, des défaillances résultant d’une mise à jour des
      serveurs ou de tout autre incident temporaire qui ne serait pas de son
      fait.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 3 : Contenu des examens en ligne et informations sur les examens
      </span>
      <br />
      <br />
      Les informations concernant le périmètre du certificat de Conseiller à la
      Sécurité pour le Transport des Marchandises Dangereuses, la structuration
      des examens de initiaux et de renouvellement, les conditions d’admission
      et les tarifs en vigueur sont disponibles sur le site internet du CIFMD.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 4 : Conditions pour le passage des examens en ligne
      </span>
      <br />
      <br /> Tout candidat inscrit à un examen initial ou de renouvellement doit
      satisfaire deux conditions préalables pour pouvoir accéder à sa session
      d’examen : Avoir transmis son attestation de formation, couvrant le
      périmètre de son certificat Avoir réalisé le test préalable obligatoire,
      permettant de vérifier les conditions techniques et d’infrastructure du
      candidat et d’en valider la conformité
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 5 : Clauses spécifiques du CIFMD pour les examens en ligne
      </span>
      <br />
      <br /> Des clauses spécifiques sont à respecter par le candidat pour le
      bon déroulement de son examen en ligne et la conformité de sa
      surveillance. Avant d’accéder à son épreuve, le candidat doit lire et
      accepter ces clauses. Elles sont décrites dans le document suivant :{" "}
      <a
        className="text-blue-500"
        href="https://cifmd.org/wp-content/uploads/2022/06/2022-06_Clauses_Examen_V2.pdf"
        target="_newPage"
      >
        Clauses spécifiques du CIFMD.
      </a>
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 6 : Création et utilisation du compte personnel candidat
      </span>
      <br />
      <br /> Le candidat est seul responsable de la création de son “compte
      candidat” sur le site internet du CIFMD et des conséquences en cas
      d’erreur sur les données renseignées. L’accès au compte candidat est
      personnel et privé car il comporte toutes les données d’identification
      personnelle et les documents relatifs à l’examen CSTMD, y compris le
      certificat authentifié de CSTMD. Il permet également de procéder aux
      modifications éventuelles du compte et de payer les frais d’inscription en
      ligne via une plate-forme bancaire sécurisée.
      <br />
      <br />
      Le CIFMD ne pourrait être tenu pour responsable des erreurs d’utilisation
      de ce service. En effet, conformément à l’article 3 de l’arrêté du 6
      février 2019 en vigueur, une attestation de formation (ou plusieurs, selon
      les cas) doit être transmise par le candidat au CIFMD, avant l’examen,
      pour pouvoir y être accepté. La plate-forme d’inscription permet d’ajouter
      ce type de document jusqu’à 7 jours avant la date de la session d’examen
      pour laquelle le candidat s’est inscrit. Le CIFMD s’assure de la
      recevabilité de ce document.
      <br />
      <br />
      Le candidat prend intégralement connaissance, accepte et met en œuvre sans
      réserves les{" "}
      <a
        className="text-blue-500"
        href="https://cifmd.org/wp-content/uploads/2023/07/2023-07_Modalites_examen_CIFMD.pdf"
        target="_newPage"
      >
        « Modalités de préparation, de connexion, de déroulement des épreuves et
        dispositifs de lutte contre la fraude »
      </a>
      , publiées sur le site internet du CIFMD, conformément à l’Avis Officiel
      du 14 décembre 2021 (NOR : TREP2139314V ; non publié au J.O.). La
      convocation finale à l’examen en ligne est envoyée uniquement aux
      candidats qui auront satisfait ces deux conditions.
      <br />
      <br /> En cas d’attestation incomplète ou absente, l’inscription du
      candidat est reportée gracieusement à la session d’examen suivante. Un
      seul report est possible.
      <br />
      <br />
      En cas d’impossibilité de réaliser le test pendant la période définie,
      publiée sur le site internet du CIFMD, le candidat doit en informer le
      CIFMD dans les plus brefs délais et en justifier la raison. Toute absence
      au test non justifiée entraîne une annulation de l’inscription, sans
      remboursement possible.
      <br />
      <br />
      En cas d’échec lié à des conditions matérielles ou d’infrastructure du
      candidat inadaptées et non validées par le CIFMD et son prestataire
      MANAGEXAM pendant la phase de test, non résolues à temps, l’inscription du
      candidat est reportée gracieusement à la session d’examen suivante.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 7 : Modification de l’inscription
      </span>
      <br />
      <br />
      Le candidat peut modifier son inscription (périmètre de son certificat
      et/ou date de sa session d’examen), par le biais de son compte candidat,
      au plus tard jusqu’à la date de clôture des inscriptions de la session
      initialement choisie. La date de clôture de chaque session d’examen est
      disponible sur le site internet du CIFMD et communiquée sur la plate-forme
      d’inscription. Après cette date de clôture, aucune modification n’est plus
      acceptée et il n’existe aucune dérogation possible, ni auprès du CIFMD, ni
      auprès de l’Administration.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 8 : Cas de force majeure du candidat
      </span>
      <br />
      <br /> Les cas de force majeure sont ceux qui empêchent le candidat de
      réaliser son examen de CSTMD en ligne le jour prévu et qui ne pouvaient
      pas être anticipés. A titre d’exemple, on peut noter l’hospitalisation ou
      une maladie entraînant un arrêt, le jour de l’examen, ou d’autres raisons
      qu’il convient de dûment justifier.
      <br />
      <br />
      Le candidat absent à un examen pour des raisons de force majeure, doit
      faire la demande de report par mail au CIFMD à l’adresse :
      contact@cifmd.fr, au plus tard 15 jours après l’examen. Sa demande doit
      être motivée et accompagnée des justificatifs correspondants.
      <br />
      <br />
      Après vérification de l’absence du candidat demandeur à l’examen et sur la
      base des éléments fournis, le CIFMD propose le report gracieux de son
      inscription à la session d’examen suivante, ou refuse si les
      justifications ne sont pas suffisantes ni valables, notamment au regard
      des dispositions des présentes CGU. Le CIFMD informe par mail le candidat
      de la suite donnée à sa demande de report pour cas de force majeure.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 9 : Cas de force majeure du cifmd
      </span>
      <br />
      <br />
      La responsabilité du CIFMD ne pourra pas être mise en œuvre si la
      non-exécution ou le retard dans l’exécution de l’une de ses obligations
      décrites dans les présentes conditions générales de vente découle d’un cas
      de force majeure. À ce titre, la force majeure s’entend de tout événement
      extérieur, imprévisible et irrésistible au sens de l’article 1148 du Code
      civil.
      <br />
      <br /> En cas de force majeure empêchant la tenue d’une session d’examen,
      le CIFMD procéderait à un report pur et simple de tous les candidats sur
      une nouvelle session programmée dans les meilleurs délais, sans versement
      d’aucun frais de dommages et intérêts.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 10 : Protection du site
      </span>
      <br />
      <br /> Toute reproduction intégrale ou partielle du site et de nos
      services, par quelque procédé que ce soit, faite sans le consentement
      préalable et écrit, est illicite et constitue une contrefaçon donnant lieu
      à des sanctions pénales.
      <br />
      <br />
      En accédant au présent site et aux fonctions liées, le candidat reconnaît
      que les données le concernant sont légalement protégées. Conformément aux
      dispositions de la loi n°98-536 du 1er juillet 1998, le candidat
      s’interdit d’extraire, réutiliser, stocker, reproduire, représenter ou
      conserver, directement ou indirectement, sur un support quelconque, par
      tout moyen et sous toute forme que ce soit, tout ou partie qualitativement
      ou quantitativement substantielle du site auquel il a accès, ainsi que
      d’en faire l’extraction ou la réutilisation répétée et systématique de
      parties qualitativement et quantitativement non substantielles lorsque ces
      opérations excèdent manifestement les conditions d’utilisation normales.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 11 : Modification des services
      </span>
      <br />
      <br /> Le CIFMD se réserve le droit d’apporter à ses services toutes les
      modifications et améliorations qu’il jugera nécessaires ou utiles, à tout
      moment. Par ailleurs, le CIFMD se réserve le droit, sans préavis ni
      indemnité, d’arrêter définitivement un service ou l’ensemble des services
      qu’il propose sur le site ou d’en fermer temporairement l’accès.
    </>
  );
};

export default CGU;
