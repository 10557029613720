import { ExamLiveCandidateDtoClient } from "@/api/live-exam/dto/exam-live-candidate.dto";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { socket } from "@/hooks/socket";
import { X } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

interface CloseCandidateExamDialogProps {
  candidate: ExamLiveCandidateDtoClient;
}

export const CloseCandidateExamDialog = ({
  candidate: {
    candidate: { firstName, lastName },
    registration,
  },
}: CloseCandidateExamDialogProps) => {
  const [open, setOpen] = useState(false);
  const [pending, setPending] = useState(false);

  const onAcknowledge = (success: boolean) => {
    setPending(false);
    if (success) {
      setOpen(false);
      toast.success("Session d'examen fermée.", {
        description: `${lastName} ${firstName} ne peut plus accéder à son examen.`,
      });
    } else
      toast.error("Erreur lors de la fermeture de l'examen.", {
        description: `${lastName} ${firstName} peut toujours accéder à son examen.`,
      });
  };

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      <DialogTrigger
        className="flex flex-row gap-2 items-center p-2 hover:bg-gray-50 transition-colors rounded-sm"
        onClick={(e) => e.stopPropagation()}
      >
        <X className="w-4 h-4" />
        <span>Clôturer l'examen</span>
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-6 p-4"
      >
        <DialogHeader>
          <DialogTitle>
            Clôturer l'examen de {lastName} {firstName}
          </DialogTitle>
          <DialogDescription hidden={true}>
            Clôturer l'examen de {lastName} {firstName}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => setOpen(false)}
            variant="ghostWithBorders"
            disabled={pending}
            className="flex-grow"
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              setPending(true);
              socket.emit(
                "closeCandidate",
                { registrationId: registration.id },
                onAcknowledge
              );
            }}
            disabled={pending}
            className="flex-grow"
            variant="red"
          >
            {pending ? "En cours..." : "Clôturer"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CloseCandidateExamDialog;
