import { useQueryCompanyDelegations } from "@/api/company/company";
import { CompanyDelegationDtoClient } from "@/api/company/dto/company-delegation.dto";
import { useConstantQuery } from "@/api/constant/constant";
import { ConstantKey } from "@/api/constant/constants";
import { useMutationDelegationRefuse } from "@/api/delegation/delegation";
import { DelegationStatus } from "@/api/enums/DelegationStatus";
import { PayementStatus } from "@/api/enums/PayementStatus";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { Role } from "@/api/enums/Role";
import { keyFactory } from "@/api/keyFactory";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import { Input } from "@/components/atoms/Input";
import { Skeleton } from "@/components/atoms/Skeleton";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import RegistrationTypeBadge from "@/components/molecules/Badges/RegistrationTypeBadge";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import LoadingError from "@/components/molecules/LoadingError";
import PayingDialog from "@/components/molecules/PayingDialog/PayingDialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import usePersistent from "@/hooks/use-persistent";
import { cn, formatDate } from "@/lib/utils";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@radix-ui/react-hover-card";
import { useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FileText } from "lucide-react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { DelegationsTab } from "./delegations-tab.enum";

const DelegationsTabAllowedValues = {
  [DelegationsTab.All]: Object.values(DelegationStatus),
  [DelegationsTab.ToPay]: [DelegationStatus.WAITING_ACCEPTATION],
  [DelegationsTab.Paid]: [DelegationStatus.ACCEPTED],
  [DelegationsTab.Refused]: [DelegationStatus.REFUSED],
};

const columnHelper = createColumnHelper<CompanyDelegationDtoClient>();

const Delegations = () => {
  const [searchText, setSearchText] = useState("");
  const [currentTab, setCurrentTab] = usePersistent(
    "delegations-tab",
    DelegationsTab.All
  );
  // refuse delegation dialog
  const [refuseDialogOpen, setRefuseDialogOpen] = useState(false);
  const [refusedDelegationDto, setRefusedDelegationDto] = useState<
    number | null
  >(null);
  // pay delegation dialog
  const [payingDialogOpen, setPayingDialogOpen] = useState(false);
  const [payingDelegationDto, setPayingDelegationDto] = useState<
    CompanyDelegationDtoClient | undefined
  >();

  const queryClient = useQueryClient();

  /////////////////////////
  // api calls
  /////////////////////////

  const delegationsQuery = useQueryCompanyDelegations();
  const { data: delegations, isLoading } = delegationsQuery;
  const filteredData = useMemo(() => {
    if (!delegations) return [];
    return delegations.filter(
      ({ delegationStatus, candidateEmail, candidateName, candidateSurname }) =>
        DelegationsTabAllowedValues[currentTab].includes(delegationStatus) &&
        (candidateEmail.toLowerCase().includes(searchText.toLowerCase()) ||
          candidateName.toLowerCase().includes(searchText.toLowerCase()) ||
          candidateSurname.toLowerCase().includes(searchText.toLowerCase()))
    );
  }, [delegations, currentTab, searchText]);

  const refuseDelegationMutation = useMutationDelegationRefuse();

  const { data: prices } = useConstantQuery(ConstantKey.Prices);

  /////////////////////////
  // table
  /////////////////////////

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        ({ candidateEmail, candidateName, candidateSurname }) => ({
          candidateEmail,
          candidateName,
          candidateSurname,
        }),
        {
          id: "user",
          header: "Candidat",
          cell: (info) => {
            const { candidateEmail, candidateName, candidateSurname } =
              info.getValue();
            return (
              <HoverCard openDelay={0} closeDelay={0}>
                <HoverCardTrigger>
                  <span className="text-gray-900 font-medium">
                    {candidateSurname + " " + candidateName}
                  </span>
                </HoverCardTrigger>
                <HoverCardContent>
                  <div className="bg-gray-50 border-gray-200 border p-2 rounded-lg text-gray-700">
                    {candidateEmail}
                  </div>
                </HoverCardContent>
              </HoverCard>
            );
          },
        }
      ),
      columnHelper.accessor("registrationType", {
        id: "type",
        header: "Type",
        cell: (info) => (
          <RegistrationTypeBadge registrationType={info.getValue()} />
        ),
      }),
      columnHelper.accessor("payingDeadline", {
        id: "deadline",
        header: "Date limite paiement",
        cell: (info) => (
          <span className="text-gray-600 font-medium">
            {formatDate(info.getValue())}
          </span>
        ),
      }),
      columnHelper.accessor(
        ({ delegationStatus, payementStatus }) => ({
          delegationStatus,
          payementStatus,
        }),
        {
          id: "status",
          header: "État d'acception",
          cell: (info) => {
            const { delegationStatus, payementStatus } = info.getValue();

            switch (delegationStatus) {
              case DelegationStatus.REFUSED:
                return <Badge variant="red">Refusé</Badge>;
              case DelegationStatus.WAITING_ACCEPTATION:
                switch (payementStatus) {
                  case PayementStatus.DELEGATION_WAITING_FOR_VALIDATION:
                    return (
                      <Badge variant="purple">En attente de validation</Badge>
                    );
                  case PayementStatus.UNPAID:
                    return <Badge variant="orange">À payer</Badge>;
                }
                break;
              case DelegationStatus.ACCEPTED:
                return <Badge variant="green">Payé</Badge>;
              case DelegationStatus.WAITING_ACCOUNT_CREATION:
                return <Badge variant="green">Inconnu</Badge>;
            }
          },
        }
      ),
      columnHelper.accessor("registrationType", {
        id: "price",
        header: "Montant",
        cell: (info) => {
          if (!prices) return <span className="italic">Chargement...</span>;

          const price =
            info.getValue() === RegistrationType.RENEW
              ? prices.renewing.full
              : prices.initial.full;

          return (
            <span className="text-gray-900 font-medium">{`${price}€`}</span>
          );
        },
      }),
      columnHelper.accessor("debitNote", {
        id: "debit",
        header: "Note de débit",
        cell: (info) => {
          const debitNote = info.getValue();
          if (!debitNote?.signedAccessKey) return <></>;

          return (
            <Button variant="blueLink">
              <Link to={debitNote.signedAccessKey} target="_blank" download>
                <FileText className="w-4 h-4" />
              </Link>
            </Button>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "action",
        header: "Action",
        cell: (info) => {
          const row = info.getValue();

          if (
            row.delegationStatus === DelegationStatus.WAITING_ACCEPTATION &&
            row.payementStatus === PayementStatus.UNPAID
          ) {
            return (
              <div className="flex flex-row gap-2">
                <Button
                  onClick={() => {
                    setRefusedDelegationDto(row.registrationId);
                    setRefuseDialogOpen(true);
                  }}
                  variant="red"
                >
                  Refuser
                </Button>
                <Button
                  onClick={() => {
                    setPayingDelegationDto(row);
                    setPayingDialogOpen(true);
                  }}
                  variant="default"
                >
                  Payer
                </Button>
              </div>
            );
          }

          return <></>;
        },
      }),
    ],
    [prices]
  );

  const table = useReactTable({
    // data: delegationsQuery.data ?? [],
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  /////////////////////////
  // component
  /////////////////////////

  if (!delegations)
    return (
      <div className="py-3 px-6 bg-white w-full h-full">
        {isLoading ? (
          <Skeleton className="h-full w-full" />
        ) : (
          <LoadingError>Erreur de chargement des délégations</LoadingError>
        )}
      </div>
    );

  return (
    <div className="flex flex-col w-full">
      {payingDelegationDto && (
        <PayingDialog
          open={payingDialogOpen}
          onOpenChange={setPayingDialogOpen}
          candidateFirstName={payingDelegationDto.candidateSurname}
          candidateLastName={payingDelegationDto.candidateName}
          registrationId={payingDelegationDto.registrationId}
          examDate={payingDelegationDto.exam.date}
          examType={payingDelegationDto.exam.type}
          registrationEndDate={payingDelegationDto.exam.registeringEndDate}
          role={Role.COMPANY}
        />
      )}
      <ConfirmDialog
        open={refuseDialogOpen}
        onOpenChange={setRefuseDialogOpen}
        cancelStr="Annuler"
        title="Refuser la délégation"
        validateStr="Confirmer le refus"
        onResult={(accepted) => {
          if (!refusedDelegationDto) console.error("invalid state");
          else if (accepted) {
            refuseDelegationMutation.mutate(refusedDelegationDto, {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: keyFactory.company.delegations(),
                });

                toast.success("Refus pris en compte");
              },
              onError: () => {
                toast.error("Échec de la requête", {
                  description: "Votre action n'a pas pu être prise en compte.",
                });
              },
            });
          }

          setRefuseDialogOpen(false);
        }}
        cancelButtonVariant="ghostWithBorders"
        validateButtonVariant="red"
      />
      <div className="flex flex-col w-full border-b border-b-gray-200">
        <div className="flex flex-col gap-2 pt-3 px-6 bg-white w-full">
          <h1>Paiements délégations</h1>
          <Tabs
            value={currentTab}
            onValueChange={(e) => setCurrentTab(e as DelegationsTab)}
          >
            <TabsList variant="transparent" className="pb-0 mb-0">
              <TabsTrigger variant="underlineBlue" value={DelegationsTab.All}>
                Tous
              </TabsTrigger>
              <TabsTrigger variant="underlineBlue" value={DelegationsTab.ToPay}>
                À payer
              </TabsTrigger>
              <TabsTrigger variant="underlineBlue" value={DelegationsTab.Paid}>
                Payé
              </TabsTrigger>
              <TabsTrigger
                variant="underlineBlue"
                value={DelegationsTab.Refused}
              >
                Refusé
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>
      <div className="py-4 px-6 flex flex-col gap-3">
        <Input
          className="shadow-none"
          id="shadcn-input"
          searchIcon
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Rechercher"
        />
        <div className="rounded-lg overflow-auto border border-gray-200">
          <Table className="text-gray-500 text-sm font-normal leading-6">
            <TableHeader className="items-center bg-gray-50">
              <TableRow>
                {table.getHeaderGroups()[0].headers.map((header) => (
                  <TableHead key={header.id}>
                    <div className="flex flex-row items-center gap-3 text-xs whitespace-nowrap">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row, i) => (
                <TableRow key={row.id} className={cn({ "bg-gray-50": i % 2 })}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="h-14 py-0">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Delegations;
