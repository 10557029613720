import { QueueData, QueueEnum } from "@/api/enums/queue.enum";

export enum JobState {
  Completed = "completed",
  Active = "active",
  Waiting = "waiting",
  Failed = "failed",
  Unknown = "unknown",
}

interface JobStateData {
  state: JobState;
  timestamp: number;
  finishedOn?: number;
  processedOn?: number;
  failedReason?: string;
}

export interface QueueJob<T extends QueueEnum> {
  queue: T;
  data: QueueData[T];
  state: JobStateData;
}

export type QueuesJobsDto = QueueJob<QueueEnum>[];
