import { ENDPOINT } from "@/constants/endpoints";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { TransferStatusDto } from "./dto/accept-transfer.dto";
import { PayByCardDto } from "./dto/pay-by-card.dto";
import { TransferDoneDto } from "./dto/transfer-done.dto";

// transfer done

const transferDone = async (body: TransferDoneDto) => {
  await axios.post<void>(ENDPOINT.payementController.postTransferDone(), body);
};

export const useMutationPayementTransferDone = (
  props?: Partial<UseMutationOptions<void, AxiosError, TransferDoneDto>>
) =>
  useMutation({
    mutationFn: transferDone,
    ...props,
  });

// transfer status

const transferStatus = async (body: TransferStatusDto) => {
  await axios.post<void>(
    ENDPOINT.payementController.postTransferStatus(),
    body
  );
};

export const useMutationPayementTransferStatus = (
  props?: Partial<UseMutationOptions<void, AxiosError, TransferStatusDto>>
) =>
  useMutation({
    mutationFn: transferStatus,
    ...props,
  });

// pay by card redirect

const payByCardRedirect = async (body: PayByCardDto) => {
  await axios
    .post<string>(ENDPOINT.payementController.getPayByCardRedirect(), body)
    .then(({ data: url }) => {
      window.location.href = url;
    });
};

export const useMutationPayementPayByCardRedirect = (
  props?: Partial<UseMutationOptions<void, AxiosError, PayByCardDto>>
) =>
  useMutation({
    mutationFn: payByCardRedirect,
    ...props,
  });
